import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { itDiagnosticsService } from "src/app/providers/input/itdiagnostics.service";
import { HmapobservationsComponent } from "../../hmapobservations/hmapobservations.component";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from 'src/app/modules/home/data.service';
import { PrintModel } from 'src/app/models/shared/printmodel';
import { Subscription } from "rxjs";

const heatmapUrlMap = {
    dataheatmap: 'dataheatmap',
    cybersecheatmap: 'csheatmap',
    itinfraheatmap: 'itinfraheatmap',
    itcapheatmap: 'itheatmap'
}

@Component({
    selector: 'app-hmap',
    templateUrl: './hmap.component.html',
    styleUrls: ['./hmap.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DialogService]
})
export class HMapComponent implements OnInit {
    @Input() headerName: string;
    @Input() type: string;
    heatmap: any;
    private refreshSubscription: Subscription;
    private routeSubscription: Subscription;

    constructor(private itDiagService: itDiagnosticsService,
        private dialogSvc: DialogService, private activatedRoute: ActivatedRoute,private sharedService: SharedService) { }

    ngOnInit(): void {
        this.refreshSubscription = this.sharedService.refreshScreen$.subscribe(response => {
            if (response === 'dataheatmap' || response === 'itinfraheatmap' || response === 'cybersecheatmap' || response === 'itcapheatmap') {
                this.getHeatMapData();
            }
          })
        this.activatedRoute.url.subscribe(url => {
            this.getHeatMapData();
        });
    }

    getHeatMapData() {
        this.itDiagService.getHeatMap(this.type).subscribe(response => {
            this.heatmap = response;
            // this.sharedService.printableItems = [];
            // let localSections: PrintModel[] = [];
        
            //   localSections.push({ title: this.type, body: this.heatmap, cssClass: '', children: [] });;
        
            // this.sharedService.printableItems.push({ type: 'heatmap-list', body: localSections, cssClass: '' });
        });
    }

    GetCap(item: any) {
        return item.capabilities;
    }
    displayObservations(cap: any, dep: any) {
        if (this.checkObservationForCapability(cap)) {
            this.dialogSvc.open(HmapobservationsComponent, {
                header: 'Observations',
                data: {
                    capability: cap,
                    heatMap: this.heatmap,
                    department: dep,
                    type: this.type
                },
                width: '1000px',
                height: 'auto'
            });
        }

    }
    checkObservationForCapability(capability: any) {
        let obsevationFound = false;
        this.heatmap.departments.forEach((dept: any) => {
            for (let i = 0; i < dept.observations.length; i++) {
                if (capability.code != '' && capability.code === dept.observations[i].capability) {
                    obsevationFound = true;
                    break;
                }
            }

        });
        return obsevationFound;
    }

    ngOnDestroy(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }
}