import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Appconfig } from 'src/app/configurations/appconfig';
import { map } from 'rxjs';
import { CoreService } from '../core.service';
import { AppService } from '../appservice/app.service';

@Injectable({
  providedIn: 'root'
})
export class TechstrategyService extends CoreService {

  // businessUnitId: any;
  roadmapPath: any;
  roadmapPathV3:any;
  updateRoadMapV3:any;
  criticalApplist: any;
  createPortfolio: string;
  approadmapsphases: string;
  techThemes: string;
  itSwotAnalysis: string;
  technologyThemes: string;
  programCriticalData: string;
  postTechAlignemnt: string;
  themeAlignment: string;
  deleteThemeCategory: string;
  deleteThemeItem: string;
  createTechTheme: string;
  programData: string;
  postLoopBack: string;

  constructor(httpClient: HttpClient, private appService: AppService) {
    super(httpClient);
    this.setInitialValues();

  }

  setInitialValues() {
    this.roadmapPath = '/modules/approadmaps';
    this.criticalApplist = '/applications';
    this.createPortfolio = '/createapproadmaps/';
    this.approadmapsphases = '/csa/approadmapsphases/';
    this.technologyThemes = '/modules/techthemes';
    this.programCriticalData = '/masterdata/programmedata/programmes/critical';
    this.postTechAlignemnt = '/themesalignment/';
    this.themeAlignment = '/modules/themesalignment';
    this.deleteThemeCategory = '/techthemes/';
    this.deleteThemeItem = '/techthemes/';
    this.createTechTheme = '/createtechthemes';
    this.programData = '/masterdata/programmedata/projects/critical'
    this.postLoopBack = '/modules/itswotanalysis';
    this.roadmapPathV3 = '/applicationroadmaps';
    this.updateRoadMapV3='/applicationroadmaps'

  }
  getData(path: string) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.TECHSTRATEGY + path).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getCriticalApplist() {
    return this.getData('/' + this.appService.strategyId + this.criticalApplist);
  }


  // Application Roadmap

  getAppRoadmap() {
    return this.getData('/' + this.appService.strategyId + this.roadmapPath);
  }
  deleteRoadmap(id: string) {
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.TECHSTRATEGY+ '/' +this.appService.strategyId+ '/' + 'v2' + '/delete/approadmaps/'+ id)
  }


  getCreateRoadmap(id: any) {
    return this.getData('/' + this.appService.strategyId + this.createPortfolio + id);
  }

  getAppRoadmapV3() {
    return this.getData('/' + this.appService.strategyId + this.roadmapPathV3);
  }

  updateRoadmap(data: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.TECHSTRATEGY +  '/v3/' + this.appService.strategyId + this.updateRoadMapV3, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  updateHeader(data: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.TECHSTRATEGY + '/' + this.appService.strategyId + this.roadmapPath + '/', payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  // Loop Back
  postLoopback(data: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.TECHSTRATEGY + '/' + this.appService.strategyId + this.postLoopBack, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }


  // Technology Theme


  getTechnologyThemes() {
    return this.getData('/' + this.appService.strategyId + this.technologyThemes);
  }

  deleteCategory(id: string) {
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.deleteThemeCategory + id)
  }

  deleteTheme(id: string, themes?: any[], index?: number) {
    const payLoad: any = {
      themes: themes
    };
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.deleteThemeItem + id + '/' + index + '/delete', payLoad)
      .pipe(map(respData => { return respData.body; }));
  }

  createTechCategory(category: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.createTechTheme, category).pipe(map(respData => { return respData.body; }));
  }

  createTechThemeItem(body: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.technologyThemes, body).pipe(map(respData => { return respData.body; }));
  }

  updateThemeCategory(body: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.technologyThemes, body).pipe(map(respData => { return respData.body; }));
  }

  updateTechTheme(body: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.technologyThemes, body).pipe(map(respData => { return respData.body; }));
  }

  getProgrammes() {
    return this.getData('/' + this.appService.strategyId + this.programData);
  }

  // Themes Alignment

  getThemeAlignment() {
    return this.getData('/' + this.appService.strategyId + this.themeAlignment);
  }

  getCriticalProgramist() {
    return this.getData('/' + this.appService.strategyId + this.programCriticalData);
  }

  postAlignment(criticalPrgId: string, projectId: string, payLoad: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.TECHSTRATEGY + '/' + this.appService.strategyId + this.postTechAlignemnt + criticalPrgId + '/' + projectId, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));

  }

  SaveTechVision(req: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/modules/itvisionandgoals', req).pipe(map(respData => { return respData.body; }));
  }

  getTechVision() {
    this.setInitialValues();
    return this.getData('/' + this.appService.strategyId + '/modules/itvisionandgoals');
  }

  sortTechThemes(payload: any, type: String) {
    return this.post(`${Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS}/${this.appService.strategyId}/techthemes/sort/${type}`, payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  sortvisiongoals(payload: any, type: String) {
    return this.post(`${Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS}/${this.appService.strategyId}/itvisionandgoals/sort/${type}`, payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }
}
