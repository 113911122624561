import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Capability, Department, Heatmap, RAG } from 'src/app/models/input/itdiagnostics/heatmap';
import { Companies } from 'src/app/models/shared/company';
import { SharedService } from 'src/app/modules/home/data.service';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class HeatmapComponent implements OnInit {

  @Input() headerName: string;
  @Input() type: string;
  @ViewChild('closebutton') closebutton: any;
  inputscreen?: string = '';
  heatMap: Heatmap = {} as Heatmap;
  departments: Department[] = [];
  showCapabilityText: boolean = false;
  headerIndex: number = 0;
  dataIndex: number = 0;
  ragColors: RAG[] = [];
  selectedRagColor?: string;
  companies: Companies[];
  capability: any = undefined;
  department!: Department;
  editedHeaderIndex: number = -1;
  capColumns: any[] = [];
  isobsexist: boolean = false;
  search: '';
  departmentIndex: any;
  capabilityIindex: any;
  filteredCapabilitiesList: any[] = [];
  public simpleList = [
    [
      { 'name': 'John' },
      { 'name': 'Smith' },
      { 'name': 'George' },
    ],
    [
      { 'name': 'Jennifer' },
      { 'name': 'Laura' },
      { 'name': 'Georgina' },
    ]
  ];
  constructor(private itDiagService: itDiagnosticsService, private activeRoute: ActivatedRoute, private confirmationService: ConfirmationService,
    private sharedService: SharedService) {
    this.inputscreen = this.activeRoute.snapshot.paramMap.get('id')?.toString();
  }

  ngOnInit(): void {
    this.sharedService.refreshScreen$.subscribe(response => {
      console.log("DEBUG: refresh response ", response);
      if (response === 'businessunit' || response === 'businessunitclone') {
        this.init()
      }
    });
    this.init();
  }

  init(){
    this.getHeatMapData();
    this.getRAGColors();
    this.getImportData();
    this.onCancel();
  }

  getRAGColors() {
    this.ragColors.push({ code: 'deep-red', name: 'Maroon' });
    this.ragColors.push({ code: 'red', name: 'Red' });
    this.ragColors.push({ code: 'amber', name: 'Amber' });
    this.ragColors.push({ code: 'green', name: 'Green' });
    this.ragColors.push({ code: 'not_assessed', name: 'Not Assessed' });
  }

  getHeatMapData() {
    this.itDiagService.getHeatMap(this.type).subscribe(response => {
      this.heatMap = response;
      this.heatMap.departments.map((dept: any) => {
        if (dept.capabilities.length < 13) {
          dept.capabilities.push({ "code": "", "display_name": "", "sort_order": "13", "_id": "6480a5376840395f7cf0a815" });
        }
      });

      this.departments = this.heatMap.departments;
      let data = this.heatMap.departments.filter(x => x.code == this.department?.code && x.display_name == this.department?.display_name);
      if (data.length > 0) {
        this.department = data[0];
      }

      for (let k = 0; k < data.length; k++) {
        for (let i = 0; i < this.departments[k].capabilities.length; i++) {
          if (this.departments[k].observations.length > 0) {
            this.isobsexist = true;
          } else {
            this.isobsexist = false;
          }
        }
      }
    });
  }

  getImportData() {
    this.itDiagService.getImportData().subscribe(response => {
      this.companies = response;
    });
  }

  editCapability(departmentIndex: any, capabilityIndex: any, capability: any) {
    this.departmentIndex = departmentIndex;
    this.capabilityIindex = capabilityIndex;
    this.search = capability.display_name ? capability.display_name : '';
    this.filterCapabilityList();
  }
  editHeader(index: any) {
    // console.log('Edit clicked');
    this.editedHeaderIndex = index;
  }
  leftExpand(index: any) {
    // console.log('Left Expand clicked');
    if (this.departments[index].size > 1) {
      this.departments[index].size = this.departments[index].size - 1;
      this.departments[index].capabilities = this.departments[index].capabilities.slice(0, 13 * this.departments[index].size)
    }
  }
  rightExpand(index: any) {
    // console.log('Right Expand clicked');
    this.departments[index].size = this.departments[index].size + 1;
    let emptyCapability: Capability[] = [];
    for (let g = 1; g <= 13; g++) {
      this.departments[index].capabilities.push({ code: "", display_name: "", rag: "", sort_order: "" })
    }
  }
  deleteHeader(index: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {
        const headerToDelete = this.departments[index];

        // Remove header and associated observations
        this.departments.splice(index, 1);
        for (const obs of headerToDelete.observations) {
          const capabilityIndex = this.departments.findIndex(
            (dept: any) => dept.code === obs.capability
          );
          if (capabilityIndex !== -1) {
            this.departments[capabilityIndex].observations = this.departments[
              capabilityIndex
            ].observations.filter((o: any) => o._id !== obs._id);
          }
        }

        // Call onSaveHeatMap to save the updated data
        // this.onSaveHeatMap(false); // Pass true to indicate deletion

        // Display a success message for deletion
        // this.sharedService.alertNotification('success', 'Delete Operation', 'Header Deleted Successfully');
      },
      reject: (type: any) => {
        // Do nothing if user cancels
      }
    });
  }

  // onDeleteCapability(i: any, j: any) {

  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete?',
  //     header: 'Confirmation',
  //     icon: 'pi pi-exclamation-triangle',
  //     acceptLabel: 'Confirm',
  //     acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
  //     rejectLabel: 'Cancel',
  //     rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
  //     accept: () => {
  //       this.departments[i].capabilities.splice(j, 1);
  //       this.departments[i].capabilities.push({ code: "", display_name: "", rag: "", sort_order: "" });
  //       this.sharedService.alertNotification('
  //         severity: 'success',
  //         summary: this.headerName,
  //         detail: 'Capability Deleted Successfully',
  //       });
  //     },
  //     reject: (type: any) => {

  //     }
  //   });

  // }

  onDeleteCapability(departmentIndex: number, capabilityIndex: number, capability: Capability) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {
        // Remove capability and its observations
        this.departments[departmentIndex].capabilities.splice(capabilityIndex, 1);

        capability.code = '';
        capability.display_name = '';
        capability.rag = '';
        capability.sort_order = capabilityIndex < 13 ? 13 : 26;

        if (capabilityIndex < 13) {
          this.departments[departmentIndex].capabilities.forEach((x: Capability, index: number) => {
            if (index <= 12) {
              x.sort_order = index + 1;
            }
          });
          this.departments[departmentIndex].capabilities.splice(12, 0, capability);
        } else {
          this.departments[departmentIndex].capabilities.forEach((x: Capability, index: number) => { x.sort_order = index + 1; });
          this.departments[departmentIndex].capabilities.push(capability);
        }

        this.departments[departmentIndex].observations = this.departments[departmentIndex].observations.filter(
          (obs: any) => obs.capability !== capability.code
        );

        // Display delete message
        // this.sharedService.alertNotification('success', 'capability', 'Deleted Successfully');
        // Call onSaveHeatMap with false parameter to prevent displaying the save message
        // this.onSaveHeatMap(false); // Save the updated data
      },
      reject: (type: any) => {
        // Do nothing if user cancels
      }
    });
  }


  onLostFocus() {
    this.showCapabilityText = false;
  }

  onChangeRAG(cap: Capability, rag: RAG) {
    cap.rag = rag?.code ? rag?.code : cap.rag;
  }

  OnDefaultHeatMap() {
    this.confirmationService.confirm({
      message: 'All the data will be replaced with Xirocco default values. You will have to click on Save to persist the changes. Are you sure you want to revert?',
      header: 'Revert to default',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {

        this.itDiagService.getDefaultHeatMap(this.type).subscribe(response => {
          this.heatMap.departments = response.columns;
          this.departments = response.columns;
          this.sharedService.alertNotification('success', "Reverted", 'Reverted the Heatmap to Xirocco defaults');
        });

      },
      reject: (type: any) => {

      }
    });
  }



  onResetHeatMap() {


    this.confirmationService.confirm({
      message: 'Are you sure you want to reset?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {

        let emptyDepartments: Department[] = [];
        this.departments = [];
        for (let d = 1; d <= 11; d++) {
          let emptyCapability: Capability[] = [];
          for (let g = 1; g <= 13; g++) {
            emptyCapability.push({ code: "", display_name: "", rag: "", sort_order: "" })
          }
          this.departments.push({ capabilities: emptyCapability, sort_order: this.departments.length.toString(), code: "", display_name: "Header" + d, size: 1, observations: [] });
        }
        this.sharedService.alertNotification('success', this.headerName, 'Reset Heatmap Successfully');
      },
      reject: (type: any) => {

      }
    });
  }

  getCalculatedSize() {
    let sum = this.departments.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    return sum;
  }

  onAddNewColumn() {
    let emptyCapability: Capability[] = [];
    for (let g = 0; g < 13; g++) {
      emptyCapability.push({ code: "", display_name: "", rag: "", sort_order: "" });
    }

    this.departments.push({ capabilities: emptyCapability, sort_order: this.departments.length.toString(), code: "", display_name: "Header" + (this.departments.length + 1), size: 1, observations: [] });
    this.sharedService.alertNotification('success', this.headerName, 'Added New Column Successfully');
  }

  onSaveHeatMap(displaySaveMessage: boolean = true) {
    this.heatMap.departments = this.departments;
    this.itDiagService.postHeatMap(this.heatMap, this.type, this.heatMap._id).subscribe(response => {
      console.log(response);
      this.getHeatMapData();
      if (displaySaveMessage) {
        this.sharedService.alertNotification('success', this.headerName, 'Saved Heatmap Successfully');
      }


    });
  }


  onCancelHeatMap() {
    this.getHeatMapData();
    this.sharedService.alertNotification('success', this.headerName, 'Cancelled Heatmap Successfully');
  }

  addObservation(department: any, capability: any) {
    this.department = department;
    this.capability = capability;
  }

  saveClick() {
    this.getHeatMapData();
  }

  onBlur(index: any) {
    this.editedHeaderIndex = -1;
    this.departments[index].code = "";
  }

  checkLeftExpand(index: any) {
    let lastindex = 0;
    this.departments[index].capabilities.map((x: any, index: any) => {
      if (x.display_name)
        lastindex = index;
    })
    if (Math.ceil((lastindex + 1) / 13) == this.departments[index].size) {
      return true;
    }
    else
      return !(this.departments[index].size > 1);
  }
  addHeader() { }
  onCancel() {

  }
  public removeItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }
  getColumns(capabilities: any) {
    this.capColumns = [];
    for (let i = 1; i <= capabilities.length / 13; i++) {
      this.capColumns.push(i);
    }
    return this.capColumns;
  }
  getWidth(capabilities: any) {
    return 'width:' + 9.09 * (capabilities.length / 13) + '% !important';
  }
  checkObservationForCapability(capability: any) {
    let obsevationFound = false;
    this.heatMap.departments.forEach((dept) => {
      for (let i = 0; i < dept.observations.length; i++) {
        if (capability.code != '' && capability.code === dept.observations[i].capability) {
          obsevationFound = true;
          break;
        }
      }

    });
    return obsevationFound;
  }

  filterCapabilityList() {
    this.itDiagService.getCapabilityList(this.type).subscribe(res => {
      let tempFilteredCapabilitiesList: any = [];
      for (let capabilityListItem of res.list) {
        let alreadyCapabilityIsIntheLIst = false;
        for (let department of this.departments) {
          for (let capability of department.capabilities) {
            if (capability.code && capabilityListItem.code && capability.code == capabilityListItem.code) {
              alreadyCapabilityIsIntheLIst = true;
            }
          }
        }
        if (!alreadyCapabilityIsIntheLIst) {
          tempFilteredCapabilitiesList.push(capabilityListItem)
        }
      }
      this.filteredCapabilitiesList = tempFilteredCapabilitiesList;
    })
  }

  SaveCpability() {
    this.departments[this.departmentIndex].capabilities[this.capabilityIindex].display_name = this.search;
    // this.onSaveHeatMap();
    this.closebutton.nativeElement.click();
  }

  capClick(item: any) {
    this.search = item.display_name;
  }
}
