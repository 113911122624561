<p-toast position="top-center"></p-toast>
<div class="tab-container overflow-hidden">
  <nav class="tabs tabs-sticky">
    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-strengths-tab" data-bs-toggle="tab" data-bs-target="#nav-strengths"
        type="button" role="tab" aria-controls="nav-strengths" aria-selected="true">
        <img src="../../../../../../../assets/images/uploads/strength.svg" width="40" height="40" class="me-1 h-fs"> STRENGTHS </button>
      <button class="nav-link" id="nav-opportunities-tab" data-bs-toggle="tab" data-bs-target="#nav-opportunities"
        type="button" role="tab" aria-controls="nav-opportunities" aria-selected="false">
        <img src="../../../../../../../assets/images/uploads/opertuniti.svg" width="40" height="40" class="me-1 h-fs"> OPPORTUNITIES </button>
      <button class="nav-link" id="nav-weaknesses-tab" data-bs-toggle="tab" data-bs-target="#nav-weaknesses"
        type="button" role="tab" aria-controls="nav-weaknesses" aria-selected="false">
        <img src="../../../../../../../assets/images/uploads/weakness.svg" width="40" height="40" class="me-1 h-fs"> WEAKNESSES </button>
      <button class="nav-link layer" id="nav-threats-tab" data-bs-toggle="tab" data-bs-target="#nav-threats" type="button"
        role="tab" aria-controls="nav-threats" aria-selected="false">
        <img src="../../../../../../../assets/images/uploads/threat.svg" width="40" height="40" class="me-1 h-fs"> THREATS </button>
    </div>
  </nav>
   
 

  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-strengths" role="tabpanel" aria-labelledby="nav-strengths-tab"
      tabindex="0">
      <div *ngIf="isAddNewStrengthEnable" class="total-str">

        <div class="w-85">
          <p class=" fw-bold m-0 h-fs">STRENGTHS&nbsp;({{swotMatrix.strengths.length}}) </p>

          <div class="row g-3 w-100 max-width-none">
            <div class="col-8 col-xxl-10 px-0">
              <div>
                <p-editor [(ngModel)]="addStrength.strength" name="overview" rows="4" (onTextChange)="TextLength($event,'strength')">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
              <!-- <div class="editable  fs-15 border-0 border-bottom border-dark px-3 py-2 me-3" contenteditable="true"
                rows="4">
              </div> -->
              <!-- <span class=" float-end me-3">{{charLength['strength'] || 0}}/200</span> -->
            </div>
            <div class="col-4 col-xxl-2 px-0 pb-3 text-center d-flex align-items-end justify-content-center">
              <span>
                <p class="m-0 fw-bold h-fs">STATUS</p> Set in Business Loop Back
              </span>
            </div>
          </div>

        </div>

        <div class="d-flex flex-column">
       <button class="btn btn-info1 text-white h-fs" *ngIf="this.addbut" (click)="onAddStrength()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button>
          <button class="btn btn-info1 text-white h-fs" *ngIf="!this.addbut" (click)="onAddStrength()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button>   
          <button class="btn btn-secondary1 text-info1 mt-2 " (click)="onCancelStrength()"><img src="../../../../../../assets/images/uploads/reset-info.svg" alt="" width="17" height="17" class="mb-1 me-1 reset">CANCEL</button>
        </div> 

      </div>
      <div class="total-str" *ngIf="!isAddNewStrengthEnable">
        <h5 class="count" >STRENGTHS&nbsp;({{swotMatrix?.strengths?.length}})</h5>
        <div class="w-85"></div>
        
        <div class="d-flex flex-column p-0">
          <button class="btn btn-info1 text-white h-fs p-0" (click)="onAddNewStrength()"> <img src="../../../../../../assets/images/uploads/wplus.svg" alt="" width="17" height="17" class="mb-1 me-1">ADD NEW
          </button>

        </div>
      </div>
      <div class="scrollable-table">
      <table class=" tab-table table table-responsive table-hover" cdkDropList (cdkDropListDropped)="dropStrengths($event)">
        <tbody>
          <tr *ngFor="let strength of swotMatrix?.strengths; index as i" cdkDrag style=" background: white; cursor: move;">
            <th class="w-5 text-center">{{i+1}}</th>
            <td class="h-fs" [innerHTML]="strength.strength"></td>
            <td class="status-width">
              <div *ngIf="strength.status == 0 || strength.status == undefined">
                <img src="../../../../assets/images/uploads/question-symbol.svg" alt=""> No Status Available
              </div>
              <p-message></p-message>
              <div *ngIf="strength.status == 1">
                <img src="../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg" alt=""> Not
                Required to Leverage
              </div>
              <div *ngIf="strength.status == 2">
                <img src="../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg" alt="">
                Not Leveraged
              </div>
              <div *ngIf="strength.status == 3">
                <img src="../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg" alt="">
                Partially Leveraged
              </div>
              <div *ngIf="strength.status == 4">
                <img src="../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg" alt="">
                Fully Leveraged
              </div>
            </td>
            <td class="w-15 pe-3">
              <div class="float-end">
                <button class="btn btn-secondary1 mb-1 mb-xxl-0 h-fs overflow-auto" (click)="onEditStrength(strength)"
                  [disabled]="isAddNewStrengthEnable">
                  <img src="../../../../assets/images/uploads/edit-box-info.svg" alt="" width="15" height="15" class="mb-1"> EDIT
                </button>
                <button class="btn bg-danger1 h-fs ms-1 overflow-hidden" (click)="onDeleteStrength(i)"
                  [disabled]="isAddNewStrengthEnable">
                  <img src="../../../../assets/images/uploads/delete-red.svg" alt="" width="15" height="15" class="mb-1"> DELETE </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <!--second tab  -->
    <div class="tab-pane fade" id="nav-opportunities" role="tabpanel" aria-labelledby="nav-opportunities-tab"
      tabindex="0">
      <div *ngIf="isAddNewOppurtunityEnable" class="total-str">
        <div class="w-85">

          <p class="fs-20 fw-bold h-fs m-0">OPPORTUNITIES&nbsp;({{swotMatrix?.oppurtunities?.length}})</p>
          <div class="row g-3 w-100 max-width-none">
            <div class="col-8 col-xxl-10 px-0">
              <div>
                <p-editor [(ngModel)]="addOppurtunity.oppurtunity" name="oppurtunity" (onTextChange)="TextLength($event,'opportunity')">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
              <!-- <span class="fs-13 float-end me-3">{{charLength['opportunity']|| 0}}/200</span> -->
              
            </div>
            <div class="col-4 col-xxl-2 px-0 pb-3 text-center d-flex align-items-end justify-content-center">
              <span>
                <p class="m-0 fw-bold">STATUS</p> Set in Business Loop Back
              </span>
            </div>
          </div>

        </div>

        <div class="d-flex flex-column">
          <button class="btn btn-info1 text-white" *ngIf="this.addbut" (click)="onAddOppurtunity()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button>
          <button class="btn btn-info1 text-white" *ngIf="!this.addbut" (click)="onAddOppurtunity()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button> 
          <button class="btn btn-secondary1 text-info1 mt-2" (click)="onCancelOppurtunity()"><img src="../../../../../../assets/images/uploads/reset-info.svg" alt="" width="17" height="17"class="mb-1 reset"> CANCEL</button>
        </div>

      </div>

      <div class="total-str" *ngIf="!isAddNewOppurtunityEnable">
        <h5 class="count">OPPORTUNITIES&nbsp;({{swotMatrix?.oppurtunities?.length}})</h5>
        <div class="w-85"></div>
       
        <div class="d-flex flex-column">
          
          <button class="btn btn-info1 text-white " (click)="onAddNewOppurtunity()"> <img src="../../../../../../assets/images/uploads/wplus.svg" alt="" width="17" height="17" class="mb-1 me-1">ADD NEW </button>

        </div>
      </div>

      <div class="scrollable-table">
      <table class="tab-table table table-responsive table-hover"  cdkDropList (cdkDropListDropped)="dropOpportunities($event)">
        <tbody>
          <tr *ngFor="let opp of swotMatrix?.oppurtunities; index as j" cdkDrag style=" background: white; cursor: move;">
            <th class="w-5 text-center">{{j+1}}</th>
            <td class="h-fs" [innerHTML]="opp.oppurtunity"></td>
            <td class="status-width">
              <div *ngIf="opp.status == 0 || opp.status == undefined">
                <img src="../../../../assets/images/uploads/question-symbol.svg" alt=""> No Status Available
              </div>
              <div *ngIf="opp.status == 1">
                <img src="../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg" alt=""> Not
                Required to Explore
              </div>
              <div *ngIf="opp.status == 2">
                <img src="../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg" alt="">
                Not
                Explored
              </div>
              <div *ngIf="opp.status == 3">
                <img src="../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg" alt="">
                Partially Explored
              </div>
              <div *ngIf="opp.status == 4">
                <img src="../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg" alt="">
                Fully Explored
              </div>
            </td>
            <td class="w-15 pe-3">
              <div class="float-end">
                <button class="btn btn-secondary1 mb-1 mb-xxl-0 h-fs " (click)="onEditOppurtunity(opp)"
                  [disabled]="isAddNewOppurtunityEnable">
                  <img src="../../../../assets/images/uploads/edit-box-info.svg" alt="" width="15" height="15" class="mb-1">
                  EDIT
                </button>
                <button class="btn bg-danger1 h-fs ms-1" (click)="onDeleteOppurtunity(j)"
                  [disabled]="isAddNewOppurtunityEnable">
                  <img src="../../../../assets/images/uploads/delete-red.svg" alt="" width="15" height="15" class="mb-1"> DELETE </button>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
      </div>
    </div>

    <!-- third tab -->
    <div class="tab-pane fade" id="nav-weaknesses" role="tabpanel" aria-labelledby="nav-weaknesses-tab" tabindex="0">
      <div *ngIf="isAddNewWeaknessEnable" class="total-str">
        <div class="w-85">
          <p class="fs-20 fw-bold m-0">WEAKNESSES&nbsp;({{swotMatrix?.weaknesses?.length}})</p>
          <div class="row g-3 w-100 max-width-none">
            <div class="col-8 col-xxl-10 px-0">
              <div>
                <p-editor [(ngModel)]="addWeakness.weakness" name="overview" (onTextChange)="TextLength($event,'weakness')">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
              <!-- <span class="fs-13 float-end me-3">{{charLength['weakness'] || 0}}/200</span> -->
            </div>
            <div class="col-4 col-xxl-2 px-0 pb-3 text-center d-flex align-items-end justify-content-center">
              <span>
                <p class="m-0 fw-bold">STATUS</p> Set in Business Loop Back
              </span>
            </div>
          </div>

        </div>

        <div class="d-flex flex-column">
          <button class="btn btn-info1 text-white" *ngIf="this.addbut" (click)=" onAddWeakness()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button>
          <button class="btn btn-info1 text-white" *ngIf="!this.addbut" (click)=" onAddWeakness()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button> 
          <button class="btn btn-secondary1 text-info1 mt-2 " (click)="onCancelWeakness()"><img src="../../../../../../assets/images/uploads/reset-info.svg" alt="" width="17" height="17" class="mb-1 me-1 reset">CANCEL</button>
        </div>

      </div>

      <div class="total-str" *ngIf="!isAddNewWeaknessEnable">
        <h5 class="count">WEAKNESSES&nbsp;({{swotMatrix?.weaknesses?.length}})</h5>
        <div class="w-85"></div>
        <div class="d-flex flex-column">
          <button class="btn btn-info1 text-white" (click)="onAddNewWeakness()"><img src="../../../../../../assets/images/uploads/wplus.svg" alt="" width="17" height="17" class="mb-1 me-1"> ADD NEW</button>

        </div>
      </div>
      <div class="scrollable-table">
      <table class="tab-table table table-responsive table-hover" cdkDropList (cdkDropListDropped)="dropWeaknesses($event)">
        <tbody>
          <tr>
          <tr *ngFor="let weak of swotMatrix?.weaknesses; index as k" cdkDrag style=" background: white; cursor: move;">
            <th class="w-5 text-center">{{k+1}}</th>
            <td class="h-fs" [innerHTML]="weak.weakness"></td>
            <td class="status-width">
              <div *ngIf="weak.status == 0 || weak.status == undefined">
                <img src="../../../../assets/images/uploads/question-symbol.svg" alt=""> No Status Available
              </div>
              <div *ngIf="weak.status == 1">
                <img src="../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg" alt=""> Not
                Required to Address
              </div>
              <div *ngIf="weak.status == 2">
                <img src="../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg" alt="">
                Not
                Addressed
              </div>
              <div *ngIf="weak.status == 3">
                <img src="../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg" alt="">
                Partially Addressed
              </div>
              <div *ngIf="weak.status == 4">
                <img src="../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg" alt="">
                Fully Addressed
              </div>
            </td>
            <td class="w-15 pe-3">
              <div class="float-end">
                <button class="btn btn-secondary1 mb-1 mb-xxl-0 h-fs " (click)="onEditWeakness(weak)"
                  [disabled]="isAddNewWeaknessEnable">
                  <img src="../../../../assets/images/uploads/edit-box-info.svg" alt="" width="15" height="15" class="mb-1">
                  EDIT
                </button>
                <button class="btn bg-danger1 h-fs ms-1" (click)="onDeleteWeakness(k)"
                  [disabled]="isAddNewWeaknessEnable">
                  <img src="../../../../assets/images/uploads/delete-red.svg" alt="" width="15" height="15" class="mb-1"> DELETE </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>

    <!-- forth tab -->
    <div class="tab-pane fade" id="nav-threats" role="tabpanel" aria-labelledby="nav-threats-tab" tabindex="0">
      <div *ngIf="isAddNewThreatEnable" class="total-str">
        <div class="w-85">
         
          <p class="fs-20 fw-bold m-0">THREATS&nbsp;({{swotMatrix?.threats?.length}})</p>

          <div class="row g-3 w-100 max-width-none">
            <div class="col-8 col-xxl-10 px-0">
              <div>

                <p-editor [(ngModel)]="addThreat.threat" name="overview" (onTextChange)="TextLength($event,'threat')">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
              <!-- <span class="fs-13 float-end me-3">{{charLength['threat'] || 0}}/200</span> -->
            </div>
            <div class="col-4 col-xxl-2 px-0 pb-3 text-center d-flex align-items-end justify-content-center">
              <span>
                <p class="m-0 fw-bold">STATUS</p> Set in Business Loop Back
              </span>
            </div>
          </div>

        </div>

        <div class="d-flex flex-column">
          <button class="btn btn-info1 text-white" *ngIf="this.addbut" (click)="onAddThreat()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button>
          <button class="btn btn-info1 text-white" *ngIf="!this.addbut" (click)="onAddThreat()"><img src="../../../../assets/images/uploads/save.svg" alt="" width="17" height="17" class="mb-1 me-1">SAVE</button> 
          <button class="btn btn-secondary1 text-info1 mt-2"  (click)="onCancelThreat()"><img src="../../../../../../assets/images/uploads/reset-info.svg" alt="" width="17" height="17" class="mb-1 reset"> CANCEL</button>
        </div>

      </div>

      <div class="total-str" *ngIf="!isAddNewThreatEnable">
        <h5 class="count">THREATS&nbsp;({{swotMatrix?.threats?.length}})</h5>
        <div class="w-85"></div>
       
        <div class="d-flex flex-column">
         
          <button class="btn btn-info1 text-white" (click)="onAddNewThreat()"><img src="../../../../../../assets/images/uploads/wplus.svg" alt="" width="17" height="17" class="mb-1 me-1"> ADD NEW</button>

        </div>
      </div>
      <div class="scrollable-table">
      <table class="tab-table table table-responsive table-hover" cdkDropList (cdkDropListDropped)=" dropThreats($event)">
        <tbody>
          <tr *ngFor="let threat of swotMatrix?.threats; index as l" cdkDrag style=" background: white; cursor: move;">
            <th class="w-5 text-center">{{l+1}}</th>
            <td  class="h-fs" [innerHTML]="threat.threat"></td>
            <td class="status-width">
              <div *ngIf="threat.status == 0 || threat.status == undefined">
                <img src="../../../../assets/images/uploads/question-symbol.svg" alt=""> No Status Available
              </div>
              <div *ngIf="threat.status == 1">
                <img src="../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg" alt=""> Not
                Required to Address
              </div>
              <div *ngIf="threat.status == 2">
                <img src="../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg" alt="">
                Not
                Addressed
              </div>
              <div *ngIf="threat.status == 3">
                <img src="../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg" alt="">
                Partially Addressed
              </div>
              <div *ngIf="threat.status == 4">
                <img src="../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg" alt="">
                Fully Addressed
              </div>
            </td>
            <td class="w-15 pe-3">
              <div class="float-end">
                <button class="btn btn-secondary1 mb-1 mb-xxl-0 h-fs " (click)="onEditThreat(threat)"
                  [disabled]="isAddNewThreatEnable">
                  <img src="../../../../assets/images/uploads/edit-box-info.svg" alt="" width="15" height="15" class="mb-1">
                  EDIT
                </button>
                <button class="btn bg-danger1 h-fs ms-1" (click)="onDeleteThreat(l)"
                  [disabled]="isAddNewThreatEnable">
                  <img src="../../../../assets/images/uploads/delete-red.svg" alt="" width="15" height="15" class="mb-1"> DELETE </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>