import { Component } from '@angular/core';
import { LoadingService } from './providers/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'xirocco-upgrade';
  text2: any;

  constructor(public loadingService: LoadingService) {

  }

}
