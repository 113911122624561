<div class="prese-height">
    <section class="it-capability-heatmap text-center">
        <div class="container-fluid h-100" style="overflow-x: hidden !important;">
            <div class="row scrol-bar row-cols-11 gx-2 h-100">
                <div class="col mx-0 d-flex flex-column justify-content-between"
                    *ngFor="let item of heatmap?.departments">
                    <div class="cap-box bg-info-dark">
                        {{ item.display_name }}
                    </div>
                    <ng-container *ngFor="let cap of item.capabilities">
                        <div class="cap-box capability-{{
                cap.rag ? cap.rag : 'default'
              }}-block" (click)="displayObservations(cap, item)"
                            [ngClass]="{ 'cap-box1': checkObservationForCapability(cap) }">
                            {{ cap.display_name }}
                        </div>
                    </ng-container>
                    <!-- <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div> -->
                </div>

                <!-- <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-gray">
                    IT Business Partner Index
                </div>

                <div class=" cap-box bg-gray">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box">
                </div>
                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-gray">
                    IT Business Partner Index
                </div>

                <div class=" cap-box bg-gray">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>
                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>
                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-gray">
                    IT Business Partner Index
                </div>

                <div class=" cap-box bg-gray">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>
            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>


            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-gray">
                    IT Business Partner Index
                </div>

                <div class=" cap-box bg-gray">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>
                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>


            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-gray">
                    IT Business Partner Index
                </div>

                <div class=" cap-box bg-gray">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>


                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>
            </div>

            <div class="col mx-0">
                <div class=" cap-box bg-info-dark">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-danger-dark">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-green">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-danger2">
                    IT Business Partnership
                </div>


                <div class=" cap-box bg-warning1">
                    IT Business Partnership
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

                <div class=" cap-box">
                </div>

            </div> -->
            </div>
        </div>
    </section>
</div>