import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Appconfig } from 'src/app/configurations/appconfig';
import { CoreService } from '../core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../appservice/app.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessStrategyService extends CoreService {

  businessSwotAnalysis: string;
  bsObjectives: string;
  getLoopBack: string;
  postLoopBack: string;
  // Market condition screen
  marketConditions: string;
  postMarketConditions: string;
  baseMarketConditions: string;

  constructor(httpClient: HttpClient, private appService: AppService) {
    super(httpClient);
    this.setInitialValues();
  }

  setInitialValues() {

    this.businessSwotAnalysis = '/modules/businessswotanalysis';
    this.bsObjectives = '/modules/bs';
    this.getLoopBack = '/modules/loopback';
    this.postLoopBack = '/modules/businessswotanalysis';


    // Market condition screen
    this.marketConditions = '/modules/marketconditions';
    this.postMarketConditions = '/marketconditions/category';
    this.baseMarketConditions = '/marketconditions';
  }

  getLoopback() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + this.getLoopBack).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getBusinessSwotAnalysis() {
    // this.setInitialValues();
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + this.businessSwotAnalysis).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getBusinessObjectives() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + this.bsObjectives).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postLoopback(data: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + this.postLoopBack, payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }


  // Market Conditions


  getMarketCondition() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + this.marketConditions).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postMarketCategory(data: any, id?: string) {
    const payLoad: any = data;
    let payloadPath: string = this.postMarketConditions;
    if (id && id != '') {
      payloadPath = payloadPath + '/' + id;
    }

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + payloadPath, payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }
  
  sortMarketConditions(payload: any, type: String) {
    return this.post(`${Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY}/${this.appService.strategyId}/marketconditions/sort/${type}`, payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }
  
  sortObjectives(payload: any, type: String) {
    return this.post(`${Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY}/${this.appService.strategyId}/objectives/sort/${type}`, payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  deleteMarketCategory(id?: string) {

    let payloadPath: string = '/' + this.appService.strategyId + this.baseMarketConditions + '/' + id;

    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + payloadPath).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  deleteMarketTrend(categoryId?: string, conditionId?: string) {

    let payloadPath: string = '/' + this.appService.strategyId + this.baseMarketConditions + '/' + categoryId + '/' + conditionId;

    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + payloadPath).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  addCondition(data: any, id?: string) {
    const payLoad: any = data;
    let payloadPath: string = '/' + this.appService.strategyId + this.baseMarketConditions + '/' + id + '/addCondition';

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + payloadPath, payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  addResponse(data: any, categoryId?: string, conditionId?: string, isAdd?: boolean) {
    const payLoad: any = data;
    let payloadPath: string = '';
    if (isAdd)
      payloadPath = '/' + this.appService.strategyId + this.baseMarketConditions + '/' + categoryId + '/marketconditions' + '/' + conditionId + '/responses/';
    else
      payloadPath = '/' + this.appService.strategyId + this.baseMarketConditions + '/updatecategory/' + conditionId;
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + payloadPath, payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  SaveBusinessVision(request: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/modules/bs', request).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  getBusinessVision() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/modules/bs').pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  SaveGoal(request: any) {
    const apiUrl = request._id ? '/businessvision/goals/' + request._id : '/businessvision/goals';
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + apiUrl, request).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  DeleteGoal(request: any) {
    const apiUrl = '/businessvision/goals/' + request._id;
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + apiUrl, request).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  getGoals() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/modules/businessvisiongoals').pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  AddObjective(request: any, categoryId: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/businessstrategycategory/' + categoryId + '/objectives', request).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  EditObjective(req: any, objcetiveId: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/objectives/' + objcetiveId, req).pipe(map(
      respData => {
        return respData.body;
      }
    ));

  }


  deletevision(id?: string) {
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/businessstrategycategory/' + id).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  DeleteObjective(objcetiveId: any) {
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/objectives/' + objcetiveId).pipe(map(
      respData => {
        return respData.body;
      }
    ))
  }

  AddCategory(req: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/businessstrategycategory/', req).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  EditCategory(req: any, categoryId: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.BUSINESSSTRATEGY + '/' + this.appService.strategyId + '/businessstrategycategory/' + categoryId, req).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }
}
