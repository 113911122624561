import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/* Ext Library imports*/
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { ChartModule } from 'primeng/chart';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ChipModule } from 'primeng/chip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { InputComponent } from './components/input/input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { InputnumbersComponent } from './components/inputnumbers/inputnumbers.component';
import { InputswitchComponent } from './components/inputswitch/inputswitch.component';
import { TexteditorComponent } from './components/texteditor/texteditor.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioComponent } from './components/radio/radio.component';
import { ColorpickerComponent } from './components/colorpicker/colorpicker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { PasswordComponent } from './components/password/password.component';
import { ChipsComponent } from './components/chips/chips.component';
import { InputchipsComponent } from './components/inputchips/inputchips.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ButtonComponent } from './components/button/button.component';
import { IconbuttonComponent } from './components/iconbutton/iconbutton.component';
import { PanelComponent } from './components/panel/panel.component';
import { CardComponent } from './components/card/card.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { SplitterComponent } from './components/splitter/splitter.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ConfirmdialogComponent } from './components/confirmdialog/confirmdialog.component';
import { ModaldialogComponent } from './components/modaldialog/modaldialog.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UploadComponent } from './components/upload/upload.component';
import { MenuComponent } from './components/menu/menu.component';
import { BarchartComponent } from './components/barchart/barchart.component';
import { PiechartComponent } from './components/piechart/piechart.component';
import { DonutchartComponent } from './components/donutchart/donutchart.component';
import { LinechartComponent } from './components/linechart/linechart.component';
import { ToastComponent } from './components/toast/toast.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { InputHeaderComponent } from './components/input-header/input-header.component';
import { InputFooterComponent } from './components/input-footer/input-footer.component';
import { InputSidenavComponent } from './components/input-sidenav/input-sidenav.component';
import { SwotmatrixComponent } from './components/swotmatrix/swotmatrix.component';
import { HeatmapComponent } from './components/heatmap/heatmap.component';
import { HMapComponent } from './components/hmap/hmap.component';
import { OrgchartComponent } from './components/orgchart/orgchart.component';
import { LoopBackComponent } from './components/loop-back/loop-back.component';
import { CanvasComponent } from './components/canvas/canvas.component';
import { openimageuploadpopupComponent } from './components/popup/openimageuploadpopup.component';
import { SafePipe } from '../utils/safe.pipe';
import { HeatmapobservationsComponent } from './heatmapobservations/heatmapobservations.component';

import { ExecsummaryComponent } from '../modules/presentation/modules/itdiagnostic/components/execsummary/execsummary.component';
import { NeedforchangeComponent } from '../modules/presentation/modules/itdiagnostic/components/needforchange/needforchange.component';
import { ItswotmatrixComponent } from '../modules/presentation/modules/itdiagnostic/components/itswotmatrix/itswotmatrix.component';
import { KeyfindingsComponent } from '../modules/presentation/modules/itdiagnostic/components/keyfindings/keyfindings.component';
import { DatahmComponent } from '../modules/presentation/modules/itdiagnostic/components/datahm/datahm.component';
import { CyberhmComponent } from '../modules/presentation/modules/itdiagnostic/components/cyberhm/cyberhm.component';
import { ItcaphmComponent } from '../modules/presentation/modules/itdiagnostic/components/itcaphm/itcaphm.component';
import { ItinfrahmComponent } from '../modules/presentation/modules/itdiagnostic/components/itinfrahm/itinfrahm.component';
import { CriticalapphmComponent } from '../modules/presentation/modules/itdiagnostic/components/criticalapphm/criticalapphm.component';
import { VisonobjectiveComponent } from './visionobjective/visionobjective.component';
import { ColorPickerModule as ColorModule } from 'ngx-color-picker';
import { VisiongoalsComponent } from './visiongoals/visiongoals.component';
import { ITVisiongoalsComponent } from './itvisiongoals/itvisiongoals.component';
import { TableModule } from 'primeng/table';
import { NumberSuffixPipe } from './number-suffix.pipe';
import { HmapobservationsComponent } from './hmapobservations/hmapobservations.component';
import { OutputHmObservationsComponent } from './output-hm-observations/output-hm-observations.component';
import { SafeHtmlPipe } from './dom-sanitizer';
import { ThemeAlignComponent } from './theme-align/theme-align.component';
import { PortfolioThemePopupComponent } from './portfolio-theme-popup/portfolio-theme-popup.component';
import { NarrativePopupComponent } from './narrative-popup/narrative-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObjsharedPopupComponent } from './objshared-popup/objshared-popup.component';
import { ExesumpComponent } from './print/exesump/exesump.component';
import { NeedforchngpComponent } from './print/needforchngp/needforchngp.component';
import { ItswotpComponent } from './print/itswotp/itswotp.component';
import { ImageFallbackDirective } from '../directives/image-fallback.directive';
import { ApplicationdataPopupComponent } from './applicationdata-popup/applicationdata-popup.component';
@NgModule({
  providers: [MessageService],
  declarations: [
    ImageFallbackDirective,
    InputComponent,
    TextareaComponent,
    InputnumbersComponent,
    InputswitchComponent,
    TexteditorComponent,
    CheckboxComponent,
    RadioComponent,
    ColorpickerComponent,
    DropdownComponent,
    PasswordComponent,
    ChipsComponent,
    InputchipsComponent,
    MultiselectComponent,
    CalendarComponent,
    ButtonComponent,
    IconbuttonComponent,
    PanelComponent,
    CardComponent,
    AccordionComponent,
    SplitterComponent,
    TabsComponent,
    ConfirmdialogComponent,
    ModaldialogComponent,
    SidebarComponent,
    TooltipComponent,
    UploadComponent,
    MenuComponent,
    BarchartComponent,
    PiechartComponent,
    DonutchartComponent,
    LinechartComponent,
    ToastComponent,
    AvatarComponent,
    ProgressbarComponent,
    InputHeaderComponent,
    InputFooterComponent,
    InputSidenavComponent,
    SwotmatrixComponent,
    HeatmapComponent,
    HMapComponent,
    LoopBackComponent,
    OrgchartComponent,
    CanvasComponent,
    openimageuploadpopupComponent,
    SafePipe,
    HeatmapobservationsComponent,
    VisonobjectiveComponent,
    ApplicationdataPopupComponent,
    VisiongoalsComponent,
    HmapobservationsComponent,
    OutputHmObservationsComponent,
    ITVisiongoalsComponent,
    SafeHtmlPipe,
    NumberSuffixPipe,
    ThemeAlignComponent,
    PortfolioThemePopupComponent,
    NarrativePopupComponent,
    ObjsharedPopupComponent,
    ExesumpComponent,
    NeedforchngpComponent,
    ItswotpComponent,
    // ExecsummaryComponent,
    // NeedforchangeComponent,
    // ItswotmatrixComponent,
    // KeyfindingsComponent,
    // ItcaphmComponent,
    // ItinfrahmComponent,
    // DatahmComponent,
    // CyberhmComponent,
    // CriticalapphmComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    PanelModule,
    ButtonModule,
    ChipsModule,
    CalendarModule,
    CheckboxModule,
    ColorPickerModule,
    DropdownModule,
    EditorModule,
    InputSwitchModule,
    InputNumberModule,
    PasswordModule,
    DividerModule,
    RadioButtonModule,
    ConfirmDialogModule,
    DialogModule,
    SidebarModule,
    TooltipModule,
    FileUploadModule,
    ChartModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    AvatarModule,
    AvatarGroupModule,
    ChipModule,
    ProgressSpinnerModule,
    CardModule,
    NgxOrgChartModule,
    ColorModule,
    TableModule,
    DragDropModule
  ],
  exports: [
    ImageFallbackDirective,
    InputComponent,
    TextareaComponent,
    InputnumbersComponent,
    InputswitchComponent,
    TexteditorComponent,
    CheckboxComponent,
    RadioComponent,
    ColorpickerComponent,
    DropdownComponent,
    PasswordComponent,
    ChipsComponent,
    InputchipsComponent,
    MultiselectComponent,
    CalendarComponent,
    ButtonComponent,
    IconbuttonComponent,
    PanelComponent,
    CardComponent,
    AccordionComponent,
    SplitterComponent,
    TabsComponent,
    ConfirmdialogComponent,
    ModaldialogComponent,
    SidebarComponent,
    TooltipComponent,
    UploadComponent,
    MenuComponent,
    BarchartComponent,
    PiechartComponent,
    DonutchartComponent,
    LinechartComponent,
    ToastComponent,
    AvatarComponent,
    ProgressbarComponent,
    SwotmatrixComponent,
    HeatmapComponent,
    HMapComponent,
    LoopBackComponent,
    OrgchartComponent,
    CanvasComponent,
    openimageuploadpopupComponent,
    ITVisiongoalsComponent,
    SafeHtmlPipe,
    NumberSuffixPipe,
    ThemeAlignComponent,
    PortfolioThemePopupComponent,
    NarrativePopupComponent,
    // ExecsummaryComponent,
    // NeedforchangeComponent,
    // ItswotmatrixComponent,
    // KeyfindingsComponent
  ]
})
export class SharedModule { }