import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Department, Heatmap } from 'src/app/models/input/itdiagnostics/heatmap';
import { SharedService } from 'src/app/modules/home/data.service';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';

@Component({
  selector: 'app-heatmapobservations',
  templateUrl: './heatmapobservations.component.html',
  styleUrls: ['./heatmapobservations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class HeatmapobservationsComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;
  addressStatus: any = 'select';
  addressStatuses: any[] = [
    { name: 'No Status Available', image: '../../../../../../assets/images/uploads/editor-alert-icons/not-required-colored.svg' },
    { name: 'Partially Addressed', image: '../../../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg' },
    { name: 'Fully Addressed', image: '../../../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg' },
    { name: 'Not Required to Address', image: '../../../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg' },
    { name: 'Not Addressed', image: '../../../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg' }];
  isAdd = true;
  @Input() capability: any;
  @Input() heatMap: Heatmap;
  @Input() department: Department;
  @Input() type: any;
  observations: any;
  clonedObservations: any;
  observaton: any;
  @Output() saveClick: any = new EventEmitter();
  constructor(private itDiagService: itDiagnosticsService, private sharedService: SharedService) { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    if (this.department && this.capability) {
      this.observations = this.department.observations.filter((x: any) => x.capability == this.capability.code);
      this.clonedObservations = this.department.observations;
    }
  }

  addObservation() {
    this.isAdd = true;
  }

  onDataSave() {
    let i = this.observations.findIndex((x: any) => !x.desc);

    if (i > -1) {
      this.sharedService.alertNotification('error', 'Observations', 'Observation should not empty');
    }
    // this.department.observations = [];
    if (this.department && i == -1) {
      if (this.observaton) {
        this.observations.push({ capability: this.capability.code, desc: this.observaton, sort_order: "11", status: this.addressStatus });
        // this.department.observations.push({ capability: this.capability.code, desc: this.observaton, sort_order: "11", status: this.addressStatus });
        let index = this.heatMap.departments.findIndex((x: any) => x.code == this.department.code);
        this.heatMap.departments[index].observations.push({ capability: this.capability.code, desc: this.observaton, sort_order: "11", status: this.addressStatus });
      }
      // if (this.clonedObservations) {
      //   this.clonedObservations.forEach((element: any) => {
      //     this.department.observations.push(element);
      //   });
      // }
      // if (this.isAdd && this.observaton) {
      //   if (this.capability) {
      //     this.observations.push({ capability: this.capability.code, desc: this.observaton, sort_order: "11", status: this.addressStatus });
      //   }
      // }
      // let index = this.heatMap.departments.findIndex((x: any) => x.code == this.department.code);
      // this.heatMap.departments[index].observations = this.observations;
      this.itDiagService.postHeatMap(this.heatMap, this.type, this.heatMap._id).subscribe(response => {
        this.onCancel();
        // this.closebutton.nativeElement.click();
        this.saveClick.emit();
        this.sharedService.alertNotification('success', 'Observations', 'Saved Successfully');
      });
    }
  }

  onCancel() {
    this.observaton = '';
    this.addressStatus = 'select';
    this.isAdd = true;
  }

  getImage(ob: any) {
    if (ob.status) {
      let ad = this.addressStatuses.filter(x => x.name == ob.status);
      if (ad.length > 0)
        return ad[0].image;
      else
        return this.addressStatuses[0].image;
    }
    else
      return this.addressStatuses[0].image;
  }

  getStatus(ob: any) {
    if (ob.status) {
      let ad = this.addressStatuses.filter(x => x.name == ob.status);
      if (ad.length > 0)
        return ad[0].name;
      else
        return this.addressStatuses[0].name;
    }
    else
      return this.addressStatuses[0].name;
  }

  selectStatus(status: any) {
    this.addressStatus = status;
  }

  getImg() {
    if (this.addressStatus) {
      let ad = this.addressStatuses.filter(x => x.name == this.addressStatus);
      if (ad.length > 0)
        return ad[0].image;
      else
        return this.addressStatuses[0].image;
    }
    else
      return this.addressStatuses[0].image;
  }

  getSta() {
    if (this.addressStatus) {
      let ad = this.addressStatuses.filter(x => x.name == this.addressStatus);
      if (ad.length > 0)
        return ad[0].name;
      else
        return this.addressStatuses[0].name;
    }
    else
      return this.addressStatuses[0].name;
  }

  deleteObservation(i: any) {
    this.observations.splice(i, 1);
    this.department.observations.splice(i, 1)
  }
}
