// Business Strategy Loop Back

export interface ISwotBS {
    type?: any;
    loopbackStatusUpdated: boolean;

    archive?: boolean;
    cd?: any;
    start?: string;
    ud?: any;
    __v?: any;
    _id?: any;

    categories: ISwotCategory[];
}

export interface ISwotCategory {

    type?: any;
    loopbackStatusUpdated: boolean;

    archive?: boolean;
    cd?: any;
    start?: string;
    ud?: any;
    __v?: any;
    _id?: any;

    objectives: ISwotObjective[];
}

export interface ISwotObjective {

    title?: string;
    isChecked: boolean;
    progs: IProgram[];
    archive?: boolean;
    cd?: any;
    start?: string;
    ud?: any;
    __v?: any;
    _id?: any;
}

// Tech Strategy Loop Back

export interface ITechThemes {
    archive?: boolean;
    cd?: any;
    start?: string;
    ud?: any;
    __v?: any;
    _id?: any;
    sort: number;
    cat?: string;
    themes: ITheme[];
}

export interface ITheme {

    name?: string;
    isChecked: boolean;
    progs: IProgram[];
    _id?: any;
}



//Common

export interface IProgram {
    prgName?: string;
    _id?: any;
    prgType?: any;
    selected?: string;
    projects_depend?: any;
    checked: boolean;
    filtered: boolean;
}

export interface IObejctive {
    Id?: string,
    title?: string,
    isChecked: boolean,
    progs?: IProgram[];
}

export interface IProject {
    Id?: string,
    projId?: string,
    name?: string,
    isChecked: boolean,
    isParent: boolean
}
