import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CommonService } from 'src/app/helpers/common.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { UserService } from 'src/app/providers/user/user.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  accountDetails: any;
  avatar: SafeUrl;

  constructor(
    public userService: UserService,
    private router: Router,
    public dashboardService: DashboardService,
    private authService: AuthService,
    private commonService: CommonService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {
    // this.elem = document.documentElement;
  }

  init() {
    this.dashboardService.getAccountDetails$.subscribe((resp) => {
      this.accountDetails = resp;
    });
  }

  goToUserProfile() {
    this.router.navigate(['home', this.dashboardService.accountDetails.account._id, 'userprofile']);
  }

  gotoOverViewPage() {
    this.router.navigate(['home']);
  }

  goToHome() {
    this.dashboardService.isUserProfile = false;
    this.router.navigate(['home']);
  }

  goToUserSettings() {
    this.router.navigate(['home', this.dashboardService.accountDetails.account._id, 'userprofile', 'overview']);
  }

  logout() {
    this.authService.logout().subscribe({
      next: () => {
        this.commonService.clearBrowserStorages();
        this.router.navigate(['auth', 'login']);
      }, error: () => {
        this.commonService.clearBrowserStorages();
        this.router.navigate(['auth', 'login']);
      },
    });
  }

  getSelectedImage() {
    const avatar = this.commonService.getCurrentUser()?.avatar;
    if(avatar){
      this.dashboardService.getSelectedImage(avatar)
      .subscribe(url => this.avatar = avatar)
    } else{
      this.avatar = "../../../../../../assets/images/public/default-avatar.png";
    }
  }

  formatDate(date: string) {
    return this.datePipe.transform(date, 'MMM d, y, h:mm:ss a')
  }

}
