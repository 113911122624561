import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { PrintModel } from 'src/app/models/shared/printmodel';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private accountDetailsSubject = new BehaviorSubject<any>(null);
  accountDetails$ = this.accountDetailsSubject.asObservable();

  private personalizationSubject = new BehaviorSubject<any>(null);
  personalization$ = this.personalizationSubject.asObservable();

  // printableItems: PrintModel[] = [];
  isPrintPreviewOpened: boolean = false;

  printApisCount: number = 0;


  constructor(private messageService: MessageService, private sanitizer: DomSanitizer) {

  }




  setAccountDetails(accountDetails: any) {
    this.accountDetailsSubject.next(accountDetails);
  }

  setPersonalization(personalization: any) {
    this.personalizationSubject.next(personalization);
  }

  private refreshScreen = new BehaviorSubject<any>(null);
  refreshScreen$ = this.refreshScreen.asObservable();

  doRefresh(screenName: string) {
    this.refreshScreen.next(screenName)
  }


  alertNotification(type: string, title: string, desc: string) {
    this.messageService.add({ severity: type, summary: title, detail: desc, });
  }

  getHtmlContent(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }


}