import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { IOppurtunity, IStrength, ISwotMatrix, IThreat, IWeakness } from 'src/app/models/input/itdiagnostics/itswotmatrix';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';
import { LoadingService } from 'src/app/providers/loading/loading.service';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
const MAX_LENGTH = 200;
@Component({
  selector: 'app-swotmatrix',
  templateUrl: './swotmatrix.component.html',
  styleUrls: ['./swotmatrix.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None,

})
export class SwotmatrixComponent implements OnInit {

  @Input()
  screenName: string;

  swotMatrix: ISwotMatrix;
  addStrength: IStrength = {};
  addOppurtunity: IOppurtunity = {};
  addWeakness: IWeakness = {};
  addThreat: IThreat = {};
  isAddNewStrengthEnable: boolean = false;
  isAddNewOppurtunityEnable: boolean = false;
  isAddNewWeaknessEnable: boolean = false;
  isAddNewThreatEnable: boolean = false;
  charLength: any = [{ 'strength': 0 }, { 'opportunity': 0 }, { 'weakness': 0 }, { 'threat': 0 }];
  strtext: any;
  addbut: boolean;
  constructor(private itDiagService: itDiagnosticsService, private confirmationService: ConfirmationService, private loadingService: LoadingService,
    private msgService: MessageService) {

  }

  ngOnInit(): void {
    this.getSwotMatrix();
  }

  getSwotMatrix() {
    this.loadingService.apiLoader = true;
    this.itDiagService.getSwotAnalysis(this.screenName).subscribe({
      next: response => {
        this.loadingService.apiLoader = false;
        this.swotMatrix = response;
      }, error: (e) => {
        this.loadingService.apiLoader = false;
      }
    });
  }

  postSwotMatrix(payload: any) {
    this.loadingService.apiLoader = true;
    this.itDiagService.postSwotMatrix(payload, this.screenName).subscribe({
      next: response => {
        this.loadingService.apiLoader = false;
        this.getSwotMatrix();
      }, error: (e) => {
        this.loadingService.apiLoader = false;
      }
    });
  }

  // Strengths Tab 

  onAddNewStrength() {
    this.isAddNewStrengthEnable = true;
    this.addbut = true;
  }

  onAddStrength() {
    if (this.addStrength._id == null || this.addStrength._id == undefined) {
      let strength: IStrength = this.addStrength;
      this.swotMatrix.strengths.push(strength);
      this.msgService.add({
        severity: 'success',
        summary: 'Strength',
        detail: 'Saved Successfully'
      });
    } else {

      this.msgService.add({
        severity: 'success',
        summary: 'Strength',
        detail: 'Saved Successfully'
      });
    }

    let payload = { strengths: this.swotMatrix.strengths };
    this.postSwotMatrix(payload);
    this.onCancelStrength();
  }


  onEditStrength(strength: IStrength) {
    this.addStrength = strength;
    this.isAddNewStrengthEnable = true;
    this.Trimhtmltags(this.addStrength.strength);
    this.addbut = false;
    this.charLength['strength'] = this.strtext.length;
    console.log(this.charLength);

  }

  onDeleteStrength(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {

        this.swotMatrix.strengths.splice(index, 1);

        let payload = { strengths: this.swotMatrix.strengths };

        this.postSwotMatrix(payload);

        this.isAddNewStrengthEnable = false;
        this.msgService.add({
          severity: 'success',
          summary: 'Strength',
          detail: 'Deleted Successfully',
        });

      },
      reject: (type: any) => {

      }
    });


  }

  onCancelStrength() {
    this.addStrength = {};
    this.isAddNewStrengthEnable = false;

  }

  // textChanged($event: any) {
  //   if ($event.editor.getLength() > MAX_LENGTH) {
  //     $event.editor.deleteText(MAX_LENGTH, $event.editor.getLength());
  //   }
  // }

  // Oppurtunity Tab

  onAddNewOppurtunity() {
    this.isAddNewOppurtunityEnable = true;
    this.addbut = true;
  }

  onAddOppurtunity() {
    if (this.addOppurtunity._id == null || this.addOppurtunity._id == undefined) {
      let oppurtunity: IOppurtunity = this.addOppurtunity;
      this.swotMatrix.oppurtunities.push(oppurtunity);
      this.msgService.add({
        severity: 'success',
        summary: 'Opportunity',
        detail: 'Saved Successfully'
      });

    } else {
      this.msgService.add({
        severity: 'success',
        summary: 'Opportunity',
        detail: 'Saved Successfully'
      });
    }

    let payload = { oppurtunities: this.swotMatrix.oppurtunities };

    this.postSwotMatrix(payload);

    this.onCancelOppurtunity();
  }

  onEditOppurtunity(oppurtunity: IOppurtunity) {

    this.addOppurtunity = oppurtunity;
    this.isAddNewOppurtunityEnable = true;
    this.Trimhtmltags(this.addOppurtunity.oppurtunity);
    this.charLength['opportunity'] = this.strtext.length;
    this.addbut = false;

    //this.charLength=this.strtext.length || 0;
  }

  onDeleteOppurtunity(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {
        this.swotMatrix.oppurtunities.splice(index, 1);

        let payload = { oppurtunities: this.swotMatrix.oppurtunities };

        this.postSwotMatrix(payload);

        this.isAddNewOppurtunityEnable = false;
        this.msgService.add({
          severity: 'success',
          summary: 'Opportunity',
          detail: 'Deleted Successfully'
        });


      },
      reject: (type: any) => {

      }
    });
  }

  onCancelOppurtunity() {
    this.addOppurtunity = {};
    this.isAddNewOppurtunityEnable = false;
  }

  // Weakness Tab

  onAddNewWeakness() {
    this.isAddNewWeaknessEnable = true;
    this.addbut = true;
  }

  onAddWeakness() {
    if (this.addWeakness._id == null || this.addWeakness._id == undefined) {
      let weakness: IWeakness = this.addWeakness;
      this.swotMatrix.weaknesses.push(weakness);
      this.msgService.add({
        severity: 'success',
        summary: 'Weakness',
        detail: 'Saved Successfully'
      });
    } else {
      this.msgService.add({
        severity: 'success',
        summary: 'Weakness',
        detail: 'Saved Successfully'
      });

    }

    let payload = { weaknesses: this.swotMatrix.weaknesses };

    this.postSwotMatrix(payload);
    this.onCancelWeakness();


  }

  onEditWeakness(weakness: IWeakness) {
    this.addWeakness = weakness;
    this.isAddNewWeaknessEnable = true;
    this.Trimhtmltags(this.addWeakness.weakness);
    this.charLength['weakness'] = this.strtext.length;
    this.addbut = false;
  }

  onDeleteWeakness(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {
        this.msgService.add({
          severity: 'success',
          summary: 'Weakness',
          detail: 'Deleted Successfully'
        });

        this.swotMatrix.weaknesses.splice(index, 1);

        let payload = { weaknesses: this.swotMatrix.weaknesses };

        this.postSwotMatrix(payload);

        this.isAddNewWeaknessEnable = false;
      },
      reject: (type: any) => {

      }
    });
  }

  onCancelWeakness() {
    this.addWeakness = {};
    this.isAddNewWeaknessEnable = false;
  }

  // Threat Tab

  onAddNewThreat() {
    this.isAddNewThreatEnable = true;
    this.addbut = true;
  }

  onAddThreat() {
    if (this.addThreat._id == null || this.addThreat._id == undefined) {
      let threat: IThreat = this.addThreat;
      this.swotMatrix.threats.push(threat);
      this.msgService.add({
        severity: 'success',
        summary: 'Threat',
        detail: 'Saved Successfully'
      });
    } else {
      this.msgService.add({
        severity: 'success',
        summary: 'Threat',
        detail: 'Saved Successfully'
      });
    }

    let payload = { threats: this.swotMatrix.threats };

    this.postSwotMatrix(payload);

    this.onCancelThreat();

  }

  onEditThreat(threat: IThreat) {
    this.addThreat = threat;
    this.isAddNewThreatEnable = true;
    this.Trimhtmltags(this.addThreat.threat);
    this.charLength['threat'] = this.strtext.length;
    this.addbut = false;
  }

  onDeleteThreat(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirm',
      acceptButtonStyleClass: 'btn btn-dialog-info py-1 px-4',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'btn btn-dialog-danger py-1 px-4',
      accept: () => {
        this.swotMatrix.threats.splice(index, 1);

        let payload = { threats: this.swotMatrix.threats };

        this.postSwotMatrix(payload);

        this.isAddNewThreatEnable = false;
        this.msgService.add({
          severity: 'success',
          summary: 'Threat',
          detail: 'Deleted Successfully'
        });
      },
      reject: (type: any) => {

      }
    });
  }

  onCancelThreat() {
    this.addThreat = {};
    this.isAddNewThreatEnable = false;
  }
  TextLength(event: any, type: string) {
    this.charLength[type] = event.textValue.length;
  }
  Trimhtmltags(swatmatrixobjtext: any) {
    //this.charLength=0;
    this.strtext = swatmatrixobjtext;
    this.strtext = this.strtext.replaceAll('/', '');
    this.strtext = this.strtext.replace(/<p>|<strong>|<em>|<u>/gi, function (matched: any) {
      return '';
    });
  }
  dropStrengths(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.swotMatrix?.strengths, event.previousIndex, event.currentIndex);
    this.postSwotMatrix({ strengths: this.swotMatrix.strengths });
  }
  dropOpportunities(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.swotMatrix?.oppurtunities, event.previousIndex, event.currentIndex);
    this.postSwotMatrix({ oppurtunities: this.swotMatrix.oppurtunities });
  }
  dropWeaknesses(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.swotMatrix?.weaknesses, event.previousIndex, event.currentIndex);
    this.postSwotMatrix({ weaknesses: this.swotMatrix.weaknesses });
  }
  dropThreats(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.swotMatrix?.threats, event.previousIndex, event.currentIndex);
    this.postSwotMatrix({ threats: this.swotMatrix.threats });
  }
}
