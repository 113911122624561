<div class="right-container-filter d-grid">
    <!-- Filter Side bar -->
    <div class="sidebar-container">   
     <div class="sidebar sidebar-2 bg-white overflow-hidden sbar-h">
        <li class="sidebar-header d-flex align-items-center bg-secondary1 justify-content-between">
            <strong class="h-fs text-white"> SWOT CATEGORIES </strong>

        </li>
        <!-- scrollable section -->
        <div class="scroll-container">
            <ul class="li-pt">
                <li *ngFor="let category of swotCategories;index as j" class="border-bottom overflow-hidden "
                [ngClass]="selectedCategordIndex === j ? 'active' : ''">
                <a (click)="onSelectCategory(j,category)" href="javascript:void(0)"
                    class="list-group-item d-flex align-items-center ">
                    <div class="d-flex align-items-center me-auto">
                        <span class="mt-1 h-fs ">{{category == 'Oppurtunities'?'Opportunities': category}}</span>
                    </div>
                    <!-- <div class="i-icon" data-bs-toggle="modal" data-bs-target="#chartInfoModel">i</div> -->
                </a>
            </li>
              
            </ul>
        </div>
    </div>
   </div>

    <!-- Filter Side bar End-->
    <!-- accordion Container Start -->
    <div class="after-filter-container">

        <div class="detail-container h-101  ">

            <table class="table table-responsive table-bordered text-white border-0 m-0 scrolldown">
                <thead class="h-fs bg-info1">
                    <tr class="border-0">
                        <th class="text-uppercase background-col" style="width: 265px;">{{selectedSwotType == 'Oppurtunities'?'Opportunities': selectedSwotType}}</th>
                        <th class="text-uppercase background-col" style="width: 238px;">OUR RESPONSE</th>
                        <th class="text-uppercase background-col" *ngIf="screenName!='techstrategy'" style="width: 269px;">STRATEGIC OBJECTIVES</th>
                        <th class="text-uppercase background-col" *ngIf="screenName=='techstrategy'" style="width: 270px;">TECHNOLOGY THEMES</th>
                        <th class="text-uppercase background-col" style="width: 270px;">PROJECTS</th>
                        <th class="text-uppercase background-col" style="width: 293px;">STATUS</th>
                        <th class="text-uppercase background-col" style="width: 127px;">ACTION</th>
                    </tr>
                </thead>
                                 <!-- Strengths -->
                <tbody *ngIf="selectedCategordIndex === 0" class="h-fs">     
                    <tr *ngFor="let strength of swotMatrix?.strengths;index as i">
                        <td valign="middle" style="width: 265px;">
                            <div class="d-flex justify-content-between">
                                <p [innerHTML]="strength.strength" class="m-0"></p>
                        
                            </div>
                        </td>
                        <td class="align-bottom" style="height:auto; width: 238px;vertical-align: top !important;">
                            <textarea class="form-control h-fs px-0 py-0 " id="" placeholder="Our response"
                                [(ngModel)]="strength.response" rows="20" style="height: 200px;min-height: 200px"></textarea>
                        </td>
                        <td class="text-center" style="width: 269px;">
                            <div class="dropdown h-fs" >
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    [class.d-none]="strength.selectedObjectives && strength.selectedObjectives.length > 0" 
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <!-- {{ screenName == 'techstrategy' ? '+MAP THEMES' : '+ MAP OBJECTIVES' }}  -->
                                    <div *ngIf="screenName === 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label">MAP THEMES</span>
                                        </div>

                                    </div>

                                    <div *ngIf="screenName !== 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label">MAP OBJECTIVES</span>
                                        </div>

                                    </div>
                                </button>							
                                <button class="btn text-white dropdown-toggle form-control btn-info1  plus-button" type="button"
                                    [class.d-none]="!(strength.selectedObjectives && strength.selectedObjectives.length > 0)" 
                                    data-bs-toggle="dropdown" aria-expanded="false" >
                                    <span class="plus-var">+</span>
                                </button>
                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedObjectives;index as k" >
                                            <span  class="tags" >
                                            <a (click)="removeObj(strength, obj)" >  
                                                <span [innerHTML]="TrimHtmlTags(obj.title)"></span>
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                 height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                        </span>
                                    </div>
                                </div>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height: 300px;overflow-y: auto;"
                                    data-popper-placement="top-center">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            {{ screenName == 'techstrategy' ? 'THEMES' : ' OBJECTIVES' 
                                        }} 
                                        <!-- <img src="../../../../../../assets/images/uploads/search black.svg"
                                             alt="" class="float-end mt-1"> -->
                                        </a>
                                    </li>

                                    <li *ngFor="let obj of strength.objectives;index as j">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.title"
                                                [(ngModel)]="obj.isChecked" id="[{{j}}]"
                                                (change)="filterObjectives(strength)" />
                                                <span [innerHTML]="TrimHtmlTags(obj.title)"></span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </td>                       
                        <td style="width: 270px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedProjects && strength.selectedProjects.length > 0" >
                                    <div class="button-content">
                                        <span class="plus-align">+</span>
                                        <span class="button-label">MAP PROJECTS</span>
                                    </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1 align-start  plus-button" type="button"
                                [class.d-none]="!(strength.selectedProjects && strength.selectedProjects.length > 0)" 
                                data-bs-toggle="dropdown" aria-expanded="false" >
                                <span class="plus-var">+</span>
                            </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedProjects;index as k" >
                                           <span *ngIf="obj.isChecked" class="tags"> {{obj.name}} <a (click)="removeObjectives(strength, obj)">
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                        </span>
                                    </div>
                                </div>

                                <ul class="dropdown-menu h-fs p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px); height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" >
                                            PROJECTS 
                                            <!-- <img
                                                src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                                class="float-end mt-1"> -->
                                            </a>
                                    </li>

                                    <li class="bg-info1" *ngFor="let obj of strength.projects;index as k">
                                        <a *ngIf="obj.isParent" class="dropdown-item text-white"
                                            href="javascript:void(0)">
                                           <span [innerHTML]="TrimHtmlTags(obj.name)"></span>
                                         </a>

                                        <a *ngIf="!obj.isParent && obj.name" class="dropdown-item dropdown-sub-bg-info"
                                            href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.name"
                                            [(ngModel)]="obj.isChecked" id="[{{k}}]"
                                            (change)="filterProjects(strength)" />
                                            <span [innerHTML]="TrimHtmlTags(obj.name)"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td  style="width: 293px;" >
                            <div class="dropdown fs-15">
                                <button class="btn dropdown-toggle form-control btn-secondary1 mb-1" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"> <img
                                        [src]="getStatusImg(strength.status)" alt="" height="18px" class="me-2">
                                    <span [innerHTML]="getstrStatus(strength.status)"></span>
                                    </button>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                                    data-popper-placement="bottom-start">



                                    <li *ngFor="let status of swotstreStatus;" (click)="strength.status = status.code">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <img [src]="status.image" class="me-2" height="18px"> {{status.title}}</a>
                                    </li>

                                </ul>

                            </div>

                        </td>
                        <td style="width: 127px">
                            <div style="display: flex;">
                                <button class="btn btn-info1 ms-2 text-light" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Save"
                                    (click)="SaveSwot(selectedCategordIndex)"><img
                                        src="../../../../../../assets/images/uploads/save.svg" alt="" height="20px" width="20px"></button>
                                <button class="btn btn-info1 ms-2 text-light" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cancel"(click)="CancelSwot()"><img
                                    src="../../../../../../assets/images/uploads/reset-white.svg"alt="" height="20px" width="20px"></button>

                            </div>
                        </td>
                    </tr>
                </tbody>
                                  <!-- Opportunties -->
                <tbody *ngIf="selectedCategordIndex === 1" class="fs-15 fw-500">
                    <tr *ngFor="let strength of swotMatrix.oppurtunities;index as i">
                        <td style="width: 265px;">
                            <p [innerHTML]="strength.oppurtunity"></p>
                        </td>
                        <td class="align-bottom" style="height: 100px; width: 238px;">
                            <textarea class="form-control fs-15 px-0" id="" placeholder="Our response"
                                [(ngModel)]="strength.response" rows="2"></textarea>
                        </td>
                        <td class="text-center"  style="width: 269px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedObjectives && strength.selectedObjectives.length > 0" >
                                    <!-- {{ screenName == 'techstrategy' ? '+MAP THEMES' : '+ MAP OBJECTIVES' }}  -->
                                    <div *ngIf="screenName === 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label">MAP THEMES</span>
                                        </div>

                                    </div>

                                    <div *ngIf="screenName !== 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label" >MAP OBJECTIVES</span>
                                        </div>

                                    </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1 plus-button" type="button"
                                [class.d-none]="!(strength.selectedObjectives && strength.selectedObjectives.length > 0)" 
                                data-bs-toggle="dropdown" aria-expanded="false" >
                                <span class="plus-var">+</span>
                            </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedObjectives;index as k" >
                                            <span class="tags" >
                                            <a (click)="removeObj(strength, obj)"> {{TrimHtmlTags(obj.title)}} 
                                              
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                        </span>
                                    </div>
                                </div>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px; overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            {{ screenName == 'techstrategy' ? 'SEARCH THEMES' : 'SEARCH OBJECTIVES'
                                            }} <img src="../../../../../../assets/images/uploads/search black.svg"
                                                alt="" class="float-end mt-1"></a>
                                    </li>

                                    <li *ngFor="let obj of strength.objectives;index as j">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.title"
                                                [(ngModel)]="obj.isChecked" id="[{{j}}]"
                                                (change)="filterObjectives(strength)" /> {{TrimHtmlTags(obj.title)}}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 270px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedProjects && strength.selectedProjects.length > 0" >
                                    <div class="button-content">
                                        <span class="plus-align">+</span> <span class="button-label">MAP PROJECTS</span>
                                    </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1  plus-button" type="button"
                                [class.d-none]="!(strength.selectedProjects && strength.selectedProjects.length > 0)" 
                                data-bs-toggle="dropdown" aria-expanded="false" >
                                <span class="plus-var">+</span>
                                </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedProjects;index as k">
                                           <span  *ngIf="obj.isChecked" class="tags"> {{obj.name}} <a (click)="removeObjectives(strength, obj)" >
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                        </span>
                                    </div>
                                </div>

                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="#">
                                            SEARCH PROJECTS <img
                                                src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                                class="float-end mt-1"></a>
                                    </li>

                                    <li class="bg-info1" *ngFor="let obj of strength.projects;index as k">
                                        <a *ngIf="obj.isParent" class="dropdown-item text-white"
                                            href="javascript:void(0)">
                                            {{TrimHtmlTags(obj.name)}} </a>

                                        <a *ngIf="!obj.isParent && obj.name !=''"
                                            class="dropdown-item dropdown-sub-bg-info" href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.name"
                                                [(ngModel)]="obj.isChecked" id="[{{k}}]"
                                                (change)="filterProjects(strength)" />
                                            {{obj.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td style="width: 293px;">
                            <div class="dropdown fs-15">
                                <button class="btn dropdown-toggle form-control btn-secondary1 mb-1" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"> <img
                                        [src]="getStatusImg(strength.status)" alt="" height="18px" class="me-2">
                                    {{getoppStatus(strength.status)}} </button>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                                    data-popper-placement="bottom-start">



                                    <li *ngFor="let status of swotOPPStatus;" (click)="strength.status = status.code">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <img [src]="status.image" class="me-2" height="18px"> {{status.title}}</a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 127px">
                            <div style="display: flex;">
                                <button class="btn btn-info1 ms-2 text-light"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Save"
                                    (click)="SaveSwot(selectedCategordIndex)"><img
                                        src="../../../../../../assets/images/uploads/save.svg" alt="" height="20px" width="20px"></button>
                                <button class="btn btn-info1 ms-2 text-light" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cancel" (click)="CancelSwot()"><img
                              src="../../../../../../assets/images/uploads/reset-white.svg" alt="" height="20px" width="20px"></button>

                            </div>
                        </td>
                    </tr>
                </tbody>
                                  <!-- Weakness -->
                <tbody *ngIf="selectedCategordIndex === 2" class="fs-15 fw-500">
                    <tr *ngFor="let strength of swotMatrix.weaknesses;index as i">
                        <td style="width: 265px;">
                            <p [innerHTML]="strength.weakness"></p>
                        </td>
                        <td class="align-bottom" style="height: 100px; width: 238px;">
                            <textarea class="form-control fs-15 px-0" id="" placeholder="Our response"
                                [(ngModel)]="strength.response" rows="2"></textarea>
                        </td>
                        <td class="text-center" style="width: 269px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedObjectives && strength.selectedObjectives.length > 0" >
                                    <!-- {{ screenName == 'techstrategy' ?'+ MAP THEMES' : '+ MAP OBJECTIVES' }}  -->
                                    <div *ngIf="screenName === 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label">MAP THEMES</span>
                                        </div>

                                    </div>

                                    <div *ngIf="screenName !== 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label">MAP OBJECTIVES</span>
                                        </div>

                                    </div>
                                </button>
                                <button class="btn text-white dropdown-toggle form-control btn-info1  plus-button" type="button"
                                [class.d-none]="!(strength.selectedObjectives && strength.selectedObjectives.length > 0)" 
                                data-bs-toggle="dropdown" aria-expanded="false" >
                                <span class="plus-var">+</span>
                            </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedObjectives;index as k" class="tags">
                                           <span > <a (click)="removeObj(strength, obj)">{{TrimHtmlTags(obj.title)}} 
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span> 
                                        </span>
                                    </div>
                                </div>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            {{ screenName == 'techstrategy' ? 'SEARCH THEMES' : 'SEARCH OBJECTIVES'
                                            }}<img src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                                class="float-end mt-1"></a>
                                    </li>

                                    <li *ngFor="let obj of strength.objectives;index as j">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.title"
                                                [(ngModel)]="obj.isChecked" id="[{{j}}]"
                                                (change)="filterObjectives(strength)" /> {{TrimHtmlTags(obj.title)}}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 270px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedProjects && strength.selectedProjects.length>0">
                                    <div class="button-content">
                                        <span class="plus-align">+</span> <span class="button-label">MAP PROJECTS</span>
                                    </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1 plus-button" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                [class.d-none]="!(strength.selectedProjects && strength.selectedProjects.length>0)">
                                <span class="plus-align">+</span>
                                </button>


                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedProjects;index as k">
                                            <span  *ngIf="obj.isChecked" class="tags">
                                            {{obj.name}} <a (click)="removeObjectives(strength, obj)">
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                    </span>
                                    </div>
                                </div>

                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="#">
                                            SEARCH PROJECTS <img
                                                src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                                class="float-end mt-1"></a>
                                    </li>

                                    <li class="bg-info1" *ngFor="let obj of strength.projects;index as k">
                                        <a *ngIf="obj.isParent" class="dropdown-item text-white"
                                            href="javascript:void(0)">
                                            {{TrimHtmlTags(obj.name)}} </a>

                                        <a *ngIf="!obj.isParent" class="dropdown-item dropdown-sub-bg-info"
                                            href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.name"
                                                [(ngModel)]="obj.isChecked" id="[{{k}}]"
                                                (change)="filterProjects(strength)" />
                                            {{obj.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td style="width: 293px;">
                            <div class="dropdown fs-15">
                                <button class="btn dropdown-toggle form-control btn-secondary1 mb-1" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"> <img
                                        [src]="getStatusImg(strength.status)" alt="" height="18px" class="me-2">
                                    {{getStatus(strength.status)}} </button>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                                    data-popper-placement="bottom-start">



                                    <li *ngFor="let status of swotStatus;" (click)="strength.status = status.code">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <img [src]="status.image" class="me-2" height="18px"> {{status.title}}</a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 127px">
                            <div style="display: flex;">
                                <button class="btn btn-info1 ms-2 text-light"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Save"
                                    (click)="SaveSwot(selectedCategordIndex)"><img
                                        src="../../../../../../assets/images/uploads/save.svg" alt="" height="20px" width="20px"></button>
                                <button class="btn btn-info1 ms-2 text-light" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cancel" (click)="CancelSwot()"><img
                                    src="../../../../../../assets/images/uploads/reset-white.svg" alt="" height="20px" width="20px"></button>

                            </div>
                        </td>

                    </tr>
                </tbody>
                                  <!-- Threats -->
                <tbody *ngIf="selectedCategordIndex === 3" class="fs-15 fw-500">
                    <tr *ngFor="let strength of swotMatrix.threats;index as i">
                        <td style="width: 265px;">
                            <p [innerHTML]="strength.threat"></p>
                        </td>
                        <td class="align-bottom" style="height: 100px; width: 238px;">
                            <textarea class="form-control fs-15 px-0" id="" placeholder="Our response"
                                [(ngModel)]="strength.response" rows="2"></textarea>
                        </td>
                        <td class="text-center" style="width: 269px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedObjectives && strength.selectedObjectives.length>0">
                                    <div *ngIf="screenName === 'techstrategy'">

                                        <div class="button-content">
                                            <span class="plus-align">+</span>
                                            <span class="button-label">MAP THEMES</span>
                                        </div>

                                    </div>
                                    <div *ngIf="screenName !== 'techstrategy'">
                                    <div class="button-content">
                                        <span class="plus-align">+</span> <span class="button-label">MAP
                                            OBJECTIVES</span>
                                    </div>
                                </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1 plus-button" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                [class.d-none]="!(strength.selectedObjectives && strength.selectedObjectives.length>0)">
                                <span class="plus-var">+</span>
                                </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedObjectives;index as k" class="tags">
                                            <a (click)="removeObj(strength,obj)">{{TrimHtmlTags(obj.title)}} 
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            {{ screenName == 'techstrategy' ? 'SEARCH THEMES' : 'SEARCH OBJECTIVES'
                                            }} <img src="../../../../../../assets/images/uploads/search black.svg"
                                                alt="" class="float-end mt-1"></a>
                                    </li>

                                    <li *ngFor="let obj of strength.objectives;index as j">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.title"
                                                [(ngModel)]="obj.isChecked" id="[{{j}}]"
                                                (change)="filterObjectives(strength)" /> {{TrimHtmlTags(obj.title)}}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 270px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedProjects && strength.selectedProjects.length>0">
                                    <div class="button-content">
                                        <span class="plus-align">+</span> <span class="button-label">MAP PROJECTS</span>
                                    </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1 plus-button" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                [class.d-none]="!(strength.selectedProjects && strength.selectedProjects.length>0)">
                                <span class="plus-var">+</span>
                                </button>



                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedProjects;index as k" >
                                            <span *ngIf="obj.isChecked" class="tags">
                                            {{obj.name}} <a (click)="removeObjectives(strength, obj)">
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                        </span>
                                    </div>
                                </div>

                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="#">
                                            SEARCH PROJECTS <img
                                                src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                                class="float-end mt-1"></a>
                                    </li>

                                    <li class="bg-info1" *ngFor="let obj of strength.projects;index as k">
                                        <a *ngIf="obj.isParent" class="dropdown-item text-white"
                                            href="javascript:void(0)">
                                            {{TrimHtmlTags(obj.name)}} </a>

                                        <a *ngIf="!obj.isParent" class="dropdown-item dropdown-sub-bg-info"
                                            href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.name"
                                                [(ngModel)]="obj.isChecked" id="[{{k}}]"
                                                (change)="filterProjects(strength)" />
                                            {{obj.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td style="width: 293px;">
                            <div class="dropdown fs-15">
                                <button class="btn dropdown-toggle form-control btn-secondary1 mb-1" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"> <img
                                        [src]="getStatusImg(strength.status)" alt="" height="18px" class="me-2">
                                    {{getStatus(strength.status)}} </button>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                                    data-popper-placement="bottom-start">
                                    <li *ngFor="let status of swotStatus;" (click)="strength.status = status.code">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <img [src]="status.image" class="me-2" height="18px"> {{status.title}}</a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 127px">
                            <div style="display: flex;">
                                <button class="btn btn-info1 ms-2 text-light"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Save"
                                    (click)="SaveSwot(selectedCategordIndex)"><img
                                        src="../../../../../../assets/images/uploads/save.svg" alt="" height="20px" width="20px"></button>
                                <button class="btn btn-info1 ms-2 text-light" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cancel" (click)="CancelSwot()"><img
                                    src="../../../../../../assets/images/uploads/reset-white.svg" alt="" height="20px" width="20px"></button>

                            </div>
                        </td>
                    </tr>
                </tbody>
                          <!-- Need for change -->
                <tbody *ngIf="selectedCategordIndex === 4" class="fs-15 fw-500">
                    <tr *ngFor="let strength of swotMatrix.needForChange;index as i">
                        <td style="width: 265px;">
                            <p [innerHTML]="strength.category"></p>
                        </td>
                        <td class="align-bottom" style="height: 100px; width: 238px;">
                            <textarea class="form-control fs-15 px-0" id="" placeholder="Our response"></textarea>
                        </td>
                        <td class="text-center" style="width: 269px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                [class.d-none]="strength.selectedObjectives && strength.selectedObjectives.length>0">
                                <div *ngIf="screenName === 'techstrategy'">

                                    <div class="button-content">
                                        <span class="plus-align">+</span>
                                        <span class="button-label">MAP THEMES</span>
                                    </div>

                                </div>
                                <div *ngIf="screenName !== 'techstrategy'">
                                <div class="button-content">
                                    <span class="plus-align">+</span> <span class="button-label">MAP
                                        OBJECTIVES</span>
                                </div>
                            </div>
                            </button>
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                [class.d-none]="!(strength.selectedObjectives && strength.selectedObjectives.length>0)">
                                <span class="plus-var">+</span>
                                </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedObjectives;index as k" class="tags">
                                            {{TrimHtmlTags(obj.title)}} <a href="#">
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            {{ screenName == 'techstrategy' ? 'SEARCH THEMES' : 'SEARCH OBJECTIVES'
                                            }} <img src="../../../../../../assets/images/uploads/search black.svg"
                                                alt="" class="float-end mt-1"></a>
                                    </li>

                                    <li *ngFor="let obj of strength.objectives;index as j">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.title"
                                                [(ngModel)]="obj.isChecked" id="[{{j}}]"
                                                (change)="filterObjectives(strength)" />
                                            {{TrimHtmlTags(obj.title)}}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 270px;">
                            <div class="dropdown h-fs">
                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    [class.d-none]="strength.selectedProjects && strength.selectedProjects.length>0">
                                    <div class="button-content">
                                        <span class="plus-align">+</span>
                                        <span class="button-label">MAP PROJECTS</span>
                                    </div>
                                </button>

                                <button class="btn text-white dropdown-toggle form-control btn-info1 small-button" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                [class.d-none]="!(strength.selectedProjects && strength.selectedProjects.length>0)">
                                <span class="plus-var">+</span>
                                </button>

                                <div class="d-flex justify-content-between">
                                    <div class="text-dark tags-overflow">
                                        <span *ngFor="let obj of strength.selectedProjects;index as k" class="tags">
                                            {{obj.name}} <a href="#">
                                                <img src="../../../../../../assets/images/uploads/close-red.svg"
                                                    height="10" width="10" class="ms-1">
                                            </a>
                                        </span>
                                    </div>
                                </div>

                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);height:300px;overflow-y: auto;"
                                    data-popper-placement="bottom-start">
                                    <li class="bg-secondary">
                                        <a class="dropdown-item" href="#">
                                            SEARCH PROJECTS <img
                                                src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                                class="float-end mt-1"></a>
                                    </li>

                                    <li class="bg-info1" *ngFor="let obj of strength.projects;index as k">
                                        <a *ngIf="obj.isParent" class="dropdown-item text-white"
                                            href="javascript:void(0)">
                                            {{TrimHtmlTags(obj.name)}} </a>

                                        <a *ngIf="!obj.isParent" class="dropdown-item dropdown-sub-bg-info"
                                            href="javascript:void(0)">
                                            <input class="form-check-input me-2" type="checkbox" value="obj.name"
                                                [(ngModel)]="obj.isChecked" id="[{{k}}]"
                                                (change)="filterProjects(strength)" />
                                            {{obj.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td style="width: 293px;">
                            <div class="dropdown fs-15">
                                <button class="btn dropdown-toggle form-control btn-secondary1" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"> <img
                                        [src]="getStatusImg(strength.status)" alt="" height="18px" class="me-2">
                                    {{getStatus(strength.status)}} </button>
                                <ul class="dropdown-menu fs-15 p-0 w-100 " aria-labelledby="dropdownMenuLink"
                                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                                    data-popper-placement="bottom-start">



                                    <li *ngFor="let status of swotStatus;" (click)="strength.status = status.code">
                                        <a class="dropdown-item" href="javascript:void(0)">
                                            <img [src]="status.image" class="me-2" height="18px"> {{status.title}}</a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                        <td style="width: 127px">
                            <div style="display: flex;">
                                <button class="btn btn-info1 ms-2 text-light"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Save" 
                                    (click)="SaveSwot(selectedCategordIndex)" alt="" height="20px" width="20px"><img
                                        src="../../../../../../assets/images/uploads/save.svg" alt=""></button>
                                <button class="btn btn-info1 ms-2 text-light" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cancel" (click)="CancelSwot()"><img
                                    src="../../../../../../assets/images/uploads/reset-white.svg" alt="" height="20px" width="20px"></button>

                            </div>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
    <!-- accordion Container Start -->

    
    <p-toast position="top-center"></p-toast>
</div>