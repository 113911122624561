<div class="modal fade" id="ImageUpload" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header py-2 bg-info1 text-light">
        <h1 class="modal-title fs-18" id="staticBackdropLabel"><img
            src="../../../../../../assets/images/uploads/handshake.svg" class="me-2">SELECT IMAGE/ICON</h1>
        <div>
          <button type="button" #closeModal class="btn btn-info2 text-light rounded-circle" data-bs-dismiss="modal" (click)="closeModalFun()">
            <img src="../../../../../../assets/images/uploads/close-white.svg" height="11px">
          </button>
        </div>
      </div>
      <div class="modal-body px-0 py-0 d-flex">
        <div class="popup-sidemenu fs-15">
          <ul>
            <li (click)="setActiveItem('upload')" [ngClass]="{'active': activeItem === 'upload'}">Upload From Device</li>
            <li (click)="setActiveItem('unsplash')" [ngClass]="{'active': activeItem === 'unsplash'}">Image From Unsplash</li>
            <li (click)="setActiveItem('line_icons')" [ngClass]="{'active': activeItem === 'line_icons'}">Line Icons</li>
            <li (click)="setActiveItem('rich_icons')" [ngClass]="{'active': activeItem === 'rich_icons'}">Rich Icons</li>
          </ul>
        </div>
        <!-- <div *ngIf="activeItem === 'unsplash'" class="image-container upload-container w-100" infiniteScroll (scrolled)="getUnsplash()"> -->
        <div *ngIf="activeItem === 'unsplash'" class="image-container upload-container w-100">
          <div class="d-flex align-items-center">
            <div class="search d-flex">
              <input type="search hide-input" placeholder="Search" class="show-input" (change)="onSearchUnsplash($event)">
              <button><img src="../../../../../../assets/images/uploads/search-info.svg"></button>
            </div>
          </div>
          <div class="uploaded-list container-fluid p-0" style="overflow:scroll;">
            <div class="row row-cols-6 g-12">

              <div class="col " *ngFor="let file of unsplashImages">
                <div class="uploaded-item position-relative" (click)="setImage(file.links.download)">
                  <img [src]="file.links.download" class="img-fluid">
                  <div class="file-detail justify-content-between align-items-center position-absolute w-100">
                    <p class="m-0 fs-13 ms-3 text-white">{{ file.links.download ? file.links.download : ''}}</p>
                    <button class="delete-btn"><img
                        src="../../../../../../assets/images/uploads/delete-white.svg"></button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div *ngIf="activeItem === 'line_icons'" class="image-container upload-container w-100" infiniteScroll (scrolled)="getLineIcons()">
          <div class="d-flex align-items-center mb-3">
            <div>
              
              <!-- <div *ngIf="line_icons_temp_selected_cat.length > 0">
                <button (click)="getLineIcons()">Apply</button>
                <button (click)="resetLineIconCategories()">Clear All</button>
              </div>
              <div class="cat-container">
                <div class="cat-item" *ngFor="let cat of line_icons_categories">
                  <div class="" (click)="setLineIconCategory(cat)">
                    {{cat}}
                    <span (click)="removeLineIconCategory($event, cat)" *ngIf="line_icons_temp_selected_cat.indexOf(cat) !== -1" class="close-black">
                      <img src="../../../../../../assets/images/uploads/close-white.svg">
                    </span>
                  </div>
                </div>
              </div> -->
            
              <div class="dropdown">
                <button style="overflow: hidden;" class="btn dropdown-toggle form-control bg-secondary-light drop-vis" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="drop-text">Select your line icon</span>
                </button>
                <div class="dropdown-menu">
                    <div class="drop-menu">
                        <input type="search" placeholder="Search" class="drop-search" [(ngModel)]="searchText"
                               (input)="filterLineIconCategories()" />
                        <button class="drop-icon">
                            <img src="../../../../../../assets/images/uploads/search-info.svg" width="20px" height="20px">
                        </button>
                    </div>
                    <div class="cat-item" *ngFor="let cat of filteredLineIconCategories">
                        <label>
                            <input type="checkbox" (click)="updateLineIconCategoryAndFetchIcons(cat)"
                                   [(ngModel)]="line_icons_temp_selected_cat[cat]"/> {{ cat }}
                        </label>
                    </div>
                </div>
            </div>             
            </div>
            <div class="search d-flex ms-5">
              <input type="search hide-input" placeholder="Search" class="show-input" (change)="onSearchLineIcons($event)">
              <button><img src="../../../../../../assets/images/uploads/search-info.svg"></button>
            </div>
          </div>
          <div class="uploaded-list container-fluid p-0 " style="overflow:scroll;">
            <div class="row row-cols-6 g-12">

              <div class="col " *ngFor="let iconObj of line_icons">
                <div class="uploaded-item1 position-relative ms-5" (click)="setLineIcon(iconObj.file, iconObj.folder)">
                  <img [src]="getLineIconURL(iconObj.file, iconObj.folder)" width="30" height="30" class="img-fluid">
                  <!-- <div class="file-detail justify-content-between align-items-center position-absolute w-100">
                    <p class="m-0 fs-13 ms-3 text-white">{{ file.links.download ? file.links.download : ''}}</p>
                  </div> -->
                </div>
                

              </div>
              <div *ngIf="line_icons.length === 0" class="no-icons">{{searchResponseText}}</div>
            </div>

          </div>
        </div>
        <div *ngIf="activeItem === 'upload'" class="upload-container w-100 overflow-auto">

          <div class="d-flex justify-content-between">
            <p class="fs-24 fw-500">Upload From Device</p>

            <!-- <div class="d-flex align-items-center">
              <div class="search d-flex">
                <input type="search hide-input" placeholder="Search" class="show-input">
                <button><img src="../../../../../../assets/images/uploads/search-info.svg"></button>
              </div>
            </div> -->
          </div>

          <div class="upload-block text-center">
            <p class="fs-20 fw-500">+ Drag & Drop Your Files Here</p>
            <p class="fs-18">or</p>
            <button class="btn btn-info1 text-white fw-500" (click)="file.click()"><img
                src="../../../../../../assets/images/uploads/upload-btn.svg" class="me-2"> UPLOAD
              FROM DEVICE</button>
            <input type="file" style="display:none" accept="image/*" #file (change)="OnImageSelected($event)">
          </div>

          <p class="fs-24 fw-500 mt-4 title">Your Files</p>

          <div class="uploaded-list container-fluid p-0" style="overflow:scroll;">
            <div class="row row-cols-6 g-12">

              <div class="col " *ngFor="let file of uploaded">
                <div class="uploaded-item position-relative" (click)="setImage(file.url)">
                  <img [src]="file.awsURL" class="img-fluid" alt="">
                  <div class="file-detail justify-content-between align-items-center position-absolute w-100">
                    <!-- <p class="m-0 fs-13 ms-3 text-white">{{ file.awsURL ? file.awsURL : ''}}</p> -->
                    <button class="delete-btn" (click)="deleteImage($event, file)"><img
                        src="../../../../../../assets/images/uploads/delete-white.svg" style="height: unset;"></button>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
        <div *ngIf="activeItem === 'rich_icons'" class="rich_icons">
          <div class="loader" *ngIf="!iframeLoaded" class="loader">Loading rich icons...</div>
          <iframe #richIconsIFrame (load)="onIframeLoad()" src="https://main--inspiring-bonbon-e38161.netlify.app/"></iframe>
        </div>
      </div>
    </div>
  </div>
  
</div>