// import {
//   Component,
//   ElementRef,
//   Input,
//   OnInit,
//   Renderer2,
//   ViewChild,
//   ViewEncapsulation,
// } from '@angular/core';
// import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';
// import { Guid } from 'guid-typescript';
// import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
// import { AppService } from 'src/app/providers/appservice/app.service';
// import { CommonService } from 'src/app/helpers/common.service';
// import { DialogService } from 'primeng/dynamicdialog';


// @Component({
//   selector: 'app-orgchart',
//   templateUrl: './orgchart.component.html',
//   styleUrls: ['./orgchart.component.scss'],
//   encapsulation: ViewEncapsulation.None,
// })
// export class OrgchartComponent implements OnInit {
//   @Input()
//   chartType: any;
//   @ViewChild('chartPopupBtn') chartPopupBtn: any;
//   @ViewChild('empPopup', { static: false }) empPopup!: ElementRef;

//   constructor(private itDiagService: itDiagnosticsService, public manageDataService: ManagedataService ,  private appService: AppService, private commonSvc: CommonService, private modalService: DialogService) { }

//   nodes: any = [];
//   defaultImg: any = '../../../../../../assets/images/uploads/user.png';
//   selectedNode: any = {
//     name: '',
//     cssClass: '',
//     image: '../../../../../../assets/images/uploads/user.png',
//     title: '',
//     isDefaultNode: null,
//     childs: [],
//   };
//   currentNode: any;
//   isAddSuccessor: boolean = false;
//   currentSuccessor: any = {
//     empId: '',
//     name: '',
//     cssClass: '',
//     image: '../../../../../../assets/images/uploads/user.png',
//     title: '',
//     childs: [],
//   };

//   chartDropdownOptions: any = [
//     {
//       name: 'color1',
//       code: 'color-1',
//     },
//     {
//       name: 'color2',
//       code: 'color-2',
//     },
//     {
//       name: 'color3',
//       code: 'color-3',
//     },
//     {
//       name: 'color4',
//       code: 'color-4',
//     },
//     {
//       name: 'color5',
//       code: 'color-5',
//     },
//     {
//       name: 'color6',
//       code: 'color-6',
//     },
//   ];

//   addColor: any = '';
//   addSuccessorColor: any = '';
//   public chevronHeight: any = 'default(1x)';
//   public chevronType: any = 'colour chevrons individually';
//   public chevronColor: any = '#003a96';
//   public chevronText_Color: any = '#ffffff';

//   public schevronHeight: any = 'default(1x)';
//   public schevronType: any = 'colour chevrons individually';
//   public schevronColor: any = '#003a96';
//   public schevronText_Color: any = '#ffffff';
//   hrData: any[] = [];
//   selectedEmp: string="Select Employee";
//   selectedSuccesorEmp: string= "Select Employee";
//   currentCompanyInfo: any; 
//   newNodes : any[] = [];
//   isEmpValid: boolean = true;
//   isSEmpValid: boolean = true;
//   addedEmpList: any[] =[];
//   ngOnInit(): void {
//     //this.init();
// this.getCompanyInfo();
// this.getHRData();

  
    
//   }
// getCompanyInfo() {
//   this.commonSvc.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => {
//     this.currentCompanyInfo  = x;
//   })

// }
//   getOrgChart() {
//     this.itDiagService.getOrgChart().subscribe(res => {
//       if(res.orgChart) {
//         const resultNodes = JSON.parse(res.orgChart);
//         if (resultNodes.length > 0) {
//           this.nodes = resultNodes;
//           this.nodes[0].image='https://s3.eu-west-2.amazonaws.com/xirocco.io/assets/dashbg.jpg';
//           this.CreateCSS();
//         }
//         else {
//          this.CreateRootNode();
//         }
//       }
//       else {
//         this.CreateRootNode();
//       }
//     })
//   }

//   CreateCSS()
//   {
//     this.nodes[0].childs.forEach((node : any)=> {
//       this.generateCSS(node);
//     });
//   }

//   CreateRootNode() {
//     if(this.currentCompanyInfo) {
//       this.nodes.push({
//       nodeid: this.newGuid,
//         name: this.currentCompanyInfo.name,
//         cssClass: 'company_styles',
//         image: 'https://s3.eu-west-2.amazonaws.com/xirocco.io/assets/dashbg.jpg',
//         title: 'Company',
//         isRootNode: true,
//         childs: [],
//       });
//       let req = {
//         orgChart: this.nodes
//       }
//       this.itDiagService.saveOrgChart(req).subscribe(res => {
//         console.log(res);
//       });
//     }
//   }

//  replaceSpacesWithUnderscores(name: string): string {
//     return name.replace(/\s/g, '_');
//   }
  
//   onItemClick(evt: any,popupContent? : any) {
   
//     this.selectedNode = {
//       nodeid: evt.nodeid,
//       name: evt.name,
//       cssClass: this.replaceSpacesWithUnderscores(evt.name) + '_' + evt.title,
//       image: evt.image,
//       title: evt.title,
//       isRootNode: evt.isRootNode,
//       childs: evt.childs,
//       empId: evt.empId
//     };
//     this.selectedEmp = evt.empId;
// this.chevronText_Color=evt.color;
//     this.currentNode = evt;
//     this.chartPopupBtn.nativeElement.click();
//   }

//   updateNode(evt: any) {
//     console.log(this.selectedEmp);
//     console.log(this.chevronText_Color);
//     console.log(this.selectedSuccesorEmp);
   
//     const EmpData=this.hrData.find(x=>x._id == this.selectedEmp);
//     const SuccessorEmpData=this.hrData.find(x=>x._id == this.selectedSuccesorEmp);
//     let tempNodes = JSON.parse(JSON.stringify(this.nodes));
//     if(tempNodes)
//     {
//         if(this.selectedNode.isRootNode)
//         {
//           if(tempNodes && tempNodes.length>0 && !tempNodes[0].childs) {
//               tempNodes[0].childs=[];
//           }
//             this.FillSuccessor();
//             if(this.selectedSuccesorEmp && this.selectedSuccesorEmp == 'Select Employee') {
//               this.isSEmpValid = false;
//               return;
//             }
//             tempNodes[0].childs.push(this.currentSuccessor);
//       }
//       else {
//         if(this.isAddSuccessor &&  this.selectedSuccesorEmp && this.selectedSuccesorEmp == 'Select Employee') {
//           this.isSEmpValid =false;
//           return;
//         }
//               tempNodes[0].childs.map((element: any) => {
//             this.CheckChilds(element);
//           });
//       }
//       // if(tempNodes[0].childs && tempNodes[0].childs.length>0. & !this.selectedNode.isRootNode)
//       // {
//       //     tempNodes[0].childs.map((element: any) => {
//       //       this.CheckChilds(element);
//       //     });
//       // }
//       this.nodes = tempNodes; 
//     }
//     this.SaveOrgChart(this.nodes);
//     this.isAddSuccessor = false;
//     this.addSuccessorColor = '';
//     this.ResetData();
//     this.empPopup.nativeElement.style.display = 'none';
  
//   }

//    callRecursively(node: any) {
//     if (node['childs'].length>0) {
//       node.childs.forEach((childNode: any) => {
//         this. callRecursively(childNode);
//       });
//     }
//     else {
//       if(node['name'] == this.selectedNode.name)
//         return node;
//     }
//   }
// SaveOrgChart(nodesObj: any) {
//   let req = {
//     orgChart: nodesObj
//   }

//   this.itDiagService.saveOrgChart(req).subscribe(res => {
//    this.CreateCSS();
//    this.getOrgChart();
//    window.location.href = window.location.pathname;
//   });
// }
//   FillSuccessor() {
//     const hrObj = this.getEmployeeObject(this.selectedSuccesorEmp);
//     if(hrObj) {
//       this.currentSuccessor.nodeid = this.newGuid();
//       this.currentSuccessor.name=hrObj.empName;
//       this.currentSuccessor.empId = hrObj._id;
//       this.currentSuccessor.title=hrObj.desig;
//       this.currentSuccessor.image=hrObj.pic;
//       this.currentSuccessor.cssClass =this.replaceSpacesWithUnderscores(hrObj.empName) + '_' + this.replaceSpacesWithUnderscores(hrObj.desig);
//       this.currentSuccessor.color = this.schevronText_Color;
 
//     }
//   }

//   CheckChilds(x: any) {
//     if (x.nodeid == this.currentNode.nodeid) {
//       if (!x.childs)
//         x.childs = [];
//         if(this.isAddSuccessor) {
//           this.FillSuccessor();
//           x.childs.push(this.currentSuccessor);
//         }
          
//       const hrObj =this.getEmployeeObject(this.selectedNode.empId);
//       x.title = hrObj.desig;
//       x.name = hrObj.empName;
//       x.image = this.selectedNode.image;
//       x.empId = this.selectedEmp;
//       x.cssClass = this.replaceSpacesWithUnderscores(hrObj.empName) + '_' + this.replaceSpacesWithUnderscores(hrObj.desig);
//       x.color = this.chevronText_Color;
//       x.color=this.chevronText_Color

//       x.isRootNode = false;
//     }
//     else {
//       x.childs.map((y: any) => {
//         this.CheckChilds(y);
//       });
//     }
//   }

//   addSuccessor() {
//     this.isAddSuccessor = true;
//     this.currentSuccessor = {
//       name: '',
//       cssClass: '',
//       image: '../../../../../../assets/images/uploads/user.png',
//       title: '',
//       isDefaultNode: this.newGuid(),
//       childs: [],
//     };
//   }

//   newGuid() {
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//       var r = Math.random() * 16 | 0,
//       v = c == 'x' ? r : (r & 0x3 | 0x8);
//       return v.toString(16);
//     });
//   }

//   getHRData() {
//     this.manageDataService
//       .getHRDataForProject(this.appService.currentActivatedFeature.strategyId)
//       .subscribe({
//         next: (res) => {
//           // this.hrData = res.filter((emp: any)=>{
//           //   const addedEmp = this.addedEmpList.find((e: any)=> e == emp._id);
//           //   if(!addedEmp) {
//           //     return emp;
//           //   }
//           // });
//           this.hrData =res;
//           this.getOrgChart();
//         },
//         error: (e) => {
//           console.log(e);
//         },
//         complete: () => console.info('complete'),
//       });
//   }

//   getEmployeeObject(_id : any) {
//     if(this.hrData) {

//       return this.hrData.find(x=>x._id == _id);
//     }
//   }

//   ResetData() {
// this.chevronText_Color="#FFFFFF";
// this.schevronText_Color="#FFFFFF";
// this.selectedEmp="Select Employee";
// this.selectedSuccesorEmp ="Select Employee";
//   }
//   Cancel() {
//     this.isAddSuccessor=false;
//     this.isEmpValid = true;
//     this.isSEmpValid = true;
//     this.getOrgChart();
//   }

//   DeleteNode(nodeObj: any) {
//     this.newNodes = JSON.parse(JSON.stringify(this.nodes));
//     this.newNodes[0].childs = [];
//     this.nodes[0].childs.map((node:any)=> { 
//       if(node.childs.length == 0){
//       if (node.nodeid !=  this.selectedNode.nodeid) {
//         this.newNodes[0].childs.push(node);
//       }
//     }
//     else {
//       if (node.nodeid !=  this.selectedNode.nodeid) {
//         this.newNodes[0].childs.push(node);
//       }
//       this.ExcludeDelNode(node);
//     }
//     });
//     this.SaveOrgChart(this.newNodes);
//     setTimeout(() => {
//       this.getOrgChart();
//     }, 1000);
    
//     console.log(this.newNodes);
    
//   }
//   ExcludeDelNode(node?: any)
//   {
//     if(node) {
//       node.childs.map((n: any) => {
//         if(n.nodeid == this.selectedNode.nodeid)
//         {
//           node.childs.splice(n,1);
//         }
//         this.ExcludeDelNode(n);
//       })
//     }
//   }
//   onSuccessorEmpChange(evt : any) {
//     this.isSEmpValid =true;
//   }

//   onOpenChartPopup(content: any) {
//     this.modalService.open(content,{
//       header:'Employee',
//       width: '1920px',
//       height:'1080px'
//     });
//   }

//   generateCSS(node: any) {
//     if(node) {
//       const txtColor = this.invertColor(node.color, true);
//       let text = '.' + node.cssClass +'{background-color: '+ node.color +';color: '+ txtColor +';}';
//       document.getElementsByTagName('style')[0].append(text);
//       // this.addedEmpList.push(node.empId);
//       const hrObj=this.hrData.find((h:any)=>h._id == node.empId);
//       node.empName =hrObj.name;
//       node.title = hrObj.desig;
//       node.image=hrObj.pic;
//     }
//     if(node.childs.length > 0)
//     {
//       node.childs.map((childNode: any) => {
//         this.generateCSS(childNode);
//       });
//     }
//   }
//   invertColor(hex: any, bw?: boolean) {
//     if (hex.indexOf('#') === 0) {
//       hex = hex.slice(1);
//   }
//   // convert 3-digit hex to 6-digits.
//   if (hex.length === 3) {
//       hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//   }
//   if (hex.length !== 6) {
//       throw new Error('Invalid HEX color.');
//   }
//   var r = parseInt(hex.slice(0, 2), 16),
//       g = parseInt(hex.slice(2, 4), 16),
//       b = parseInt(hex.slice(4, 6), 16);
//   if (bw) {
//       // https://stackoverflow.com/a/3943023/112731
//       return (r * 0.299 + g * 0.587 + b * 0.114) > 186
//           ? '#000000'
//           : '#FFFFFF';
//   }
//   // invert color components
//    var r1 = (255 - r).toString(16),
//    g1 = (255 - g).toString(16),
//    b1 = (255 - b).toString(16);
//   // pad each with zeros and return
//   return "#" + this.padZero(r1) + this.padZero(g1) + this.padZero(b1);
// }

//  padZero(str: any, len?:any) {
//     len = len || 2;
//     var zeros = new Array(len).join('0');
//     return (zeros + str).slice(-len);
// }
// }

// Replacing the org chart plugin with canvas 
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orgchart',
  templateUrl: './orgchart.component.html',
  styleUrls: ['./orgchart.component.scss']
})
export class OrgchartComponent implements OnInit {
  constructor() { 
  }

  ngOnInit(): void {
  }
}