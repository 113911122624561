import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';
import { TechstrategyService } from 'src/app/providers/input/techstrategy.service';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-itswotp',
  templateUrl: './itswotp.component.html',
  styleUrls: ['./itswotp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItswotpComponent implements OnInit {

  screenName: string = "itswotanalysis";
  swotana:any
  swotStatus: any;
  swotStatusIcon: any;
  techthemes:any;
  ourResponse:any;
  status: any = ['No Status Available', 'Not Required To Address', 'Not Addressed', 'Partially Addressed', 'Fully Addressed'];
  StreStatus = ['No Status Available', 'Not Required to Leverage', ' Not Leveraged', ' Partially Leveraged', ' Fully Leveraged'];
  OPPStatus = ['No Status Available', 'Not Required to Explore', 'Not Explored', ' Partially Explored', ' Fully Explored']
  statusIcon: any = ['../../../../../../assets/images/uploads/question-symbol.svg',
    '../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg',
    '../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg',
    '../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg',
    '../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg'];
    selectedObjectives:any;
    selectedProjects:any;
    stren: any;
    opp: any;
    weak: any;
    thre: any;

  constructor(
    private itdiagnostic:itDiagnosticsService, private techStrategyService: TechstrategyService,  private sanitized: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getTechthemes();
    this.getitswot();
   
  }
  getTechthemes() {
    this.techStrategyService.getTechnologyThemes().subscribe((resp: any) => {
      this.techthemes = resp;
      
      
    })
  }
  selectedstr:any;
  getitswot(){

    this.techStrategyService.getTechnologyThemes().subscribe((resp: any) => {
      this.techthemes = resp;
    })
    this.itdiagnostic.getSwotAnalysis(this.screenName).subscribe(rem =>{
    this.swotana = rem;
    

    this.swotana.strengths.forEach((item: any) => {
      item.strengths=''
      const statusStrtext = this.StreStatus[item.status] || 'No Status Available';
      const statusIcon = this.statusIcon[item.status] || '../../../../../../assets/images/uploads/question-symbol.svg';
      item.strengths = this.sanitized.bypassSecurityTrustHtml(item.strengths + "<img class='me-2' src=" + statusIcon + "><span>" + statusStrtext + "</span>");
      let strfoundtheme:any=[]
      this.techthemes?.forEach((theme: any) => {
        theme?.themes.forEach((Th: any) => {
          item.objectives.forEach((ob:any)=>{
         if( ob == Th._id){
           strfoundtheme.push(Th)
           item.obj=strfoundtheme
         }
         

          
          })
       
       
        })
      })
      

      console.log( JSON.stringify(this.swotana.strengths) + "obj")
    });


    this.swotana.oppurtunities.forEach((item: any) => {
      item.oppurtunitys=''
      const Statusopptext = this.OPPStatus[item.status] || 'No Status Available';
      const statusIcon = this.statusIcon[item.status] || '../../../../../../assets/images/uploads/question-symbol.svg';
      item.oppurtunitys = this.sanitized.bypassSecurityTrustHtml(item.oppurtunitys + "<img class='me-2' src=" + statusIcon + "><span>" + Statusopptext +"</span>" );
      let oppfoundtheme:any=[]
      this.techthemes?.forEach((theme: any) => {
        theme?.themes.forEach((Th: any) => {
          item.objectives.forEach((ob:any)=>{
         if( ob == Th._id){
           oppfoundtheme.push(Th)
           item.obj=oppfoundtheme
         }
         

          
          })
       
       
        })
      })
    });


    this.swotana.weaknesses.forEach((item: any) => {
      item.weaknesss=''
      const statusText = this.status[item.status] || 'No Status Available';
      const statusIcon = this.statusIcon[item.status] || '../../../../../../assets/images/uploads/question-symbol.svg';
      item.weaknesss = this.sanitized.bypassSecurityTrustHtml(item.weaknesss + "<img class='me-2' src=" + statusIcon + "><span>" + statusText +"</span>" );
      let weakfoundtheme:any=[]
      this.techthemes?.forEach((theme: any) => {
        theme?.themes.forEach((Th: any) => {
          item.objectives.forEach((ob:any)=>{
         if( ob == Th._id){
           weakfoundtheme.push(Th)
           item.obj=weakfoundtheme
         }
         

          
          })
       
       
        })
      })
    });


    this.swotana.threats.forEach((item: any) => {
      item.threatss=''
      const statusText = this.status[item.status] || 'No Status Available';
      const statusIcon = this.statusIcon[item.status] || '../../../../../../assets/images/uploads/question-symbol.svg';
      item.threatss = this.sanitized.bypassSecurityTrustHtml(item.threatss + "<img class='me-2' src=" + statusIcon + "><span>" + statusText +"</span>" );
      let threatfoundtheme:any=[]
      this.techthemes?.forEach((theme: any) => {
        theme?.themes.forEach((Th: any) => {
          item.objectives.forEach((ob:any)=>{
         if( ob == Th._id){
           threatfoundtheme.push(Th)
           item.obj=threatfoundtheme
         }
         

          
          })
       
       
        })
      })
    });

    this.status.push({

      strengthstatus: this.stren,
      opportstatus: this.opp,
      weakstatus: this.weak,
      threatstatus: this.thre

    })
    console.log(this.status)
  
    })}

    
  
}
