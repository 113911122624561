import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ButtonComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements ControlValueAccessor, OnInit {
  @Input()
  _label: string;
  constructor() { }

  ngOnInit(): void {
  }

  public get label() : string {
    return this._label
  }
  
  public set label(v : string) {
    this._label = v;
    console.log(this._label);
  }
  
  registerOnChange() { }

  registerOnTouched(){ }

  writeValue(value: any) {
    this.label = value;
  } 

}
