import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modaldialog',
  templateUrl: './modaldialog.component.html',
  styleUrls: ['./modaldialog.component.scss']
})
export class ModaldialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
