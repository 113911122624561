import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag, CdkDropList, } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import Utils from 'src/app/utils/utils';
import { CommonService } from 'src/app/helpers/common.service';


@Component({
  selector: 'app-narrative-popup',
  templateUrl: './narrative-popup.component.html',
  styleUrls: ['./narrative-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NarrativePopupComponent implements OnInit {
  jsonData: any;
  private _users: any;

  public get users(): any {
    return this._users;
  }
  public set users(value: any) {
    this._users = value;
  }

  constructor(
    public dashboardService: DashboardService,
    public commonService: CommonService,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private msgservices: MessageService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) { }
  public companiesDetails: any = [];
  Narratives: any[] = [];
  droppedItems: any[] = [];
  todoList: any;
  dragText: boolean = false;
  accountDetails: any;
  screensSelected: any[] = [];
  activeCompanies: any[] = [];
  activeCompany: any;
  done: string[] = [];
  selectedUsers: any[] = [];
  narrativeName: string;
  isCardClicked: boolean = true;
  userId: any;
  userName: any;
  accId: any;
  imgUrl: any;
  checkedScreens: any[] = [];
  isAdd: any = false;
  narratives: any = {
    narName: '',
    selectedUsers: '',
    pic: '',
    actcomany: '',
    screens: '',
    _id: undefined
  };
  stratId: any;
  narrativeid: any;
  headerText: string = 'New Narrative';
  iconUrl: any = '';
  ngOnInit(): void {
    this.init();
    this.httpClient.get('../../../../../../assets/Screens.json').subscribe((data) => {
      this.jsonData = data;
      this.jsonData.map((m: any) => {
        m.children.map((c: any, index: number) => {
          if (!c.isNarrativeScreen) {
            m.children.splice(index, 1);
          }
        });
      });
      this.uncheckAllScreens();
    });
    this.Narratives.forEach((res) => {
      this.narrativeid = res._id
    })
    if (this.config?.data?.isEdit) {
      this.headerText = "Edit Narrative";
      this.narrativeid = this.config.data.narrativeId;
      this.narratives.selectedUsers = this.config?.data?.narrat?.users;
      this.narratives.actcomany = this.config?.data?.narrat?.list[0]?.company;
      // this.screensSelected = this.config?.data?.narrat?.list[0]?.screen_name.split(',').map((name: string) => ({ name }));
      this.config?.data?.narrat?.list.forEach((lst: any) => {
        this.screensSelected.push({ "screenName": lst.screen_name, "permission": lst.permission, "name": lst.permission, "companyName": lst.company?.name, "company": lst.company });
      });
      this.narrativeName = this.config?.data?.narrat?.name;
      this.activeCompany = this.config?.data?.narrat?.list[0]?.company;
      if (this.config?.data?.iconUrl) {
        this.iconUrl = this.config?.data?.iconUrl;
      }
      else {
        this.iconUrl = this.config?.data?.narrat?.icon;
      }
      this.selectedUsers = this.config?.data?.narrat?.users;
    }
    else {
      this.iconUrl = this.config?.data?.iconUrl;
      this.screensSelected = this.config?.data?.screensSelected;
      this.narrativeName = this.config?.data?.narrativeName;
      this.activeCompany = this.config?.data?.activeCompany;
      this.selectedUsers = this.config?.data?.selectedUsers;
      // this.narratives.selectedUsers=this.config?.data?.selectedUsers;
    }
  }
  uncheckAllScreens() {
    this.jsonData.map((s: any) => {
      s.children.map((c: any) => {
        c['checked'] = false;
      });
    });
  }
  init() {
    this.dashboardService.getCompaniesDetails()
      .subscribe((respData) => {
        this.companiesDetails = respData
        this.companiesDetails.forEach((comp: any) => {
          const id = comp.company
          this.stratId = id.strat;
        })
      });
    this.getUsers();
    this.getNarratives();
  }


  saveName() {
    const narrative = this.narrativeName;
  }

  getUsers() {
    this.dashboardService.getUsersDetails()
      .subscribe({
        next: (v) => {
          if (this.config?.data?.selectedUsers) {
            this.narratives.selectedUsers = this.config?.data?.selectedUsers;
          }
          this.users = this.processUsersMetricData(v);

        },
        error: (e) => { }
      });
  }

  processUsersMetricData(userDetails: any) {
    let processedUsers: any = [];
    if (userDetails.users) {
      userDetails.users.forEach((usr: any) => {
        usr['accName'] = userDetails.acc.name;
        usr['accId'] = userDetails.acc._id;
        if(usr.avatar){
          this.dashboardService.getSelectedImage(usr.avatar)
          .subscribe(url => usr.avatar = url)
        }
      });
      processedUsers = userDetails.users;
    }
    return processedUsers;
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.screensSelected, event.previousIndex, event.currentIndex);
    return;
    // Below function isn't working; can be removed
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const draggedItem = {
        name: event.previousContainer.data[event.previousIndex].name,
        screenName: event.previousContainer.data[event.previousIndex].screenName
      };
      this.done.push(draggedItem.name);
      this.dragText = true;
      if (!this.screensSelected) {
        this.screensSelected = [{}];
      }
      this.screensSelected.push({ "screenName": draggedItem.screenName, "name": draggedItem.name, "companyName": this.activeCompany?.company?.name, "company": this.activeCompany.company });
    }
  }

  setActiveCompany(company: any) {
    const companyIndex = this.activeCompanies.findIndex(c => c.company._id === company.company._id);
    this.activeCompany = company;
    this.uncheckAllScreens();
    //  if (companyIndex !== -1) {

    //     this.activeCompanies.splice(companyIndex, 1);
    //     } else {

    //     this.activeCompanies.push(company);
    //     }
    //     this.activeCompanies.forEach((compan:any)=>{
    //      const id= compan.company
    //      this.stratId = id.strat;
    //  })


  }
  isActiveCompany(company: any): boolean {
    return this.activeCompanies.some(c => c.company._id === company.company._id);
  }

  selectAllChecked = false;
  deleteItem(item: string) {
    const index = this.screensSelected.findIndex(
      (selectedItem) => selectedItem.name === item
    );
    if (index !== -1) {
      this.screensSelected.splice(index, 1);
    }

    // Uncheck the corresponding checkbox
    const checkbox = document.getElementById('item' + index);
    if (checkbox) {
      (checkbox as HTMLInputElement).checked = false;
    }
  }


  toggleSelectAll() {
    for (const section of this.jsonData) {
      for (const item of section.children) {
        item.checked = this.selectAllChecked;
        this.updateSelectedScreens(item);
      }
    }
  }


  SaveNarrative(isPublish?: any) {
    const selectedScreens = this.screensSelected.map(screen => screen.screenName);
    const scrNames = this.screensSelected.map(screen => screen.name);

    const list = this.screensSelected.map((sc: any) => ({
      strat: sc.company?.strat,
      company: sc.company._id,
      permission: sc.permission,
      screen_name: sc.screenName,
    }));

    const permissions = this.screensSelected.map((sc: any) => ({
      strat: sc.company?.strat,
      company: sc.company._id,
      permission: sc.permission,
    }));

    const payLoad = {
      publish: isPublish || false,
      draft: isPublish ? false : true,
      users: this.selectedUsers,
      name: this.narrativeName,
      icon: this.config?.data?.saveNarrativeIconUrl,
      acc: this.accId,
      creator: this.userId,
      list: list,
      permissions: permissions,
    };

    if (this.narrativeid) {
      this.dashboardService.UpadteNarrative(payLoad, this.narrativeid)
      .subscribe({
        next: (value) => {
          this.ref.close({ isSaved: true });
          this.msgservices.add({
            severity: 'success',
            summary: 'Narrative',
            detail: 'Updated Successfully'
          });
        },
        error: (e) => {
          this.msgservices.add({
            severity: 'error',
            summary: 'Failed',
            detail: e.error.message
          });
        },
        complete: () => console.log("Completed")
      });
    } else {
      this.dashboardService.createNarratives(payLoad)
      .subscribe({
        next: (value) => {
          this.ref.close({ isSaved: true });
          this.msgservices.add({
            severity: 'success',
            summary: 'Narrative',
            detail: 'Saved Successfully'
          });
        },
        error: (e) => {
          this.msgservices.add({
            severity: 'error',
            summary: 'Failed',
            detail: e.error.message
          });
        },
        complete: () => console.log("Completed")
      });
    }
  }

  getNarratives() {
    this.dashboardService.getAccountDetails$.subscribe(userDetails => {
      this.userId = userDetails.user._id;
      this.userName = userDetails.user.name;
      this.accId = userDetails.account._id;
      this.dashboardService.getNarrativesList(this.userId).subscribe(res => {
        this.Narratives = res.body;
      });
    });
  }




  onSaveclick() {
  }

  selectedUsersAll: string[] = [];
  selectAllUsersChecked = false;

  toggleUserSelection(user: any) {
    const userId = user._id;

    if (this.selectAllUsersChecked) {
      // If "Select All" is checked, add the user to the selectedUsersAll list
      if (!this.selectedUsersAll.includes(userId)) {
        this.selectedUsersAll.push(userId);
      }
    } else {
      // If "Select All" is unchecked, remove the user from the selectedUsersAll list
      const index = this.selectedUsersAll.indexOf(userId);
      if (index > -1) {
        this.selectedUsersAll.splice(index, 1);
      }
    }

    // Toggle the user's selection in the individual selections array
    const index = this.selectedUsers.indexOf(userId);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(userId);
    }

  }

  // toggleUserSelection(user: any) {
  //   const userId = user._id;


  //   if (this.config?.data?.isEdit && this.narratives.selectedUsers) {
  //     const index = this.narratives.selectedUsers.indexOf(user);

  //     if (index > -1) {
  //       this.narratives.selectedUsers.splice(index, 1);
  //     } else {
  //       this.narratives.selectedUsers.push(user);
  //     }
  //   } else {

  //     const index = this.selectedUsers.indexOf(user);

  //     if (index > -1) {
  //       this.selectedUsers.splice(index, 1); 
  //     } else {
  //       this.selectedUsers.push(user); 
  //     }
  //   }
  // }

  toggleSelectAllUsers() {
    if (this.selectAllUsersChecked) {

      this.selectedUsersAll = this.users.map((user: any) => user._id);
      this.selectedUsers = this.users.map((user: any) => user._id);
    } else {

      this.selectedUsersAll = [];
      this.selectedUsers = [];
    }

  }



  toggleScreens(item: any) {
    item.checked = !item.checked;
    const index = this.screensSelected.findIndex(selectedItem => selectedItem.name === item.name && selectedItem?.companyName === this.activeCompany?.company.name);

    if (index > -1) {
      this.screensSelected.splice(index, 1);
    } else {
      this.screensSelected.push({ screenName: item.name, name: item.name, "companyName": this.activeCompany?.company?.name, "company": this.activeCompany.company, permission: item.screenName });
    }

    this.updateSelectedScreens(item);
  }


  closepopup() {
    this.ref.close({ isSaved: false, saveObj: { screensSelected: this.screensSelected, narrativeName: this.narrativeName, selectedUsers: this.selectedUsers, activeCompany: this.activeCompany } });
    this.screensSelected = [];
    this.narrativeName = '';
    this.selectedUsers = [];
    this.activeCompany = null;
    this.iconUrl = null;
  }

  cancelpopup() {
    this.ref.close({ isSaved: false, saveObj: null, isCancel: true });
    this.screensSelected = [];
    this.narrativeName = '';
    this.selectedUsers = [];
    this.activeCompany = null;
    this.iconUrl = null;
    this.narratives = {
      narName: '',
      selectedUsers: '',
      pic: '',
      actcomany: '',
      screens: '',
      _id: undefined
    }
  }
  PublishNarrative() {
    this.SaveNarrative(true);
  }
  updateSelectedScreens(item: any) {
    const index = this.screensSelected.findIndex(
      (selectedItem) =>
        selectedItem.name === item.name &&
        selectedItem?.companyName === this.activeCompany?.company.name
    );

    if (item.checked) {
      if (index === -1) {
        this.screensSelected.push({
          screenName: item.name,
          name: item.name,
          companyName: this.activeCompany?.company?.name,
          company: this.activeCompany.company,
        });
      }
    } else {
      if (index !== -1) {
        this.screensSelected.splice(index, 1);
      }
    }
  }

  OnNarrativeCardClick(narrativeId: any) {
    if (this.isCardClicked) {
      const routeParts: any = location.pathname.split('/');
      const localModuleId = routeParts[routeParts.length - 2];
      const presentationRoutes = `presentation/${this.stratId}/?narrativeId=${narrativeId}`;
      // presentationRoutes.push('presentation');
      // presentationRoutes.push(narrativeId);
      //'presentation/64a2b142c29e4b1f539268aa/itdiagnostic/execsummary'
      const myWindow = window.open(presentationRoutes, narrativeId, 'fullscreen=yes;toolbar=yes,scrollbars=yes,resizable=yes,left=0,top=0,width=' + screen.availWidth + ',height=' + screen.availHeight + '"');
      myWindow?.moveTo(0, 0);
    }
  }


}
