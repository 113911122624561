import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Appconfig } from 'src/app/configurations/appconfig';
import { CoreService } from '../core.service';
import { map} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanvasService extends CoreService{
  private _isCanvasActive: boolean = false;

  public get isCanvasActive(): boolean {
    return this._isCanvasActive;
  }
  public set isCanvasActive(value: boolean) {
    this._isCanvasActive = value;
  }
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getCanvasData(type: any, stratId: any) {
    return this.get(
      Appconfig.APIENDPOINT.CANVAS.CORE +
        stratId +
        Appconfig.APIENDPOINT.CANVAS.GET + type
    ).pipe(
      map((respData) => {        
        return respData.body;
      })
    );
  }

  saveCanvasData(canvas: any, type: any, stratId: any) {
    let postObj: any = {
      type: type,
      canvas: canvas
    }
    return this.post(
      Appconfig.APIENDPOINT.CANVAS.CORE +
        stratId +
        Appconfig.APIENDPOINT.CANVAS.CREATE,
        postObj
    ).pipe(
      map((respData) => {        
        return respData.body;
      })
    );
  }

}
