import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
import { AppService } from 'src/app/providers/appservice/app.service';
import Utils from 'src/app/utils/utils';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';
import { Appconfig } from 'src/app/configurations/appconfig';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { CommonService } from 'src/app/helpers/common.service';

@Component({
  selector: 'applicationdata-popup',
  templateUrl: './applicationdata-popup.component.html',
  styleUrls: ['./applicationdata-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationdataPopupComponent implements OnInit {
  appData:any;
  appPic: any;
  company:any;
  screen: string;
  selectedObjective: any = {};

  constructor(
    private config: DynamicDialogConfig,
    private appService: AppService,
    public manageDataService: ManagedataService, 
    private itDiagService: itDiagnosticsService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  this.appData = this.config?.data?.appData;
  this.screen = this.config?.data?.screen;
  this.selectedObjective = this.config?.data?.selectedObjective || {}
  this.dashboardService.getSelectedImage(this.appData.pic)
  .subscribe(signedUrl => {
    this.appPic = signedUrl;
  })
    this.commonService.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => {
      this.company = x;
    })
  }

  
  
    

    //   getOwnerData() {
    //    this.manageDataService
    //     .getHRDataForProject(this.appService.currentActivatedFeature.strategyId)
    //     .subscribe(res => {
    //          this.ownerData = res;
    //          const ownerObj = this.ownerData.find(x => x._id ===this.businessObjectives.sponsor._id);
    //         this.dashboardService.getSelectedImage(ownerObj?.pic)
    //         .subscribe(signedUrl => this.businessObjectives['pics'] = signedUrl);
    //     });
    // }
    // getSponsor(id: any) {
    // const sponsor = this.ownerData.find(x => x._id === id);
    // return sponsor ? { empName: sponsor.empName, pic: sponsor.pic } : { empName: '', pic: '' };
    // }

    Trimhtmltags(txt: any) {
      return Utils.Trimhtmltags(txt);
    }

    getFATAColor(val: any) {
      let colorObj: any;
      if (val > 4) {
        colorObj = Appconfig.CriticalColorIndicators['green'];
      }
      else if (val > 2) {
        colorObj = Appconfig.CriticalColorIndicators['amber'];
      }
      else if (val > 0) {
        colorObj = Appconfig.CriticalColorIndicators['red'];
      }
      else
        colorObj = Appconfig.CriticalColorIndicators['grey'];
      return colorObj;
    }

    backToObjective(){
      this.ref.close({});
    }
}
