import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/helpers/common.service';
import { AppService } from 'src/app/providers/appservice/app.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { environment } from 'src/environments/environment';
import { createApi } from 'unsplash-js';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { debounce } from 'lodash';
import { LoadingService } from 'src/app/providers/loading/loading.service';
import { SharedService } from 'src/app/modules/home/data.service';

@Component({
  selector: 'app-openimageuploadpopup',
  templateUrl: './openimageuploadpopup.component.html',
  styleUrls: ['./openimageuploadpopup.component.scss']
})
export class openimageuploadpopupComponent implements OnInit {
  company: any;
  gallery: any[] = [];
  line_icons: any[] = [];
  line_icons_categories: any[] = [];
  line_icons_selected_cat: any[] = [];
  line_icons_temp_selected_cat: any[] = [];
  filteredLineIconCategories: any[] = [];
  searchText: string = '';
  rich_icons: any[] = [];
  uploaded: any[] = [];
  unsplashImages: any[] = [];
  unsplashPageNum: number = 0;
  iframeLoaded: boolean = false;
  currentAccountId: any;
  private searchUnsplash = new Subject<string>();
  lineIconsPageNum: number = 0;
  private searchLineIcons = new Subject<string>();
  activeItem: string = "upload";
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();
  // @Input() callback: (url: string, inputObj: any, imageType: string, callbackData?: any) => void;
  @Input() inputObj: any;
  @Input() buttonsRef: any;
  @Input() activeUserIndex: any;
  @Input() activeUserAvatar: any;
  @ViewChild('closeModal') closeModal: any;
  @ViewChild('richIconsIFrame') richIconsIFrame: any;
  debouncedUnsplashFun: any;
  debouncedLineIconsFun: any;
  searchResponseText: string = 'Please select a category or use search to get the icons'
  constructor(private commonService: CommonService, private dashboardService: DashboardService,
    private appService: AppService, private route: ActivatedRoute, private loaderService: LoadingService, private sharedService: SharedService
  ) {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
    // this.searchUnsplash.pipe(debounceTime(100)).subscribe((searchTerm: string) => {
    //   this.unsplashPageNum = 0;
    //   this.getUnsplash(searchTerm);
    // });      
    // this.searchLineIcons.pipe(debounceTime(100)).subscribe((searchTerm: string) => {
    //   this.lineIconsPageNum = 0;
    //   if(searchTerm){
    //     this.getLineIcons(searchTerm);
    //   }
    // });      
    this.debouncedUnsplashFun = debounce(this.getUnsplash, 100);
    this.debouncedLineIconsFun = debounce(this.getLineIcons, 100);
  }

  ngOnInit(): void {
    this.commonService.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => {
      this.company = x;
    })
    this.currentAccountId = this.dashboardService.accountDetails?.account?._id || this.dashboardService.currentAccId;
    this.getImageGallery();
    // this.getLineIcons('');
    this.getLineIconCategories();
    this.getUnsplash('strategy');
  }

  receiveMessage(event: MessageEvent) {
    // Handle the received message from the iframe
    if ((/^react-devtools/gi).test(event.data.source)) {
      // console.log("DEBUG: event data: ", event.data);
      // Sometimes the event.data is coming as 
      // {source: "react-devtools-content-script"}. What the hell is this? 
      return;
    }
    this.setImage(`	https://main--inspiring-bonbon-e38161.netlify.app/${event.data}`);
  }

  onIframeLoad() {
    this.iframeLoaded = true;
  }

  setActiveItem(item: string) {
    this.activeItem = item;
    this.iframeLoaded = false;
  }

  onSearchUnsplash(event: any) {
    const searchTerm = event.target.value;
    this.debouncedUnsplashFun(searchTerm);
    // this.searchUnsplash.next(searchTerm);
  }

  getUnsplash(search = 'strategy') {
    const unsplash = createApi({
      accessKey: 'qe210R3PkD-OZ4aHt5dIO1JE-HvoHySC3Z4MIoF9DFw',
    });
    // this.unsplashPageNum++;
    unsplash.search.getPhotos({
      query: search,
      // page: this.unsplashPageNum,
      page: 1,
      perPage: 20,
    })
      .then((result) => {
        if (result.errors) {
          console.error('Failed to fetch random photo', result.errors);
        } else {
          this.unsplashImages = result.response.results;
          // this.unsplashImages = this.unsplashImages.concat(result.response.results);
        }
      }).catch((error) => {
        console.error('Failed to fetch random photo', error);
      });
  }

  getImageGallery() {
    this.dashboardService.getAccountDetails$.subscribe(v => {
      const accId = v?.account?._id;
      if(accId){
        this.dashboardService.getAccountImages(accId)
        .subscribe(res => {
          this.gallery = res;
          this.uploaded = res.uploaded.filter((obj: any) => obj.url);
        });
      }
    });
    // if(!this.dashboardService?.currentAccId && this.dashboardService?.accountDetails?.hq_company_id == this.company?.strategyId ) {    
    //     this.dashboardService.currentAccId =this.company['acc'];
    // }
    // else {
    //   this.dashboardService.currentAccId =this.company['acc'];
    // }
  }

  getLineIconCategories() {
    this.dashboardService.getLineIconCategories().subscribe(res => {
      this.line_icons_categories = res;
      this.filteredLineIconCategories = res;
    })
  }

  onSearchLineIcons(event: any) {
    const searchTerm = event.target.value;
    // this.searchLineIcons.next(searchTerm);
    this.debouncedLineIconsFun(searchTerm);
    this.line_icons_selected_cat = [];
    this.line_icons_temp_selected_cat = [];
  }

  getLineIcons(search: string = '') {
    this.line_icons_selected_cat = !search ? this.line_icons : [];
    this.dashboardService.getLineIcons(search, this.line_icons_selected_cat).subscribe(res => {
      this.line_icons = res
      if (this.line_icons?.length == 0) {
        this.searchResponseText = 'No Icons found for the specific search';
      }
    })
  }

  applyLineIconCategory() {
    this.line_icons_selected_cat = this.line_icons_temp_selected_cat
  }

  setLineIconCategory(category: string) {
    this.line_icons_temp_selected_cat.push(category);
  }

  resetLineIconCategories() {
    this.line_icons_temp_selected_cat = [];
  }

  removeLineIconCategory(event: any, category: string) {
    event.stopPropagation();
    this.line_icons_temp_selected_cat = [];
    // this.line_icons_temp_selected_cat.splice(this.line_icons_temp_selected_cat.indexOf(category), 1);
    // this.cdr.detectChanges();
    // console.log(this.line_icons_temp_selected_cat, this.line_icons_temp_selected_cat.indexOf(category));
  }

  getLineIconURL(file: string, folder: string) {
    return `${environment.API_URL}line-icons/${folder}/${file}`;
  }

  OnImageSelected(event: any) {
    this.loaderService.apiLoader = true;
    if (event.target.files.length > 0) {
      let data = {
        pic: event.target.files[0],
        acc: this.currentAccountId,
        // strat: this.appService.currentActivatedFeature.strategyId
      }
      let formData: FormData = new FormData();
      formData.set('pic', event.target.files[0]);
      // formData.append('pic', event.target.files[0], event.target.files[0].name);
      formData.append('acc', this.currentAccountId);
      // formData.append('strat', this.appService.currentActivatedFeature.strategyId);
      this.dashboardService.UploadImage(formData)
      .subscribe({
        next: (res) => {
          this.uploaded.push(res);
          event.target.value = '';
          this.sharedService.alertNotification('success', 'Success', 'Image was uploaded');
        },
        error: (e) => {
          this.sharedService.alertNotification('error', 'Error', e.error.message);
        },
        complete: () => { this.loaderService.apiLoader = false; }
      })
    }
  }

  setImage(url: string) {
    this.dashboardService.getSelectedImage(url)
    .subscribe(signedUrl => {
      this.callback.emit({ url, imageType: this.activeItem, signedUrl });
      this.closeModal?.nativeElement?.click();
    })
  }

  setLineIcon(file: string, folder: string) {
    this.setImage(this.getLineIconURL(file, folder))
  }

  closeModalFun() {
    this.line_icons = [];
    this.line_icons_temp_selected_cat = [];
    this.callback.emit({ closeModal: true })
  }

  deleteImage(event: any, file: any) {
    event.stopPropagation();
    const { url, acc } = file;
    this.dashboardService.deleteImage({ acc, url }).subscribe(res => {
      this.uploaded = res.uploaded.filter((obj: any) => obj.url);
    });
  }

  getImageUrl(file: any) {
    if (file.url)
      return environment.S3_API_URL + this.currentAccountId + '/image-gallery/' + file.url;
    else
      return '';
  }


  updateLineIconCategoryAndFetchIcons(category: string, search: string = ''): void {
    const categoryIndex = this.line_icons_temp_selected_cat.indexOf(category);
    if (categoryIndex === -1) {
      this.line_icons_temp_selected_cat.push(category);
    } else {
      this.line_icons_temp_selected_cat.splice(categoryIndex, 1);
    }

    this.line_icons_selected_cat = !search ? this.line_icons_temp_selected_cat : [];
    if (search || this.line_icons_selected_cat.length !== 0) {
      this.dashboardService
        .getLineIcons(search, this.line_icons_selected_cat)
        .subscribe(res => {
          this.line_icons = res;
        });
    } else {
      this.line_icons = [];
      this.searchResponseText = 'Please select a category or use search to get the icons';
    }
    if (this.line_icons_temp_selected_cat.length > 0) {
      this.line_icons_selected_cat = this.line_icons_temp_selected_cat.slice();

      this.dashboardService.getLineIcons(search, this.line_icons_selected_cat).subscribe(res => {
        this.line_icons = res;
      });
    }
    else {
      this.line_icons = [];
      this.searchResponseText = 'Please select a category or use search to get the icons';
    }

  }

  filterLineIconCategories() {
    if (this.searchText.trim() === '') {
      this.filteredLineIconCategories = this.line_icons_categories;
    } else {
      this.filteredLineIconCategories = this.line_icons_categories.filter(
        (cat) => cat.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

}


