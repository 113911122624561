import { Injectable } from '@angular/core';
import { IUser } from 'src/app/models/user';
import { map } from 'rxjs';
import { CoreService } from '../core.service';
import { Appconfig } from 'src/app/configurations/appconfig';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CoreService{
  private _userDetails: IUser;
  private _currentSelectedBusinessUnit: any = {};

  public get currentSelectedBusinessUnit(): any {
    return this._currentSelectedBusinessUnit;
  }
  public set currentSelectedBusinessUnit(value: any) {
    this._currentSelectedBusinessUnit = value;
  }

  public get userDetails(): any {
    return this._userDetails;
  }
  public set userDetails(value: IUser) {
    this._userDetails = value;
  }

  updateUser(payload: any) {
    return this.post(
      Appconfig.APIENDPOINT.HOME_MODULES.ACCOUNT_UPDATE,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  updateAccountUser(payload: any, userId: string) {
    return this.post(
      `${Appconfig.APIENDPOINT.HOME_MODULES.USERS}/${userId}`,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  getAccountUser(userId: string) {
    return this.get(
      `${Appconfig.APIENDPOINT.HOME_MODULES.USERS}/${userId}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  setAsAdmin(userId: any){
    return this.get(
      `${Appconfig.APIENDPOINT.SETTINGS.MAKE_ADMIN}/${userId}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  removeAsAdmin(userId: any){
    return this.get(
      `${Appconfig.APIENDPOINT.SETTINGS.REMOVE_ADMIN}/${userId}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  createUser(payload: any) {
    return this.post(
      Appconfig.APIENDPOINT.HOME_MODULES.ME_USERS,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  resetPassword(userID: string) {
    return this.post(
      `${Appconfig.APIENDPOINT.HOME_MODULES.RESET_PASSWORD}/${userID}/resetpassword`,
      {}
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  reset2FA(payload: {
    acc: string,
    userId: string
  }) {
    return this.post(
      Appconfig.APIENDPOINT.AUTH_MODULE.RESET_2FA,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  suspendUser(userID: string) {
    return this.post(
      `1/me/account/users/${userID}/disable`,
      {}
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  reinstateUser(userID: string) {
    return this.post(
      `1/me/account/users/${userID}/enable`,
      {}
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  deleteUser(userID: string) {
    return this.get(
      `1/users/delete/${userID}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getStrategies(userID: string){
    return this.get(
      `1/me/itstrategies/${userID}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  getChatGPTHistory(stratID: string, category: string){
    return this.get(
      `1/users/v3/${stratID}/chatgpt/history/${category}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  saveChatGPTHistory(payload: any, stratID: string) {
    return this.post(
      `1/users/v3/${stratID}/chatgpt/history`,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  setUserPermissions(payload: any, userId: string) {
    return this.post(
      `1/itstrategies/contributors/add/${userId}`,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  bannerUpload(payload:any,accId:any) {
    return this.post(`1/me/account/${accId}/banner-upload`,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
  
  updateAccount(payload:any) {
    return this.put(`1/me/account`,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }
}
