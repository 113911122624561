import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ITechAlignment } from 'src/app/models/input/techstrategy/themeAlignemnt';
import { IProgram, ISwotBS, ITechThemes } from 'src/app/models/shared/loopBack';
import { AppService } from 'src/app/providers/appservice/app.service';
import { BusinessStrategyService } from 'src/app/providers/input/business-strategy.service';
import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
import { TechstrategyService } from 'src/app/providers/input/techstrategy.service';
import Utils from 'src/app/utils/utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/app/helpers/common.service';
import { PortfolioThemePopupComponent } from '../portfolio-theme-popup/portfolio-theme-popup.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ObjsharedPopupComponent } from '../objshared-popup/objshared-popup.component';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { SharedService } from 'src/app/modules/home/data.service';

@Component({
  selector: 'app-theme-align',
  templateUrl:'./theme-align.component.html',
  styleUrls: ['./theme-align.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeAlignComponent implements OnInit {

  techThemes: IProgram[];
  criticalPrograms: IProgram[];
  businessPrograms: IProgram[];
  businessObjectives: IProgram[];
  oprograms: IProgram[] = [];
  osprograms: any[] = [];
  swotBS: ISwotBS;
  Projects: any[] = [];
  selectedProjects: any[] = [];
  clonedSelectedProjects:any[]=[];
  rowValue: string = "ALL";
  statusData: any = [{ status: 0, statusName: 'Non-Essential' },
  { status: 1, statusName: 'Primary Contributor' },
  { status: 2, statusName: 'Secondary Contributor' }];
filterText:string = 'PROJECTS';
  columnSelector: any = ['Business Projects', 'Business Objectives', 'Technology Themes'];
  selectedColumn: string = "BUSINESS PROJECTS";
  selectedObjective: any;
  allapp: any[];
  allgoal: any[];
  allsup: any[];
  objectives: any[] = [];
  projectData: any;
  ownerData: any[] = [];
  company: any;
  constructor(private techStrategyService: TechstrategyService, private busStartegyService: BusinessStrategyService,
    public manageDataService: ManagedataService, public appService: AppService, private sanitizer: DomSanitizer,
    private el: ElementRef, private renderer: Renderer2, 
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private dialogService: DialogService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.commonService.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => {
      this.company = x;
    });
    // this.sharedService.refreshScreen$.subscribe(response => {
    //   console.log("DEBUG: theme alignment in shared comp", response)
    //   if (response === 'themealignment' || response === 'techtheme') {
    //     this.getAllProjectData();
    //     this.themeday();
    //   }
    // })
    this.getAllProjectData();
    this.themeday();
  }
  thech:any;

  themeday(){
    this.techStrategyService.getTechnologyThemes().subscribe(response => {
      this.thech=response
    })
  }

  getTechThemes() {

      this.techThemes = [];
      if (this.thech && this.thech.length > 0) {
        this.thech.forEach((x: any) => {
          if (x.themes)
            x.themes.forEach((y: any) => {
              let pr = y.progs && y.progs.length > 0 ? y.progs.map((x: any) => x._id) : [];
              this.techThemes.push({ _id: y._id, prgName: y.name, projects_depend: pr, checked: false, filtered: false })
            });
          this.criticalPrograms = this.techThemes;
          this.oprograms = JSON.parse(JSON.stringify(this.criticalPrograms));
        });

      }

  }

filteredobj:any;
mainObjdata:any;
  bussObj(){
    this.busStartegyService.getBusinessObjectives().subscribe(response=>{
     this.mainObjdata= response;
     this.mainObjdata.categories.forEach((x:any)=>{
      const obj =x.objectives;
      obj.map((ob: any) => {
        if(ob.sponsor){
          this.dashboardService.getSelectedImage(ob.sponsor.pic)
          .subscribe((signedUrl: any) => ob.sponsor.pic = signedUrl)
        }
        let prg = ob.progs.map((p: any) => { return p._id });
        prg.map((p: any) => {
          let o = this.Projects.findIndex((pf: any) => pf._id == p);
          if (!this.Projects[o].objectives) {
            this.Projects[o].objectives = [];
          }
          this.Projects[o].objectives.push(ob);
        })
      });
     })

    })
  }
  masterObjectiveData:any
  getBusinessObjectives() {
  
      this.swotBS = this.mainObjdata;
      if (this.swotBS && this.swotBS.categories && this.swotBS.categories.length > 0) {

        this.businessObjectives = [];
        this.swotBS.categories.forEach((x:any) => {
          this.masterObjectiveData=x.objectives;
          this.masterObjectiveData = this.masterObjectiveData?.filter((x: any) => x?.title);
          this.masterObjectiveData=this.getObjectiveProperties(this.masterObjectiveData);
          x.objectives.forEach((y:any) => {
            // y['OwnerName'] = y.sponsor?.empName || '';
            // y['pics'] = y.sponsor?.pic;
        
            this.objectives.push(y);
            let pr = y.progs && y.progs.length > 0 ? y.progs.map((x:any) => x._id) : [];
            this.businessObjectives.push({ _id: y._id, prgName: y.title, projects_depend: pr, checked: false, filtered: false })
          
          });
        });
        this.criticalPrograms = this.businessObjectives;
        this.oprograms = JSON.parse(JSON.stringify(this.criticalPrograms));
      }
      else
        this.businessObjectives = [];
   
  }

  getObjectiveProperties(masterObjectiveData: any ){
    masterObjectiveData.map((obj:any) => {
        let realisationDts:any[]=[];
        obj.progs.forEach((prj:any) => {
          //Risk icon logic
          if(prj.itbApproved == 'rejected'){
            obj['isRisk']=true;
          }
          //Realisation date logic
          prj.benefits_realisations.forEach((benefit:any) => {
            realisationDts.push(benefit.benefit_delivery_date);
            console.log(JSON.stringify (realisationDts) + 'date')
          });
         
        });
        if(realisationDts && realisationDts.length>0) {
          const latestDate: Date | null = this.getLatestDate(realisationDts);
          obj['realisationDate']=latestDate;
        }
  
      });
      return masterObjectiveData;
    }

    getLatestDate(dates: any): Date | null {
      if (dates.length === 0) {
        return null; // Return null if the array is empty
      }
      let maxDate: Date = dates[0];
    
      for (let i = 1; i < dates.length; i++) {
        if (dates[i] > maxDate) {
          maxDate = dates[i];
        }
      }
    
      return maxDate;
      
    }
    
  onColumnSelector(col: string) {
    this.selectedColumn = col;
    if (col == "Business Objectives") {
      this.filterText = 'OBJECTIVES';
      this.getBusinessObjectives();

    }
    else if (col == "Technology Themes") {
      this.filterText = 'THEMES';
      this.getTechThemes();

    }
    else {
      this.filterText = 'PROJECTS';
      this.criticalPrograms = this.businessPrograms;
      this.oprograms = JSON.parse(JSON.stringify(this.criticalPrograms));
    }
  }

  Trimhtmltags(txt: any) {
    //this.charLength=0;
    txt = txt.replaceAll('/', '');
    txt = txt.replaceAll(' style="color: rgb(0, 0, 0);" ', '')
    txt = txt.replaceAll(/<p>|<strong >|<em>|<u>|<span style="color:rgb(0, 0, 0);">|<span>/gi, function (matched: any) {
      return '';
    });
    return txt;
  }

  getAllProjectData(isFresh?: any) {

    this.manageDataService
      .getProjectData(this.appService.currentActivatedFeature.strategyId, isFresh)
      .subscribe({
        next: (v) => {
          // this.getBusinessObjectives();
          this.bussObj();
          this.Projects = v;
          this.selectedProjects = v;
          this.clonedSelectedProjects=v;
         
      
          this.osprograms = v;
          this.businessPrograms = [];
          v.filter((x: any) => x.prgType == "business").forEach((y: any) => {
            this.businessPrograms.push({ _id: y._id, prgName: y.prgName, projects_depend: [y._id], checked: false, filtered: false })
          });
          this.selectedColumn = "Business Projects";
          this.onColumnSelector(this.selectedColumn);
         
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => console.info('complete')
      });
  }

  LoadProjects(data: any) {
    if (data == 'IT') {
      this.rowValue = "IT Project";
      this.selectedProjects = this.Projects.filter(x => x.prgType == 'it');
      this.osprograms = this.Projects.filter(x => x.prgType == 'it');
      this.clonedSelectedProjects=this.Projects.filter(x => x.prgType == "it");
    }
    else if (data == 'Business') {
      this.rowValue = "Business Project";
      this.selectedProjects = this.Projects.filter(x => x.prgType == "business");
      this.clonedSelectedProjects=this.Projects.filter(x => x.prgType == "business");

      this.osprograms = this.Projects.filter(x => x.prgType == 'it');
    } else {
      this.rowValue = "ALL";
      this.selectedProjects = this.Projects;
      this.osprograms = this.Projects;
      this.clonedSelectedProjects=this.Projects;
      // this.selectedProjects = this.Projects.filter(x => x.prgType == "business");
      // this.osprograms = this.Projects.filter(x => x.prgType == 'it');
    }
  }

  getStatusValue(tech: any, program: IProgram) {
    if (this.selectedColumn == "Business Objectives" || this.selectedColumn == "Technology Themes") {
      return program.projects_depend.findIndex((x: IProgram) => x == tech._id) == -1 ? "No Dependency" : "Dependency Exists";
    }
    else {
      return tech.projects_dependent_on?.findIndex((x: IProgram) => x._id == program._id) != -1 ||
        tech.projects_depending_on?.findIndex((x: IProgram) => x._id == program._id) != -1 ? "Dependency Exists" : "No Dependency";
    }
  }

  // filterRow(proj: any) {
  //   let index = this.selectedProjects.findIndex(x => x._id == proj._id);
  //   if (!this.selectedProjects[index].filtered)
  //     this.selectedProjects[index].filtered = true;
  //   else{
  //     this.selectedProjects[index].filtered = false;
  //   this.onColumnSelector(this.selectedColumn);
  //   }
  //   let ftproj = this.selectedProjects.filter(x => x.filtered == true);
  //   if (ftproj.length > 0) {
  //     let filrows: any[] = [];
  //     ftproj.forEach((p, i) => {
  //       if (this.selectedColumn == "Business Objectives" || this.selectedColumn == "Technology Themes") {
  //         this.criticalPrograms.filter((x: IProgram) => x.projects_depend.includes(p._id)).forEach(y => {
  //           if (!filrows.find((f:any) => f._id == y._id))
  //             filrows.push(y);
  //         })
  //       }
  //       else {
  //         let ids: any[] = [];
  //         p.projects_dependent_on.map((x: any) => ids.push(x._id));
  //         p.projects_depending_on.map((x: any) => ids.push(x._id));
  //         this.criticalPrograms.filter((x: IProgram) => ids.includes(x._id)).forEach(y => {
  //           if (!filrows.some(f => f._id == y._id))
  //             filrows.push(y);
  //         })
  //       }
  //         this.criticalPrograms = filrows;
  //     });
  //   }
  //   console.log(this.criticalPrograms);
  // }
  filterRow(proj: any) {
    let index = this.selectedProjects.findIndex(x => x._id == proj._id);
    if (!this.selectedProjects[index].filtered)
      this.selectedProjects[index].filtered = true;
    else {
      this.selectedProjects[index].filtered = false;
    }
    this.onColumnSelector(this.selectedColumn);
    let activeFilters = this.selectedProjects
      .filter(x => x.filtered)
      .map(p => p._id);
  
    if (activeFilters.length > 0) {
      let filrows: any[] = [];
      activeFilters.forEach((projectId) => {
        const project = this.selectedProjects.find(x => x._id === projectId);
        console.log("projects:", project)
        if (this.selectedColumn == "Business Objectives" || this.selectedColumn == "Technology Themes") {
          this.criticalPrograms.filter((x: IProgram) => x.projects_depend.includes(project._id)).forEach(y => {
            if (!filrows.find((f:any) => f._id == y._id))
              filrows.push(y);
          })
        }
        else {
          let ids: any[] = [];
          project.projects_dependent_on.map((x: any) => ids.push(x._id));
          project.projects_depending_on.map((x: any) => ids.push(x._id));
          this.criticalPrograms.filter((x: IProgram) => ids.includes(x._id)).forEach(y => {
            if (!filrows.some(f => f._id == y._id))
              filrows.push(y);
          })
        }
      });
      this.criticalPrograms = filrows;
    }
    console.log("critical:",this.criticalPrograms);
  }


  filterColumn(program: any) {
    if (this.rowValue != "ALL")
    console.log('');
   //   this.LoadProjects(this.rowValue == "IT Project" ? "IT" : "Business");
    else
      this.selectedProjects = this.Projects.filter(x => x._id == x._id);

    let index = this.criticalPrograms.findIndex(x => x._id == program._id);
    if (!this.criticalPrograms[index].filtered == true)
      this.criticalPrograms[index].filtered = true;
    else
      this.criticalPrograms[index].filtered = false;
    let ftproj = this.criticalPrograms.filter(x => x.filtered == true);
    if (ftproj.length > 0) {
      let filrows: any[] = [];
      ftproj.forEach((p, i) => {
        if (this.selectedColumn == "Business Objectives" || this.selectedColumn == "Technology Themes") {
          this.selectedProjects.filter((x: IProgram) => p.projects_depend.includes(x._id)).forEach(y => {
            if (!filrows.some(f => f._id == y._id))
              filrows.push(y);
          })
        }
        else {
          let filterprg: any[] = [];
          this.selectedProjects.forEach((x: any) => {
            let ids: any[] = [];
            x.projects_dependent_on.map((y: any) => ids.push(y._id));
            x.projects_depending_on.map((y: any) => ids.push(y._id));
            if (ids.includes(p._id))
              filterprg.push(x);
          });
          filterprg.forEach(y => {
            if (!filrows.some(f => f._id == y._id))
              filrows.push(y);
          })
        }
        if (i == ftproj.length - 1)
          this.selectedProjects = filrows;
      });
    }
  }

  TrimHtmlTags(txt: any) {
    return Utils.Trimhtmltags(txt);
  }

  getColWidth() {
    switch (this.selectedColumn) {
      case 'Business Projects':
        return 100;
      case 'Business Objectives':
        return 100;
      case 'Technology Themes':
        return 100;
      default:
        return 100;


    }
  }

  truncateText1(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.slice(0, maxLength)+'...';
    }
    return text;
  }

  openObjPopup(o: any) {
    this.selectedObjective = this.objectives.filter(x => x._id == o._id)[0];
    console.log('objectives data :' + this.selectedObjective)

    this.dialogService.open(ObjsharedPopupComponent, {
      header: this.truncateText1(this.Trimhtmltags(this.selectedObjective.title),140),
      width: '1405px',  
      height: '800px',  
      data: {businessObjectives: this.selectedObjective}
    });

    let appMap = new Map<any, any>(); // Using Map to store unique apps by ID
    let suppMap = new Map<any, any>(); // Using Map to store unique suppliers by ID
    let goalMap = new Map<any, any>(); // Using Map to store unique goals by ID

    // this.categories?.forEach((obj: any) => {
    this.businessObjectives.forEach((app: any) => {
      app.apps.forEach((a: any) => {
        let appdata = this.selectedObjective.apps.find((appId: any) => appId === a._id);
        if (appdata) {
          appMap.set(a._id, a); // Use app ID as the key in the Map
        }
      });

      app.suppliers.forEach((s: any) => {
        let suppdata = this.selectedObjective.suppliers.find((suppId: any) => suppId === s._id);
        if (suppdata) {
          suppMap.set(s._id, s); // Use supplier ID as the key in the Map
        }
      });

      app.goals.forEach((g: any) => {
        let goaldata = this.selectedObjective.goals.find((goalId: any) => goalId === g._id);
        if (goaldata) {
          goalMap.set(g._id, g); // Use goal ID as the key in the Map
        }
      });
    });
    // });

    // Convert Map values to Arrays for allapp, allsup, and allgoal
    this.allapp = Array.from(appMap.values());
    this.allsup = Array.from(suppMap.values());
    this.allgoal = Array.from(goalMap.values());
  }

  projectInfo(project: any) {
    //this.getBusinessObjectives();
    this.projectData = this.Projects.filter((x: any) => x._id == project._id)[0];
   this.dialogService.open(PortfolioThemePopupComponent, {
      header: 'PROJECT INFORMATION',
      width: '1405px',  // Adjust the width here (e.g., '500px', '50%')
      height: '800px',  // Adjust the height here (e.g., '300px', '50%')
      data: {projectData: this.projectData,businessObjectives: this.businessObjectives, screen: 'themes-alignment-shared'}
    });
  }

  FilterRows() {
    this.criticalPrograms = this.oprograms.filter(x => x.checked == true);
    if (this.criticalPrograms.length == 0) {
      this.criticalPrograms = JSON.parse(JSON.stringify(this.oprograms));
    }
  }

  FilterColumn() {
    this.selectedProjects = this.osprograms.filter(x => x.checked == true);
  }
  sanitizeHtml(html: string = ''): SafeHtml {
    if (html != undefined) {
      return this.sanitizer.bypassSecurityTrustScript(html);
    } else {
      return '';
    }
  }

  FilterProjects() {
    const selectedFilteredProjects=this.clonedSelectedProjects.filter(x=>x.checked);

    if(selectedFilteredProjects?.length >0) {
      this.selectedProjects=JSON.parse(JSON.stringify(selectedFilteredProjects));
    }
    else {
      this.selectedProjects=JSON.parse(JSON.stringify(this.clonedSelectedProjects));
    }
  }
}
