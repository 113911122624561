import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from 'primeng/editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { SafePipe } from './utils/safe.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SupplierheatmpComponent } from './supplierheatmp/supplierheatmp.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxOrgChartModule } from 'ngx-org-chart';
// import { PrintPreviewComponent } from './components/print-preview/print-preview.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    SupplierheatmpComponent,
    UnauthorizedComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    EditorModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    ConfirmDialogModule,
    DatePipe,
    InfiniteScrollModule,
    ProgressSpinnerModule,
    ToastModule,
    DropdownModule,
    TabViewModule,
    NgxOrgChartModule,
    HighchartsChartModule,
  ],
  providers: [ConfirmationService, DialogService, DatePipe, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
