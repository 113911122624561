import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, Subject } from 'rxjs';
import { Appconfig } from 'src/app/configurations/appconfig';
import { CoreService } from '../core.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CoreService {

  constructor(httpClient: HttpClient, private userService: UserService, private router: Router) {
    super(httpClient);
  }

  login(loginData: any) {
    const payLoad: any = {
      email: loginData.email,
      password: loginData.password
    };
    return this.post(Appconfig.APIENDPOINT.AUTH_MODULE.LOGIN, payLoad).pipe(map(
      respData => {
        if (respData.body.success) {
          this.userService.userDetails = respData.body.userDetails;
        }
        return respData.body
      }
    ));
  }

  forgotPassword(forgotData: any) {
    const payLoad: any = {
      email: forgotData.email
    };
    return this.post(Appconfig.APIENDPOINT.AUTH_MODULE.FORGOT_PASSWORD, payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  verifyToken(payload: any) {
    return this.post(Appconfig.APIENDPOINT.AUTH_MODULE.TWO_FA, payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  logout() {
    return this.get(Appconfig.APIENDPOINT.AUTH_MODULE.LOGOUT).pipe(map(
      respData => {
        console.log(respData);
      }
    ))
  }

  checkSession(isAuthPageCheck?: boolean): Observable<boolean> {
    return this.get(Appconfig.APIENDPOINT.AUTH_MODULE.SESSION).pipe(map(
      respData => {
        if (respData.body.success) {
          if (isAuthPageCheck && localStorage.getItem('twoFactorAuth')) {
            this.router.navigate(['home']);
          }
          localStorage.setItem('currentUser', JSON.stringify(respData.body.user));
          return true;
        } else {
          return false;
        }
      }
    ),
      catchError((err: any) => {
        console.log(err);
        if (location.pathname !== '/auth/login') {
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: location.pathname } });
        }
        return of(false);
      })
    );
  }

  resetUserDetails(resetID: string) {
    return this.get(`${Appconfig.APIENDPOINT.AUTH_MODULE.RESET_DETAILS}${resetID}`)
      .pipe(map(
        respData => {
          return respData.body
        }
      ))
  }

  resetPassword(payload: any) {
    return this.post(Appconfig.APIENDPOINT.AUTH_MODULE.RESET, payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  getTwoFactorSecret() {
    return this.get(Appconfig.APIENDPOINT.AUTH_MODULE.GET_TWO_FACTOR_SECRET).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  getSignedCookies() {
    return this.get(Appconfig.APIENDPOINT.AUTH_MODULE.SIGNED_COOKIES).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

}
