import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';

@Component({
  selector: 'app-needforchngp',
  templateUrl: './needforchngp.component.html',
  styleUrls: ['./needforchngp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NeedforchngpComponent implements OnInit {

  constructor(
    private itDiagService: itDiagnosticsService,
  ) { }

  ngOnInit(): void {
    this.getneedforchange()
  }
  needsections:any;
  getneedforchange(){
    this.itDiagService.getNeedForChange().subscribe(res =>{
      this.needsections = res;
    })
  }

}
