<div class="print-main-container">
<div>
    <p class="swot-names">STRENGTHS</p>
    <div *ngFor="let str of swotana.strengths">
        <p  [innerHTML]="str.strength" ></p>
        <table class="table table-responsive border" >
            <thead>
                <tr class="bordered-tm chan-2 ">
                    <th class="header-color">OUR RESPONSE</th>
                    <th class="header-color">TECHNOLOGY THEMES</th>
                    <th class="header-color">PROJECTS</th>
                    <th class="header-color">STATUS</th>
                </tr>
            </thead>
            <tbody>
              <tr class="bordered-tm chan-3 ">
                <td style="width:21%">
                    <span [innerHTML]="str.response" ></span>
                </td>
                <td >
                    <div class="">
                        <div class=" d-flex  text-dark tags-overflow">
                            <ng-container *ngFor="let stre of str.obj">
                            <span class="m-2" [innerHTML]="stre.name" ></span>
                        </ng-container>
                        </div>
                    </div>
                </td>
                <td >
                    <div class=" ">
                        <div class=" d-flex text-dark tags-overflow ">
                            <ng-container *ngFor="let proj of str.projects">
                                <span class="m-1" [innerHTML]="proj.prgName" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td style="width:20%">
                    <p  [innerHTML]="str.strengths" ></p></td>

            </tr>
            </tbody>
        </table>
    </div>
</div>

<div>
    <p class="swot-names">OPPORTUNITIES</p>
    <div *ngFor="let opp of swotana.oppurtunities">
        <p  [innerHTML]="opp.oppurtunity" ></p>
        <table class="table table-responsive border" >
            <thead>
                <tr class="bordered-tm chan-2 ">
                    <th class="header-color">OUR RESPONSE</th>
                    <th class="header-color">TECHNOLOGY THEMES</th>
                    <th class="header-color">PROJECTS</th>
                    <th class="header-color">STATUS</th>
                </tr>
            </thead>
            <tbody>
              <tr class="bordered-tm chan-3 ">
                <td style="width:20%">
                    <span [innerHTML]="opp.response" ></span>
                </td>
                <td>
                    <div class="">
                        <div class=" d-flex  text-dark tags-overflow">
                            <ng-container *ngFor="let oppe of opp.obj">
                                <span [innerHTML]="oppe.name" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="">
                        <div class=" d-flex  text-dark tags-overflow">
                            <ng-container *ngFor="let proj of opp.projects">
                                <span class="m-1" [innerHTML]="proj.prgName" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td style="width:20%">
                    <p  [innerHTML]="opp.oppurtunitys" ></p></td>

            </tr>
            </tbody>
        </table>
    </div>
</div>

<div>
    <p class="swot-names">WEAKNESSES</p>
    <div *ngFor="let weak of swotana.weaknesses">
        <p  [innerHTML]="weak.weakness" ></p>
        <table class="table table-responsive border" >
            <thead>
                <tr class="bordered-tm chan-2 ">
                    <th class="header-color">OUR RESPONSE</th>
                    <th class="header-color">TECHNOLOGY THEMES</th>
                    <th class="header-color">PROJECTS</th>
                    <th class="header-color">STATUS</th>
                </tr>
            </thead>
            <tbody>
              <tr class="bordered-tm chan-3 ">
                <td style="width:20%">
                    <span [innerHTML]="weak.response" ></span>
                </td>
                <td>
                    <div class=" ">
                        <div class=" d-flex text-dark tags-overflow">
                          
                            <ng-container *ngFor="let wee of weak.obj">
                                <span [innerHTML]="wee.name" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td>
                    <div class=" ">
                        <div class=" d-flex text-dark tags-overflow">
                            <ng-container *ngFor="let proj of weak.projects">
                                <span class="m-1" [innerHTML]="proj.prgName" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td style="width:20%">
                    <p  [innerHTML]="weak.weaknesss" ></p></td>

            </tr>
            </tbody>
        </table>
    </div>
</div>

<div>
    <p class="swot-names">THREATS</p>
    <div *ngFor="let threat of swotana.threats">
        <p  [innerHTML]="threat.threat" ></p>
        <table class="table table-responsive border" >
            <thead>
                <tr class="bordered-tm chan-2 ">
                    <th class="header-color">OUR RESPONSE</th>
                    <th class="header-color">TECHNOLOGY THEMES</th>
                    <th class="header-color">PROJECTS</th>
                    <th class="header-color">STATUS</th>
                </tr>
            </thead>
            <tbody>
              <tr class="bordered-tm chan-3 ">
                <td style="width:21%">
                    <span [innerHTML]="threat.response" ></span>
                </td>
                <td style="width:21%">
                    <div class=" ">
                        <div class=" d-flex text-dark tags-overflow">
                            <ng-container *ngFor="let thr of threat.obj">
                                <span [innerHTML]="thr.name" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td style="width:21%">
                    <div class="">
                        <div class=" d-flex text-dark tags-overflow">
                            <ng-container *ngFor="let proj of threat.projects">
                                <span class="m-1" [innerHTML]="proj.prgName" ></span>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td style="width:20%">
                    <p  [innerHTML]="threat.threatss" ></p> </td>

            </tr>
            </tbody>
        </table>
    </div>
</div>
</div>
