import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { iif } from 'rxjs';
import { IObejctive, IProject, ISwotBS, ISwotCategory, ISwotObjective, ITechThemes } from 'src/app/models/shared/loopBack';
import { ILBNeedForChange, IStrength, ISwotMatrix } from 'src/app/models/input/itdiagnostics/itswotmatrix';
import { INeedForChange } from 'src/app/models/input/itdiagnostics/needforchange';
import { BusinessStrategyService } from 'src/app/providers/input/business-strategy.service';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';
import { TechstrategyService } from 'src/app/providers/input/techstrategy.service';
import Utils from 'src/app/utils/utils';
import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/modules/home/data.service';

@Component({
  selector: 'app-loop-back',
  templateUrl: './loop-back.component.html',
  styleUrls: ['./loop-back.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoopBackComponent implements OnInit {

  @Input()
  screenName: string;

  swotCategories: any;

  swotStatus: any = [
    { code: 0, image: '../../../../../../assets/images/uploads/question-symbol.svg', title: 'No Status Available' },
    { code: 1, image: '../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg', title: 'Not Required to Address' },
    { code: 2, image: '../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg', title: 'Not Addressed' },
    { code: 3, image: '../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg', title: 'Partially Addressed' },
    { code: 4, image: '../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg', title: 'Fully Addressed' }];

  swotstreStatus: any = [
    { code: 0, image: '../../../../../../assets/images/uploads/question-symbol.svg', title: 'No Status Available' },
    { code: 1, image: '../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg', title: ' Not Required to Leverage' },
    { code: 2, image: '../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg', title: ' Not Leveraged' },
    { code: 3, image: '../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg', title: ' Partially Leveraged' },
    { code: 4, image: '../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg', title: ' Fully Leveraged' }];

  swotOPPStatus: any = [
    { code: 0, image: '../../../../../../assets/images/uploads/question-symbol.svg', title: 'No Status Available' },
    { code: 1, image: '../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg', title: ' Not Required to Explore' },
    { code: 2, image: '../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg', title: '  Not Explored' },
    { code: 3, image: '../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg', title: ' Partially Explored' },
    { code: 4, image: '../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg', title: ' Fully Explored' }];


  selectedCategordIndex: number = 0;
  swotBS: ISwotBS;
  swotCategory: ISwotCategory[];
  //cloneSwotCategory: ISwotCategory[];
  projects: IProject[];
  objectives: IObejctive[];
  needForChange: INeedForChange;
  techThemes: ITechThemes[];
  loopBackNeedForChange: ILBNeedForChange[] = [];
  selectedSwotType: string = "STRENGTHS";
  swotMatrix: ISwotMatrix = { strengths: [], oppurtunities: [], weaknesses: [], threats: [], needForChange: [], archive: false, loopbackStatusUpdated: false, cd: '', start: '' };
  clonedSwotMatrix: ISwotMatrix = { strengths: [], oppurtunities: [], weaknesses: [], threats: [], needForChange: [], archive: false, loopbackStatusUpdated: false, cd: '', start: '' };

  constructor(private busStartegyService: BusinessStrategyService,
    private itDiagService: itDiagnosticsService,
    private techStrategyService: TechstrategyService,
    private sharedService: SharedService) { }



  ngOnInit(): void {
    this.getBusinessSwotAnalysis();
    //this.getLoopBack();

  }


  getLoopBack() {
    if (this.screenName == 'techstrategy') {
      this.swotCategories = ['Strengths', 'Oppurtunities', 'Weaknesses', 'Threats', 'Need for change'];
      this.getTechThemes();
      this.getNeedForChnage();
    }
    else {
      this.swotCategories = ['Strengths', 'Oppurtunities', 'Weaknesses', 'Threats'];
      this.getBusinessObjectives();
    }

  }

  getBusinessSwotAnalysis() {
    let input = this.screenName == 'techstrategy' ? 'itswotanalysis' : 'businessswotanalysis';
    this.itDiagService.getSwotAnalysis(input).subscribe(response => {
      this.swotMatrix = response;
      this.clonedSwotMatrix = JSON.parse(JSON.stringify(this.swotMatrix));
      this.getLoopBack();
    });
  }

  getTechThemes() {
    this.techStrategyService.getTechnologyThemes().subscribe(response => {
      this.techThemes = response;

      if (this.techThemes && this.techThemes.length > 0) {

        this.objectives = [];
        this.techThemes.forEach(x => {
          x.themes.forEach(y => {
            this.objectives.push({ Id: y._id, isChecked: false, title: y.name, progs: y.progs })
          });
        });

        this.setObjectives();

      }
    });
  }

  getNeedForChnage() {
    this.itDiagService.getNeedForChange().subscribe(response => {
      this.needForChange = response;

      if (this.needForChange && this.needForChange.needChng && this.needForChange.needChng.categories) {
        this.needForChange.needChng.categories.forEach(x => {
          this.loopBackNeedForChange.push({ category: x.title, _id: x._id });
        })
      }

    });
  }

  getBusinessObjectives() {
    this.busStartegyService.getBusinessObjectives().subscribe(response => {
      this.swotBS = response;
      if (this.swotBS && this.swotBS.categories && this.swotBS.categories.length > 0) {
        this.swotCategory = this.swotBS.categories;
        //this.cloneSwotCategory = JSON.parse(JSON.stringify(this.swotBS.categories));

        this.objectives = [];
        this.swotCategory.map(x => {
          x.objectives.forEach(y => {
            this.objectives.push({ Id: y._id, isChecked: false, title: y.title, progs: y.progs })
          });
        });

        this.setObjectives();

      }
    });
  }

  setObjectives() {
    this.swotMatrix.strengths.map(t => {
      let objlist = JSON.parse(JSON.stringify(this.objectives));
      if (!t.selectedObjectives)
        t.selectedObjectives = [];
      if (!t.selectedProjects)
        t.selectedProjects = [];

      objlist?.map((o: any) => {
        const foundObjective = t.objectives?.find((ob: any) => ob == o.Id);
        if (foundObjective) {
          o.isChecked = true;
          t.selectedObjectives?.push({ 'Id': foundObjective.Id, 'title': foundObjective.title, 'isChecked': true, progs: foundObjective.progs });
          o.progs.map((p: any) => {
            // const foundProj = t.projects?.find((proj: any) => proj._id || proj == p._id);
            const foundProj = t.projects?.find((proj: any) => proj._id === p._id || proj === p._id);

            // if (foundProj) {
            //   p.isChecked = true;
            //   t.selectedProjects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
            //   t.projects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
            // }
            if (foundProj) {
              p.isChecked = true;
              const newProject = { Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false };
              const isDuplicate = t.selectedProjects?.some(proj => proj.Id === newProject.Id);
              if (!isDuplicate) {
                t.selectedProjects?.push(newProject);
              }
              t.projects?.push(newProject);
            }

          })
        }

      });
      t.objectives = objlist;
      this.filterObjectives(t);
      console.log("selected projects:", t.selectedProjects)
    });

    this.swotMatrix.oppurtunities.map(t => {
      // t.objectives = JSON.parse(JSON.stringify(this.objectives));
      let objlist = JSON.parse(JSON.stringify(this.objectives));
      if (!t.selectedObjectives)
        t.selectedObjectives = [];
      if (!t.selectedProjects)
        t.selectedProjects = [];

      objlist?.map((o: any) => {
        const foundObjective = t.objectives?.find((ob: any) => ob == o.Id);
        if (foundObjective) {
          o.isChecked = true;
          o.progs.map((p: any) => {
            const foundProj = t.projects?.find((proj: any) => proj._id === p._id || proj === p._id);
            if (foundProj) {
              p.isChecked = true;
              t.selectedProjects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
              t.projects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
            }
          })
        }
      });
      t.objectives = objlist;
      this.filterObjectives(t);

    });

    this.swotMatrix.weaknesses.map(t => {
      let objlist = JSON.parse(JSON.stringify(this.objectives));
      if (!t.selectedObjectives)
        t.selectedObjectives = [];
      if (!t.selectedProjects)
        t.selectedProjects = [];

      objlist?.map((o: any) => {
        const foundObjective = t.objectives?.find((ob: any) => ob == o.Id);
        if (foundObjective) {
          o.isChecked = true;
          o.progs.map((p: any) => {
            const foundProj = t.projects?.find((proj: any) => proj._id === p._id || proj === p._id);
            if (foundProj) {
              p.isChecked = true;
              t.selectedProjects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
              t.projects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
            }
          })
        }
      });
      t.objectives = objlist;
      this.filterObjectives(t);
    });

    this.swotMatrix.threats.map(t => {
      let objlist = JSON.parse(JSON.stringify(this.objectives));
      if (!t.selectedObjectives)
        t.selectedObjectives = [];
      if (!t.selectedProjects)
        t.selectedProjects = [];

      objlist?.map((o: any) => {
        const foundObjective = t.objectives?.find((ob: any) => ob == o.Id);
        if (foundObjective) {
          o.isChecked = true;
          o.progs.map((p: any) => {
            const foundProj = t.projects?.find((proj: any) => proj._id === p._id || proj === p._id);
            if (foundProj) {
              p.isChecked = true;
              t.selectedProjects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
              t.projects?.push(({ Id: p._id, projId: p._id, name: p.prgName, isChecked: true, isParent: false }));
            }
          })
        }
      });
      t.objectives = objlist;
      this.filterObjectives(t);
    });

    if (this.screenName == 'techstrategy') {
      this.swotMatrix.needForChange = this.loopBackNeedForChange;
      this.swotMatrix.needForChange.forEach(t => {
        t.objectives = JSON.parse(JSON.stringify(this.objectives));
      });
    }
    else {
      this.swotMatrix.needForChange = [];
    }



  }

  onSelectCategory(index: number, category: string) {
    this.selectedCategordIndex = index;
    this.selectedSwotType = category;
  }

  // filterObjectives(strength: any) {
  //   let selectedData: any = strength.objectives?.filter((x: any) => x.isChecked);
  //   if (selectedData && selectedData.length > 0) {
  //     let data = this.objectives?.filter(t => selectedData?.find((x: any) => x.title.toLowerCase() == t.title?.toLowerCase()))
  //     strength.selectedObjectives = JSON.parse(JSON.stringify(data));

  //    console.log(JSON.stringify(strength.objectives)+"obj")

  //     strength.projects = [];
  //     strength.selectedObjectives?.map((element: any) => {
  //       strength.projects?.push({ Id: element.Id, name: element.title, isParent: true, isChecked: false });
  //       if (element.progs)
  //         if (strength.selectedProjects) {
  //           element.progs.map((prg: any) => {
  //             const checkSelectedProj = strength.selectedProjects.find((sp: any) => sp.Id == prg._id);
  //             if (checkSelectedProj)
  //               strength.projects?.push({ Id: element.Id, projId: prg._id, name: prg.prgName, isChecked: true, isParent: false });
  //             else
  //               strength.projects?.push({ Id: element.Id, projId: prg._id, name: prg.prgName, isChecked: false, isParent: false });
  //           });
  //         }
  //         else {
  //           element.progs.map((prg: any) => {
  //             strength.projects?.push({ Id: element.Id, projId: prg._id, name: prg.prgName, isChecked: false, isParent: false });
  //           });
  //         }
  //     });
  //   }
  //   else {
  //     strength.objectives = JSON.parse(JSON.stringify(this.objectives));
  //     strength.selectedObjectives = [];

  //     strength.projects = [];
  //     strength.selectedProjects = [];
  //   }

  // }




  // map objectives start
  removeObj(strength: any, obj: any) {
    obj.isChecked = false;
    strength.selectedObjectives = strength.selectedObjectives.filter((selectedObj: any) => selectedObj !== obj);

    // Remove the selected projects associated with the removed objective
    strength.selectedProjects = strength.selectedProjects.filter((selectedProj: any) => {
      return !obj.progs.some((prg: any) => prg._id === selectedProj.projId);
    });

    this.updateCheckboxStates(strength);
    this.updateProjectss(strength); // Update projects after removing the objective
  }

  updateCheckboxStates(strength: any) {
    strength.projects.forEach((project: any) => {
      project.isChecked = strength.selectedObjectives.some((selectedObj: any) => {
        return selectedObj.progs.some((prg: any) => prg._id === project.projId);
      });
    });
  }

  filterObjectives(strength: any) {
    let selectedData: any = strength.objectives?.filter((x: any) => x.isChecked);
    if (selectedData && selectedData.length > 0) {
      strength.selectedObjectives = selectedData;
      this.updateProjectss(strength);
    } else {
      strength.objectives = JSON.parse(JSON.stringify(this.objectives));
      strength.selectedObjectives = [];
      strength.projects = [];
      strength.selectedProjects = [];
    }
  }

  updateProjectss(strength: any) {
    strength.projects = [];
    strength.selectedObjectives.forEach((objective: any) => {
      strength.projects.push({ Id: objective.Id, name: objective.title, isParent: true, isChecked: false });
      if (objective.progs && strength.selectedProjects) {
        objective.progs.forEach((prg: any) => {
          const checkSelectedProj = strength.selectedProjects.find((sp: any) => sp.projId === prg._id);
          strength.projects.push({ Id: objective.Id, projId: prg._id, name: prg.prgName, isChecked: checkSelectedProj ? true : false, isParent: false });
        });
      } else if (objective.progs) {
        objective.progs.forEach((prg: any) => {
          strength.projects.push({ Id: objective.Id, projId: prg._id, name: prg.prgName, isChecked: false, isParent: false });
        });
      }
    });

    // Hide objectives that are not selected
    strength.projects = strength.projects.filter((project: any) => {
      if (project.isParent) {
        return true;
      }
      const objectiveExists = strength.selectedObjectives.find((objective: any) => {
        return objective.progs.some((prg: any) => prg._id === project.projId);
      });
      return objectiveExists;
    });
  }

  //map objectives end


  // map projects start
  removeObjectives(strength: any, obj: any) {
    obj.isChecked = false;
    strength.selectedProjects = strength.selectedProjects.filter((selectedObj: any) => selectedObj !== obj);
    this.updateCheckboxState(strength);
  }

  updateCheckboxState(strength: any) {
    strength.projects.forEach((project: any) => {
      project.isChecked = strength.selectedProjects.some((selectedObj: any) => selectedObj.name === project.name);
    });
  }

  filterProjects(strength: any) {
    let selectedData: any = strength.projects?.filter((x: any) => x.isChecked);
    if (selectedData && selectedData.length > 0) {
      strength.selectedProjects = selectedData;
    } else {
      strength.selectedProjects = [];
    }
  }
  //   filterProjects(strength: any) {
  //     const uniqueProjectIds = new Set();
  //     let selectedData: any = strength.projects?.filter((x: any) => {
  //         if (x.isChecked && !uniqueProjectIds.has(x.Id)) {
  //             uniqueProjectIds.add(x.Id);
  //             return true;
  //         }
  //         return false;
  //     });
  //     strength.selectedProjects = Array.from(uniqueProjectIds).map(projectId => {
  //         return selectedData.find((x: any) => x.Id === projectId);
  //     });
  // }


  // map projects end



  getStatus(code: number) {
    let index = this.swotStatus.findIndex((x: any) => x.code == code);
    return index != -1 ? this.swotStatus[index].title : 'No Status';
  }

  getstrStatus(code: number) {
    let index = this.swotstreStatus.findIndex((x: any) => x.code == code);
    return index != -1 ? this.swotstreStatus[index].title : 'No Status';
  }

  getoppStatus(code: number) {
    let index = this.swotOPPStatus.findIndex((x: any) => x.code == code);
    return index != -1 ? this.swotOPPStatus[index].title : 'No Status';
  }
  getStatusImg(code: number) {
    let index = this.swotStatus.findIndex((x: any) => x.code == code);
    return index != -1 ? this.swotStatus[index].image : "../../../../../../assets/images/uploads/question-symbol.svg";
  }

  legendData: any[] = [
    {
      Response: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      Objectives: "Strength & Sustainability",
      Projects: 'Mosaic(Branding Reinvention',
      Status: "Partially Exploited"
    },
    {
      Response: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      Objectives: "Strength & Sustainability",
      Projects: 'Mosaic(Branding Reinvention',
      Status: "Not Required To Exploit"
    },
    {
      Response: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      Objectives: "Strength & Sustainability",
      Projects: 'Mosaic(Branding Reinvention',
      Status: "No Status"
    },
    {
      Response: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      Objectives: "Strength & Sustainability",
      Projects: 'Mosaic(Branding Reinvention',
      Status: "Fully Exploited"
    },
    {
      Response: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      Objectives: "Strength & Sustainability",
      Projects: 'Mosaic(Branding Reinvention',
      Status: "Partially Exploited"
    }
  ];

  SaveSwot(selectedCategoryIdx?: number) {
    let payload: any = {};
    switch (selectedCategoryIdx) {
      case 0:
        payload = {
          strengths: []
        };
        this.swotMatrix.strengths.forEach((sw: any) => payload.strengths.push(
          {
            _id: sw._id,
            response: sw.response || '',
            status: sw.status,
            projects: sw.selectedProjects?.map((x: any) => x.projId) || [],
            objectives: sw.selectedObjectives?.map((x: any) => x.Id) || [],
            strength: sw.strength
          }
        ));

        break;
      case 1:
        payload = {
          oppurtunities: []
        };
        this.swotMatrix.oppurtunities.forEach((opp: any) => payload.oppurtunities.push(
          {
            _id: opp._id,
            response: opp.response || '',
            status: opp.status,
            projects: opp.selectedProjects?.map((x: any) => x.projId) || [],
            objectives: opp.selectedObjectives?.map((x: any) => x.Id) || [],
            oppurtunity: opp.oppurtunity
          }
        ));
        break;
      case 2:
        payload = {
          weaknesses: []
        };
        this.swotMatrix.weaknesses.forEach((w: any) => payload.weaknesses.push(
          {
            _id: w._id,
            response: w.response || '',
            status: w.status,
            projects: w.selectedProjects?.map((x: any) => x.projId) || [],
            objectives: w.selectedObjectives?.map((x: any) => x.Id) || [],
            weakness: w.weakness
          }
        ));
        break;
      case 3:
        payload = {
          threats: []
        };
        this.swotMatrix.threats.forEach((w: any) => payload.threats.push(
          {
            _id: w._id,
            response: w.response || '',
            status: w.status,
            projects: w.selectedProjects?.map((x: any) => x.projId) || [],
            objectives: w.selectedObjectives?.map((x: any) => x.Id) || [],
            threat: w.threat
          }
        ));
        break;
      case 4:
        payload = {
          needForChange: []
        };
        this.swotMatrix.needForChange.forEach((w: any) => payload.needForChange.push(
          {
            _id: w._id,
            response: w.response || '',
            status: w.status,
            projects: w.selectedProjects?.map((x: any) => x.projId) || [],
            objectives: w.selectedObjectives?.map((x: any) => x.Id) || [],
            needForChange: w.needForChange
          }
        ));
        break;
    }

    if (this.screenName == 'techstrategy') {
      this.techStrategyService.postLoopback(payload).subscribe(response => {
        this.sharedService.alertNotification('success', 'Loop Back', 'Saved Successfully');
        this.getBusinessSwotAnalysis();
      });
    }
    else {
      this.busStartegyService.postLoopback(payload).subscribe(response => {
        this.sharedService.alertNotification('success', 'Loop Back', 'Saved Successfully');
        this.getBusinessSwotAnalysis();
      });
    }

  }
  CancelSwot() {
    this.swotMatrix = JSON.parse(JSON.stringify(this.clonedSwotMatrix));
    this.setObjectives();
  }

  TrimHtmlTags(content: any) {
    if (content) {
      return Utils.Trimhtmltags(content);
    }
  }
}
