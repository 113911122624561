import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { AuthGuard } from './guards/auth.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [{
  path: 'home',
  canActivate: [AuthGuard],
  loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
}, {
  path: 'xadmin',
  canActivate: [AuthGuard],
  loadChildren: () => import('./modules/xadmin/xadmin.module').then(m => m.XadminModule)
}, {
  path: 'auth',
  loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
}, {
  path: 'input',
  canActivate: [AuthGuard],
  loadChildren: () => import('./modules/input/input.module').then(m => m.InputModule)
}, {
  path: 'presentation',
  canActivate: [AuthGuard],
  loadChildren: () => import('./modules/presentation/presentation.module').then(m => m.PresentationModule)
},
{
  path: 'unauthorized',
  component: UnauthorizedComponent
},
{
  path: 'print/:strategyId/:moduleId/:screenId',
  canActivate: [AuthGuard],
  loadChildren: () => import('./modules/print/print.module').then(m => m.PrintModule)
},
{
  path: '',
  redirectTo: 'auth',
  pathMatch: 'full'
},
{
  path: '404',
  component: PagenotfoundComponent
},
{
  path: '**',
  redirectTo: '404'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
