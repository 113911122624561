import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-sidenav',
  templateUrl: './input-sidenav.component.html',
  styleUrls: ['./input-sidenav.component.scss']
})
export class InputSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
