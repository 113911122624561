import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appImageFallback]'
})
export class ImageFallbackDirective {
  @Input() fallbackSrc: string;

  constructor() { }

  @HostListener('error', ['$event.target'])
  onError(target: HTMLImageElement) {
    if (this.fallbackSrc) {
      target.src = this.fallbackSrc;
    } else {
      target.src = '../../assets/images/public/BUDefaultLogo.jpeg';
    }
  }
}
