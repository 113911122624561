import { 
  Component, 
  forwardRef, 
  OnInit, 
  Input,
  ViewEncapsulation 
} from '@angular/core';
import { 
  ControlValueAccessor, 
  NG_VALUE_ACCESSOR 
} from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class PasswordComponent implements ControlValueAccessor, OnInit {
  @Input() 
  public set value(v : string) {
    this._value = v;
  }

  public _value: string; 

  @Input() public label: string; 

  @Input() public type: any = 2;

  constructor() { }

  ngOnInit(): void {
  }
  
  public get value() : string {
    return this._value
  }
  public propogateChange = (_: any) => {}

  public propogateTouch = (_: any) => {}

  registerOnChange(fn: any) {
    this.propogateChange = fn;
   }

  registerOnTouched(fn: any){
    this.propogateTouch = fn;
   }

  writeValue(value: any) {
    this.value = value;
  }  

  public onChangeValue(value: any) {
    this.propogateTouch(this.value);
    this.value = value;
    this.propogateChange(this.value);
  }

}
