import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';

@Component({
  selector: 'app-exesump',
  templateUrl: './exesump.component.html',
  styleUrls: ['./exesump.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExesumpComponent implements OnInit {

  constructor(
    private itDiagService: itDiagnosticsService
  ) { }

  exesum:any;

  ngOnInit(): void {
    this.getexesummary();
  }

  getexesummary(){
    this.itDiagService.getAuthor().subscribe(res =>
      this.exesum=res
      );}
      
}
