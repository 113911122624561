<!--  tab  -->
<div class="capability-heatmap">

    <div class="px-1 pb-2 sticky-top">
        <div class="sidebar-header bg-info1 d-flex align-items-center justify-content-between">
            <p class="m-0 fw-bold h-fs text-white upload-data d-none d-lg-block uppercase">{{headerName}}</p>

            <div class="subheader-btn float-end text-end">
                <div class="header-btn-group">
                    <!-- <a class="btn btn-dark1 fw-500 px-1" href="javascript:void(0)" data-toggle="tooltip"
                        data-placement="bottom" title="Reorder the columns" data-bs-toggle="modal"
                        data-bs-target="#modelObservations1"><img
                            src="..\assets\images\uploads\Group 3681.svg" alt=""></a> -->
                    <a class="btn btn-dark1 fw-500 px-1" href="javascript:void(0)" data-toggle="tooltip"
                        data-placement="bottom" title="Reorder the columns" [ngClass]="{'disabled': true}"><img
                            src="..\assets\images\uploads\Group 3681.svg" alt=""></a>
                    <a class="btn btn-dark1 fw-500 px-1" href="javascript:void(0)" data-toggle="tooltip"
                        data-placement="bottom" title="Revert to Default" (click)="OnDefaultHeatMap()"><img
                            src="..\assets\images\uploads\header-star.svg" alt=""></a>
                    <a class="btn btn-dark1 fw-500 px-1" href="javascript:void(0)" data-toggle="tooltip"
                        data-placement="bottom" title="Reset Columns" (click)="onResetHeatMap()"><img
                            src="..\assets\images\uploads\header-arrow.svg" alt=""></a>
                    <a class="btn btn-dark1 fw-500 px-1" href="javascript:void(0)" data-toggle="tooltip"
                        data-placement="bottom" title="Add New Column" (click)="onAddNewColumn()"
                        [ngClass]="{'disabled':getCalculatedSize()>10}"><img
                            src="..\assets\images\uploads\header-row.svg" alt=""
                            ng-disabled="departments.length == 11"></a>
                    <button class="btn1 btn btn-dark1 h-fs fw-500 px-1" data-toggle="tooltip" data-placement="bottom"
                        title="Save" (click)="onSaveHeatMap()"><img
                            src="../../../../../../assets/images/uploads/save.svg" alt="" height="20" width="20"
                            class="mb-1"> SAVE</button>
                    <button class=" btn btn-dark1 h-fs fw-500 px-1" data-toggle="tooltip" data-placement="bottom"
                        title="Cancel" (click)="onCancelHeatMap()"><img alt=""><img
                            src="../../../../../../assets/images/uploads/reset-white.svg" alt="" width="20" height="20"
                            class="mb-1 me-1 reset">CANCEL</button>
                </div>
            </div>


        </div>
    </div>

    <div class="container-fluid ">
        <div class="row main row-cols-2 row-cols-md-3 row-cols-xl-6 row-cols-xxl-eleven">

            <div *ngFor="let department of departments; index as i"
                [ngClass]="department.capabilities.length > 13 ? 'col px-1 multi-col' : 'col px-1'"
                [style]="getWidth(department.capabilities)">
                <div class="mb-2">
                    <div class="cap-box bg-info-dark">
                        <div class="department-actions position-absolute">

                            <ul class="nav justify-content-end pr-2">
                                <li class="nav-item font-size-14p mx-2">
                                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top"
                                        title="Edit Header" (click)="editHeader(i)">
                                        <img src="../../../../assets/images/uploads/pencil-nobordery.png"
                                            class="img-fluid" alt="edit icon" width="15">
                                    </a>
                                </li>
                                <li class="nav-item font-size-14p mx-2">
                                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top"
                                        title="Contract Column" (click)="leftExpand(i)"
                                        [ngClass]="{'disabled':checkLeftExpand(i)}">
                                        <i class="bi bi-arrow-left text-white" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li class="nav-item font-size-14p mx-2">
                                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top"
                                        title="Expand Column" (click)="rightExpand(i)"
                                        [ngClass]="{'disabled':getCalculatedSize()>10}">
                                        <i class="bi bi-arrow-right text-white" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li class="nav-item font-size-14p ml-2">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#deletColumnModal">
                                        <div data-toggle="tooltip" data-placement="top" title="Delete Column"
                                            (click)="deleteHeader(i)">
                                            <img src="../../../../assets/images/uploads/delete16x16.png"
                                                class="img-fluid" alt="delete icon">
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <p class="fs-12 m-0 text-light fw-500" *ngIf="editedHeaderIndex != i">
                            {{department.display_name}}</p>
                        <input type="text" [(ngModel)]="department.display_name" *ngIf="editedHeaderIndex == i"
                            (blur)="onBlur(i)">
                    </div>
                    <div class="d-flex cus-div">
                        <div *ngFor="let de of getColumns(department.capabilities);">
                            <div *ngFor="let capability of department.capabilities; index as j">
                                <!-- box -->
                                <div [ngClass]="de > 1 ? 'mb-2 show-data' : 'mb-2 show-data'"
                                    *ngIf="j >= (13*(de-1)) && j < (13 * de)">

                                    <div class="capability-{{capability.rag?capability.rag:'default'}}-block">
                                        <div [class]="checkObservationForCapability(capability)?'cap-box1':'cap-box'">
                                            <p class="fs-12 m-0  fw-500"
                                                *ngIf="!(showCapabilityText && i == headerIndex && j == dataIndex)">
                                                {{capability.display_name}}</p>
                                            <input type="text" class="fs-12" id="floatingInput" autofocus
                                                [(ngModel)]="capability.display_name" (blur)="onLostFocus()"
                                                *ngIf="showCapabilityText && i == headerIndex && j == dataIndex">
                                        </div>
                                    </div>
                                    <!-- overlay - box -->
                                    <div class="overlay-box"
                                        [ngClass]="{'overlay-box-obs' : checkObservationForCapability(capability)}"
                                        *ngIf="!(showCapabilityText && i == headerIndex && j == dataIndex)">
                                        <div class="d-flex justify-content-between">
                                            <div class="dropdown">

                                                <button class="btn btn-sm btn-info1 dropdown-toggle fs-12 text-white"
                                                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    RAG
                                                </button>
                                                <ul class="dropdown-menu p-0 overflow-hidden">
                                                    <li *ngFor="let ragColor of ragColors; index as l"><a
                                                            class="dropdown-item" href="javascript:void(0)"
                                                            (click)="onChangeRAG(capability, ragColor)">{{ragColor.name}}</a>
                                                    </li>
                                                </ul>
                                            </div>



                                            <div class="action-btns">
                                                <button class="btn btn-info1 me-1 p-0" data-bs-toggle="modal"
                                                    data-bs-target="#editCapabilityModel"
                                                    (click)="editCapability(i,j,capability)"><img
                                                        src="..\assets\images\uploads\edit white.svg"
                                                        width="20px"></button>
                                                <button class="btn btn-danger "><img
                                                        src="..\assets\images\uploads\delete-white.svg" width="10px"
                                                        (click)="onDeleteCapability(i,j,capability)"></button>
                                            </div>
                                        </div>

                                        <button *ngIf='capability.display_name'
                                            class="btn btn-info1 form-control text-white border-0"
                                            data-bs-toggle="modal" data-bs-target="#modelObservations"
                                            (click)="addObservation(department,capability)">ADD OBSERVATION
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="top-center"></p-toast>
<!-- </section> -->
<app-heatmapobservations [capability]="capability" [department]="department" [heatMap]="heatMap" [type]="type"
    (saveClick)="saveClick()">
</app-heatmapobservations>

<div class="modal fade" id="modelObservations1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header py-2 bg-info1 text-light ">
                <h1 class="modal-title fs-18" id="staticBackdropLabel">COLUMN REORDER</h1>
                <div>
                    <div>
                        <!-- <button *ngIf="!isAdd" class="btn btn-info2 text-white" (click)="addObservation()">+ ADD
              OBSERVATION</button> -->
                        <button class="btn btn-info2 ms-2 text-light"> <img
                                src="../../../../../../assets/images/uploads/save.svg" alt="" height="20" width="20"
                                class="mb-1"> Save</button>
                        <button class="btn btn-info2 ms-2 text-light" data-bs-dismiss="modal"
                            (click)="onCancel()">Cancel</button>

                    </div>
                </div>
            </div>
            <div class="modal-body p-0">
                <div class="col-md-6">
                    <div *ngFor="let item of simpleList[0]" class="col-md-12"
                        (dndMoved)="removeItem(item, simpleList[0])">{{item.name}}</div>
                </div>
                <!-- <div class="col-md-6"
        [dndPlaceholder]="placeholder"
        [dndModel]="todo">
        <div *ngFor="let item of todo"
          class="col-md-12"
          [dndType]="'item'"
          (dndMoved)="removeItem(item, todo)"
          [dndDraggable]
          [dndObject]="item">{{item}}</div>
      </div> -->
                <!-- <table class="table table-responsive table-bordered">
          <thead>
            <tr>
              <th scope="col" class="w-90 p-3 bg-success-light text-dark">Observation #</th>
              <th scope="col" class="w-10  p-3 bg-success-light text-dark">Addressed in this strategy?</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isAdd">
              <td class="ps-3"><input type="text" placeholder="Observation" [(ngModel)]="observaton"
                  style="width:'100%'"></td>
              <td>
                <div class="dropdown">
                  <button *ngIf="addressStatuses && addressStatuses.length > 0"
                    class="btn dropdown-toggle bg-secondary border-0" type="button" data-bs-toggle="dropdown"
                    aria-expanded="true">
                    <img [src]="getImg()" height="22px" width="22px" class="me-2">
                    {{getSta()}} </button>
                  <ul class="dropdown-menu border-0 rounded-10 shadow bg-secondary"
                    data-popper-placement="bottom-start">
                    <li *ngFor="let item of addressStatuses" (click)="selectStatus(item.name)">
                      <a class="dropdown-item">
                        <img [src]="item.image" height="22px" width="22px" class="me-2">
                        {{item.name}} </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr *ngFor="let ob of observations">
              <td class="ps-3"> {{ob.desc}}
              </td>
              <td>
                <div class="dropdown">
                  <button *ngIf="addressStatuses && addressStatuses.length > 0" disabled="true"
                    class="btn dropdown-toggle bg-secondary border-0" type="button" data-bs-toggle="dropdown"
                    aria-expanded="true">
                    <img [src]="getImage(ob)" height="22px" width="22px" class="me-2">
                    {{getStatus(ob)}} </button>
                  <ul class="dropdown-menu border-0 rounded-10 shadow bg-secondary"
                    data-popper-placement="bottom-start">
                    <li *ngFor="let item of addressStatuses">
                      <a class="dropdown-item">
                        <img [src]="item.image" height="22px" width="22px" class="me-2">
                        {{item.name}} </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

          </tbody>
        </table> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editCapabilityModel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header py-2 bg-info1 text-light ">
                <h1 class="modal-title fs-18" id="staticBackdropLabel">EDIT CAPABILITY</h1>
                <div>
                    <div>
                        <button class="btn btn-info2 ms-2 me-2 text-light" (click)="SaveCpability()"> <img
                                src="../../../../../../assets/images/uploads/save.svg" alt="" height="20" width="20"
                                class="mb-1">
                            Save</button>
                        <button type="button" class="btn btn-info2 text-light rounded-circle" #closebutton
                            data-bs-dismiss="modal">
                            <img src="../../../../../../assets/images/uploads/close-white.svg" height="11px">
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body p-0">
                <div class="clearfix">
                    <input
                        class="form-control input-search normal-input text-black-default border-left-0 border-right-0 border-top-0 global-search"
                        name="search" style="display: inline-block;border: 1px solid #c1c1c1;padding-left: 20px;"
                        placeholder="Search existing or enter new capability name" type="search" [(ngModel)]="search"
                        #listFilter>
                    <ul *ngIf="!search">
                        <li *ngFor="let item of filteredCapabilitiesList" (click)="capClick(item)"
                            style="border-bottom:1px solid #c1c1c1;padding: 5px;">
                            {{item.display_name}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>