import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(protected httpClient: HttpClient) {

  }

  generateHeaders(token?: any,isMultipart: any=false) {
    if (token) {
      return new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': isMultipart?'multipart/form-data; boundary=--------------------------608101077268576305554789':'application/json',
        Authorization: 'Bearer ' + token
      });
    }
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': isMultipart?'multipart/form-data; boundary=--------------------------608101077268576305554789':'application/json'
    });
  }

  protected get = (endpoint: string, token?: any): Observable<any> => {
    const header = this.generateHeaders(token);
    const requestUrl: any = (environment.API_URL + endpoint);
    return this.httpClient.get(requestUrl, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }

  protected post = (endpoint: string, postBody: any, token?: any,isMultipart:boolean=false): Observable<any> => {
    const header = this.generateHeaders(token,isMultipart);
    // if (endpoint === '1/me/v3/upload/image') {
    //   header.delete('Content-Type');
    //   header.append('Content-Type', 'multipart/form-data');
    // }
    const requestUrl = environment.API_URL + endpoint;
    return this.httpClient.post(requestUrl, postBody, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }
  
  protected put = (endpoint: string, putBody: any, token?: any,isMultipart:boolean=false): Observable<any> => {
    const header = this.generateHeaders(token,isMultipart);
    const requestUrl = environment.API_URL + endpoint;
    return this.httpClient.put(requestUrl, putBody, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }

  protected delete = (endpoint: string, token?: any): Observable<any> => {
    const header = this.generateHeaders(token);
    const requestUrl = environment.API_URL + endpoint;
    return this.httpClient.delete(requestUrl, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }

  protected update = (endpoint: string, body: any, token?: any): Observable<any> => {
    const header = this.generateHeaders(token);
    const requestUrl = environment.API_URL + endpoint;
    return this.httpClient.put(requestUrl, body, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }

  protected handleError(error: any) {
    return throwError(() => error);
  }

  protected postMultipartImage = (endpoint: string, postBody: any, token?: any): Observable<any> => {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + token
    }
    );
    // header.append('mimeType', 'multipart/form-data');
    const requestUrl = environment.API_URL + endpoint;
    return this.httpClient.post(requestUrl, postBody, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }

  protected postFormData = (endpoint: string, formData: any, token?: any): Observable<any> => {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + token
    }
    );
    // header.append('mimeType', 'multipart/form-data');
    const requestUrl = environment.API_URL + endpoint;
    return this.httpClient.post(requestUrl, formData, { headers: header, observe: 'response', withCredentials: true }).pipe(map(respData => {
      return respData;
    }, (error: any) => {
      this.handleError(error);
    }));
  }

}
