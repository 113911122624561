import { Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements ControlValueAccessor, OnInit {
  _value: any;
  constructor() { }

  ngOnInit(): void {
    
  }

  public get value() : string {
    return this._value
  }
  
  public set value(v : string) {
    this._value = v;
    console.log(this._value);
  }
  
  registerOnChange() { }

  registerOnTouched(){ }

  writeValue(value: any) {
    this.value = value;
  } 

}
