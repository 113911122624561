import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputswitch',
  templateUrl: './inputswitch.component.html',
  styleUrls: ['./inputswitch.component.scss']
})
export class InputswitchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
