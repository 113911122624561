import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChipsComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class ChipsComponent implements ControlValueAccessor, OnInit {
  @Input()
  isRemovable: boolean = true;
  @Input()
  _label: string;
  constructor() { }

  ngOnInit(): void {
  }

  public get label() : string {
    return this._label
  }
  
  public set label(v : string) {
    this._label = v;
    console.log(this._label);
  }
  
  registerOnChange() { }

  registerOnTouched(){ }

  writeValue(value: any) {
    this.label = value;
  } 
}
