
<ng-container *ngIf="type == 1">
    <span class="p-float-label p-input-filled">
        <p-password [(ngModel)]="value" [toggleMask]="true" id="passwordtype1" (ngModelChange)="onChangeValue($event)"> 
            <ng-template pTemplate="header">
              <h6>Pick a password</h6>
            </ng-template>
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p class="p-mt-2">Suggestions</p>
              <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
              </ul>
            </ng-template>
          </p-password>
          <label for="passwordtype1">{{label}}</label>
    </span>
    
</ng-container>

<ng-container *ngIf="type == 2">
    <span class="p-float-label p-input-filled">
        <p-password [(ngModel)]="value" [toggleMask]="true" id="passwordtype2" [feedback]="false" (ngModelChange)="onChangeValue($event)"></p-password>
        <label for="passwordtype2">{{label}}</label>
    </span>
    
</ng-container>
