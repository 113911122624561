import { Injectable } from "@angular/core";
import { CoreService } from "../core.service";
import { HttpClient } from "@angular/common/http";
import { AppService } from "../appservice/app.service";
import { Appconfig } from "src/app/configurations/appconfig";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GovernDeliverService extends CoreService {


  constructor(httpClient: HttpClient, private appService: AppService) {
    super(httpClient);
    this.setInitialValues();
  }

  setInitialValues() {
  }

  AddCriteria(data: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v2/create/ppc', data).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  GetAllCriterias() {
    // this.setInitialValues();
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v2/get/ppc').pipe(map(
      respData => {
        return respData.body;
      }));
  }

  UpdateCriteria(id: any, data: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v2/update/ppc/' + id, data).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  deleteCriteria(id: any) {
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v2/delete/ppc/' + id).pipe(map(
      respData => {
        return respData;
      }
    ));
  }

  getProjectData() {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
      this.appService.strategyId +
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  assignCriteriatoProject(data: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v2/update/portfolioprioritisation', data).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  getassignCriteriatoProject() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v2/get/pp').pipe(map(
      respData => {
        return respData.body;
      }));
  }

  updateProject(projectData: any) {
    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE 
      +'/' + this.appService.strategyId +
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_DATA,
      projectData
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getProjectBudjets() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/v3/get-project-budgets').pipe(map(
      respData => {
        return respData.body;
      }));
  }
}