import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';

@Component({
  selector: 'app-hmapobservations',
  templateUrl: './hmapobservations.component.html',
  styleUrls: ['./hmapobservations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class HmapobservationsComponent implements OnInit,OnChanges {
  @ViewChild('closebutton') closebutton: any;
  addressStatus: any = 'select';
  addressStatuses: any[] = [
    { name: 'No Status Available', image: '../../../../../../assets/images/uploads/editor-alert-icons/not-required-colored.svg' },
    { name: 'Partially Addressed', image: '../../../../../../assets/images/uploads/editor-alert-icons/partially-addressed-colored.svg' },
    { name: 'Fully Addressed', image: '../../../../../../assets/images/uploads/editor-alert-icons/fully-addressed-colored.svg' },
    { name: 'Not Required to Address', image: '../../../../../../assets/images/uploads/editor-alert-icons/no-status-colored.svg' },
    { name: 'Not Addressed', image: '../../../../../../assets/images/uploads/editor-alert-icons/not-addressed-colored.svg' }];
  isAdd = true;
  @Input() capability: any;
  @Input() heatMap: any;
  @Input() department: any;
  @Input() type: any;
  observations: any;
  observaton: any;
  @Output() saveClick: any = new EventEmitter();
  constructor(private itDiagService: itDiagnosticsService
    ,private dialogConfig: DynamicDialogConfig) {

   }

  ngOnInit(): void {
    this.onCancel();
    this.capability=this.dialogConfig?.data?.capability;
    this.heatMap=this.dialogConfig?.data?.heatMap;
    this.department=this.dialogConfig?.data?.department;
    this.type= this.dialogConfig?.data?.type;
    if (this.department && this.capability)
      this.observations = this.department.observations.filter((x: any) => x.capability == this.capability.code);
  }

  ngOnChanges() {
    
  }

  addObservation() {
    this.isAdd = true;
  }

  onDataSave() {
    let i = this.observations.findIndex((x: any) => !x.desc);
    if (i > -1) {
    }
    if (this.department && i == -1) {
      if (this.isAdd && this.observaton) {
        if (this.capability) {
          this.observations.push({ capability: this.capability.code, desc: this.observaton, sort_order: "11", status: this.addressStatus });
        }
      }
      let index = this.heatMap.departments.findIndex((x: any) => x.code == this.department.code);
      this.heatMap.departments[index].observations = this.observations;
      this.itDiagService.postHeatMap(this.heatMap, this.type, this.heatMap._id).subscribe(response => {
        this.onCancel();
        // this.closebutton.nativeElement.click();
      });
    }
  }

  onCancel() {
    this.observaton = '';
    this.addressStatus = 'select';
  }

  getImage(ob: any) {
    if (ob.status) {
      let ad = this.addressStatuses.filter(x => x.name == ob.status);
      if (ad.length > 0)
        return ad[0].image;
      else
        return this.addressStatuses[0].image;
    }
    else
      return this.addressStatuses[0].image;
  }

  getStatus(ob: any) {
    if (ob.status) {
      let ad = this.addressStatuses.filter(x => x.name == ob.status);
      if (ad.length > 0)
        return ad[0].name;
      else
        return this.addressStatuses[0].name;
    }
    else
      return this.addressStatuses[0].name;
  }

  selectStatus(status: any) {
    this.addressStatus = status;
  }

  getImg() {
    if (this.addressStatus) {
      let ad = this.addressStatuses.filter(x => x.name == this.addressStatus);
      if (ad.length > 0)
        return ad[0].image;
      else
        return this.addressStatuses[0].image;
    }
    else
      return this.addressStatuses[0].image;
  }

  getSta() {
    if (this.addressStatus) {
      let ad = this.addressStatuses.filter(x => x.name == this.addressStatus);
      if (ad.length > 0)
        return ad[0].name;
      else
        return this.addressStatuses[0].name;
    }
    else
      return this.addressStatuses[0].name;
  }

  deleteObservation(i: any) {
    this.observations.splice(i, 1);
  }

}
