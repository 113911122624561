      <div class="modal-content border-0">
        <div class="back d-flex" (click)="backToObjective()" *ngIf="screen === 'businessobjectives'">
          Back to <span [innerHTML]="selectedObjective.title"></span>
        </div>
        <div class="modal-head bor-cls overflow-hidden p-0 bg-secondary text-white">
          <ul class="nav nav-tabs m-0 border-bottom-0 " id="popuptab" role="tablist">
            <li class="px-0 dialog-main ">
              <a href="#"  class=" nav-link dialog-head ps-0 fs-20 active" id="what-tab" data-bs-toggle="tab"
              [attr.data-bs-target]="'#what-tab-pane-' + screen"
                >PROJECT DATA</a>
            </li>
            <li class="px-0 dialog-main ">
              <a href="#" class="nav-link dialog-head fs-20" id="current-tab" data-bs-toggle="tab"
              [attr.data-bs-target]="'#current-tab-pane-' + screen"
                >INVESTMENT</a>
            </li>
            <li class="px-0 dialog-main ">
              <a href="#" class="nav-link dialog-head fs-20" id="impact-tab" data-bs-toggle="tab"
              [attr.data-bs-target]="'#impact-tab-pane-' + screen"
                >BENEFITS</a>
            </li>
            <li class="px-0 dialog-main">
              <a href="#" class="nav-link dialog-head fs-20" id="solution-tab" data-bs-toggle="tab"
              [attr.data-bs-target]="'#solution-tab-pane-' + screen"
                >DECISION</a>
            </li>
  
            <li class="px-0 dialog-main ">
              <a href="#" class="nav-link dialog-head fs-20" id="benefits-tab" data-bs-toggle="tab"
              [attr.data-bs-target]="'#benefits-tab-pane-' + screen"
                >ALIGNMENT</a>
            </li>
          </ul>
  
        </div>
       <div class="modal-body rounded-10 investment-in-theme p-0" >

        <div class="tab-content" id="myTabContent">
         
          <div class="tab-pane fade show  active p-4" [attr.id]="'what-tab-pane-' + screen" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
              <div class="container-fluid">

                <div class="row border-bottom border-secondary">

                  <div class="col-lg-6">

                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="fs-13 fw-500 text-info2 m-0">
                          NAME
                        </p>
                        <p class="fw-500">{{projectData?.prgName}}</p>
                      </div>
                      <div class="rag-container d-flex">
                        <span [ngClass]="projectData?.rag" class="rag"></span>
                        <span class="text capitalize">{{projectData?.rag}}</span>
                      </div>
                      <div>
                        <img src="{{projectData?.pic}}" appImageFallback [fallbackSrc]="'../../../../../../assets/images/public/projectdatadefault.jpeg'" alt="" height="70" width="70" class="d-block m-auto mb-1 rounded-3">
                      </div>
                    </div>

                    <div class="row mt-3">
                      
                      <div class="col-6 p-0">
                        <div class="mb-34">
                          <p class="fs-13 fw-500 text-info2 m-0">
                            PROJECT TYPE
                          </p>
                          <p class="uppercase">{{projectData?.prgType}}</p>
                        </div>
                      </div>


                      <div class="col-6 p-0">
                        <div class="mb-34">
                          <p class="fs-13 fw-500 text-info2 m-0">
                            ITB APPROVED&nbsp;
                          </p>
                          <p class="capitalize">{{projectData?.itbApproved}}</p>
                        </div>
                      </div>


                      <div class="col-6 p-0">
                        <div class="mb-34">
                          <p class="fs-13 fw-500 text-info2 m-0">
                            PROJECT. PHASE OR STAGE
                          </p>
                          <p>{{projectData?.stage}}</p>
                        </div>
                      </div>

                      <div class="col-6 p-0">
                        <div class="mb-34">
                          <p class="fs-13 fw-500 text-info2 m-0">
                            PROJECT TIMLINE
                          </p>
                          <p>{{projectData?.sdate| date: company?.display_date_format}} <span class="fw-bold w-5">to</span> {{projectData?.edate| date: company?.display_date_format}}</p>
                        </div>
                      </div>


                      <div class="col-6 p-0">
                        <div class="mb-34">
                          <p class="fs-13 fw-500 text-info2 m-0">
                            SPONSOR
                          </p>
                          <p>{{projectData?.sponsor}}</p>
                        </div>
                      </div>

                      <div class="col-6 p-0">
                        <div class="mb-34">
                          <p class="fs-13 fw-500 text-info2 m-0">
                            PROJECT MANAGER
                          </p>
                          <p>{{projectData?.manager}}</p>
                        </div>
                      </div>


                    </div>

                    <div class="d-flex">

                      <div class="me-3">
                        <div class="text-center py-3 px-2 bg-secondary rounded-10 App-Res-Supp">
                          <img src="../../../assets/images/uploads/applications.svg" alt="" class="img-fluid" height="40px" width="40px">
                        </div>
                        <p class="mt-1">Applications</p>
                      </div>

                      <div class="me-3">
                        <div class="text-center py-3 px-2 bg-secondary rounded-10 App-Res-Supp">
                          <img src="../../../assets/images/uploads/resources-black.svg" alt="" class="img-fluid"  height="40px" width="40px">
                        </div>
                          <p class="ms-1 mt-1">Resources</p>
                      </div>

                      <div class="me-3">
                      <div class="text-center py-3 px-2 bg-secondary rounded-10 App-Res-Supp">
                        <img src="../../../assets/images/uploads/suppliers-black.svg" alt="" class="img-fluid"  height="40px" width="40px">
                      </div>
                    
                        <p class="ms-1 mt-1">Suppliers</p>
                      </div>
                    </div>

                  </div>

                  <div class="col-lg-6 ps-3">

                    <div class="mb-5">
                      <p class="fs-13 fw-500 text-info2 m-0">
                        DESCRIPTION
                      </p>
                      <p>{{projectData?.desc}}</p>
                    </div>

                    <!-- <p class="fs-13 fw-500 text-info2 m-0">
                      PROJECT DEPENDENCIES
                    </p> -->
                    <!-- <div class="d-flex flex-wrap ">
                    <p class="projects-ingon" *ngFor="let dp of projectData?.projects_dependent_on">
                        {{dp.prgName}}
                    </p>
                    <p class="projects-ingon" *ngFor="let dp of projectData?.projects_depending_on">
                        {{dp.prgName}}
                    </p>
                  </div> -->
                  </div>                  
                </div>
                <div class=" pt-4">
                  <div class="col-lg-12 d-flex flex-wrap " >
                      <div class="mb-34" *ngFor="let cu of projectData?.prgCategory" class="project">
                          <p class="fs-13 fw-500 text-info2 m-0 project-catlab">
                              {{cu.label}}
                          </p>
                          <p class="project-catval">
                              {{cu.value || '-'}}
                          </p>
                      </div>
                  </div>
              </div>
  
              </div>
          </div>
          
          <div class="tab-pane fade p-4" [attr.id]="'current-tab-pane-' + screen" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <div class="row my-5">

                  <div class="col-xl-10 overflow-auto">
                    <table class="finance-table table table-bordered table-responsive">
                        <tbody class="fw-bold">
                            <tr class="text-center border-top-0">
                                <th scope="row" rowspan="1" style="border:0;"></th>
                                <td colspan="1" class="bg-primary-dark border-top border-secondary">CAPEX
                                    (<span [innerHTML]="company ? company.curr : ''"></span>)</td>
                                <td colspan="1" class="bg-success-dark border-top border-secondary">OPEX
                                    (<span [innerHTML]="company ? company.curr : ''"></span>)</td>
                                <td class="bg-secondary fw-bold border-1 border-top border-secondary">GRAND
                                    TOTAL (<span [innerHTML]="company ? company.curr : ''"></span>)</td>
                            </tr>
                            <tr class="text-center" *ngFor="let inv of projectData?.investment">
                                <td class="bg-success-light text-dark">{{inv.year}}</td>
                                <td class="bg-primary-light text-dark">
                                    {{inv.capex_allocated | number }}
                                </td>
                                <td class="bg-success-light text-dark">
                                    {{inv.opex_eft_allocated | number}}
                                </td>
                                <td class="text-dark">
                                    {{inv.capex_allocated
                                    + inv.opex_eft_allocated | number}}</td>
                            </tr>
                            <tr class="bg-light text-center ">
                                <th scope="row">TOTAL</th>
                                <td>
                                    {{projectData?.tcapex | number}}
                                </td>
                                <td>
                                    {{projectData?.topex | number}}
                                </td>
                                <td class="bg-light">
                                    {{projectData?.tcapex
                                    + projectData?.topex | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>


                  <div class="col-xl-2 ps-5">

                    <div class="mb-34">
                      <p class="fs-13 fw-500 text-info1 m-0">
                        RR (%)
                      </p>
                      <p>{{projectData?.irr}}</p>
                    </div>

                    <div class="mb-34">
                      <p class="fs-13 fw-500 text-info1 m-0">
                       NPV (<span [innerHTML]="company ? company.curr : '' "></span>)
                      </p>
                      <p>{{projectData?.NPV}}</p>
                    </div>

                    <div>
                      <p class="fs-13 fw-500 text-info1 m-0">
                        Payback Date
                      </p>
                      <p>{{projectData?.payback_date | date: company?.display_date_format}}</p>
                    </div>
                    

                  </div>

                </div>
          </div>
         
          <div class="tab-pane fade p-4" [attr.id]="'impact-tab-pane-' + screen" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">

            <table class="table table-responsive">
              <thead class="text-info1 border border-dark border-1">
                <th class="p-3 w-20 border-end border-dark border-1">
                  # BENEFIT
                </th>
                <th class="w-20 ps-2  border-end border-dark border-1 ">DEFAULT VALUE</th>
                <th class="w-20 ps-2  border-end border-dark border-1 ">BENEFIT DELIVERY DATE</th>
                <th class="w-20 ps-2  border-end border-dark border-1 ">DESCRIPTION</th>
                <th class="w-20 ps-2 ">STATUS</th>
              </thead>
            </table>

            <div class="table-blocks "
            *ngFor="let be of projectData?.benefits_realisations; let b = index">
            <table class="table table-responsive  mb-2  m-0">
                <tbody class=" border border-dark border-1">
                    <tr>
                        <td class=" w-20 border-end border-dark border-1 py-5">
                            <p>{{b+1}}. {{be.benefit}}</p>
                        </td>
                        <td class=" w-20 border-end border-dark border-1">
                            {{be.benefit_value}}
                        </td>
                        <td class=" w-20 border-end border-dark border-1">
                            {{be.benefit_delivery_date | date: company?.display_date_format}}
                        </td>
                        <td class=" w-20 border-end border-dark border-1">
                            {{be.benefitDesc}}
                        </td>

                        <td class=" w-20 border-end border-dark border-1 uppercase">
                         <!-- <span class="me-2">{{be.benefit_delivery_status}} </span> -->
                            <p-inputSwitch class="status-switch" [disabled]="true" [(ngModel)]="be.benefit_delivery_status"></p-inputSwitch>
                        </td>
                    </tr>

                </tbody>

            </table>
        </div>
          </div>
          
          <div class="tab-pane fade p-4" [attr.id]="'solution-tab-pane-' + screen" role="tabpanel" aria-labelledby="solution-tab" tabindex="0">
            <table class="table table-responsive">
              <thead class="text-info1 border border-dark border-1" style="border-radius: 20px;">
                <th class="p-3 border-end border-dark border-1">
                  # DECISIONS
                </th>
                <th class="ps-2 w-25 border-end border-dark border-1 ">DECISION</th>
                <th class="ps-2 w-30 border-end border-dark border-1 ">DECISION DATE</th>
                <th class="ps-2 w-30 ">STATUS</th>
              </thead>
            </table>

            <div class="table-blocks"
            *ngFor="let de of projectData?.decision_matrices; let d = index">
            <table class="table table-responsive rounded-5 mb-2 m-0">
                <tbody class=" border border-dark border-1">
                    <tr class="">
                        <td class="p-4 border-end border-dark border-1" style="border-top-left-radius: 20px !important;">
                            {{d+1}}
                        </td>
                        <td class=" w-25 border-end border-dark border-1 ">
                            {{de.decision}}
                        </td>
                        <td class=" w-30 border-end border-dark border-1">
                            {{de.decision_delivery_date | date: company?.display_date_format}}
                        </td>
                        <td class=" w-30 border-end border-dark border-1 uppercase">
                        <!-- <span class="me-2"> {{de.decision_delivery_status}} </span> -->
                        <p-inputSwitch [disabled]="true"  [(ngModel)]="de.decision_delivery_status" class="status-switch"></p-inputSwitch>


                        </td>
                    </tr>

                </tbody>

            </table>
        </div>                     
          </div>
         
        
          <div class="tab-pane fade" [attr.id]="'benefits-tab-pane-' + screen" role="tabpanel" aria-labelledby="#benefits-tab-pane" tabindex="0">
            <div class="">
              <table class="table table-responsive fs-15 m-0 text-info2">
                <thead >
                  <tr class=" ">
                    <th  class="ps-3 w-25 bg-success-light">OBJECTIVE HEADING</th>
                    <th  class="w-25 bg-success-light">OBJECTIVE SPONSOR</th>
                    <th  class="bg-success-light">OBJECTIVE DESCRIPTION</th>
                  </tr>
                </thead>
                <tbody class="text-dark">
                    <tr *ngFor="let ob of projectData?.objectives">
                        <td>
                            <img [src]="ob.pic" appImageFallback [fallbackSrc]="'../../../../../../assets/images/uploads/DefaultIcon.png'" alt="" height="28" width="28" class="img-fluid me-2  float-start">
                            <span class="fs-18 fw-500 m-0 " [innerHTML]="ob.title"></span>
                        </td>
                        <td>
                            <img [src]="ob.sponsor?.pic" appImageFallback [fallbackSrc]="'../../../../../../assets/images/public/default-avatar.png'"
                                class="rounded-3 me-3" height="48" width="48">
                            <span class="d-inline-block">{{ob.sponsor?.empName}}</span>
                        </td>
                        <td [innerHTML]="ob.desc"> </td>
                    </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>


       </div>
      </div>
