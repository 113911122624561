<div id="modelObservations">
  <div class="modal-dialog modal-xl modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-body p-0">
        <table class="table table-responsive table-bordered">
          <thead>
            <tr>
              <th scope="col" class="p-3 bg-success-light text-dark">Observation #</th>
              <th scope="col" class="p-1 bg-success-light text-dark" style="width:25%">Addressed in this strategy?</th>
              <!-- <td>
                <span>Actions</span>
              </td> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ob of observations;  index as j" >
              <td class="ps-3">
                <span type="text" placeholder="Observation" style="width:'100%'" rows="3" cols="25">{{ob.desc}}</span>
              </td>
              <td class="chan-2">
                <div class="dropdown chan-3">
                  <button *ngIf="addressStatuses && addressStatuses.length > 0"
                    class="btn statusbtn dropdown-toggle bg-secondary border-0" type="button" data-bs-toggle="dropdown"
                    aria-expanded="true" disabled>
                    <img [src]="getImage(ob)" height="22px" width="22px" class="me-2">
                    {{getStatus(ob)}} </button>
                  <ul class="dropdown-menu border-0 rounded-10 shadow bg-secondary"
                    data-popper-placement="bottom-start">
                    <li *ngFor="let item of addressStatuses" (click)="ob.status = item.name">
                      <a class="dropdown-item">
                        <img [src]="item.image" height="22px" width="22px" class="me-2">
                        {{item.name}} </a>
                    </li>
                  </ul>
                </div>
              </td>
             
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<p-toast position="top-center"></p-toast>