import { Component } from '@angular/core';
import { LoaderService } from 'src/app/providers/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoading = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe(value => {
      this.isLoading = value;
    });
  }
}
