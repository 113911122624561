import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public apiLoader: boolean = false;

  public printApiLoader: boolean = false;



  constructor() { }
}
