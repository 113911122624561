import { environment } from 'src/environments/environment';

export default class Utils {
    static sortArrayByKeyValue(arrayToSort: any, sortkey: any) {
        arrayToSort = arrayToSort ? arrayToSort : [];
        arrayToSort.sort((a: any, b: any) => parseFloat(a[sortkey]) - parseFloat(b[sortkey]));
        return arrayToSort;
      }

    static getAWSImageUrl(file_name: any, acc: any) {
      return `${environment.S3_API_URL}${acc}/image-gallery/${file_name}`
    }

    static Trimhtmltags(txt: any) {
      if (txt) {
        txt = txt.replace(/&AMP;/gi, '&')
             .replace(/&LT;/gi, '<')
             .replace(/&GT;/gi, '>');
        txt = txt.replace(/\s*<\/?[^>]+\s*(>|$)/g, '');
      }
      return txt;
    }

  
    

    static sortByCreatedDate(arrayToSort:any) {
      arrayToSort.sort((a:any, b:any) => {
        const nameA = a.cd; 
        const nameB = b.cd;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      
        // names must be equal
        return 0;
      });
      return arrayToSort;
    }
}
