import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
import { AppService } from 'src/app/providers/appservice/app.service';
import Utils from 'src/app/utils/utils';
import { itDiagnosticsService } from 'src/app/providers/input/itdiagnostics.service';
import { Appconfig } from 'src/app/configurations/appconfig';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { CommonService } from 'src/app/helpers/common.service';

const supplierMap = [
  "competency",
  "innovation",
  "performance",
  "quality",
  "delivery_efficiency",
  "proactive",
  "mindset",
  "risk",
  "cost",
  "talent",
  "perception",
]
@Component({
  selector: 'app-objshared-popup',
  templateUrl: './objshared-popup.component.html',
  styleUrls: ['./objshared-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ObjsharedPopupComponent implements OnInit {
  businessObjectives:any;
  company:any;
  ownerData: any[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private appService: AppService,
    public manageDataService: ManagedataService, 
    private itDiagService: itDiagnosticsService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  this.businessObjectives = this.config?.data?.businessObjectives;
    
    this.getPortfolioAnalysis();
    this.getObjectiveProperties();
    this.getOwnerData();
    this.commonService.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => {
      this.company = x;
    })
  }

  getPortfolioAnalysis() {
    this.itDiagService.getPortfolioAnalysis()
    .subscribe(response => {
      const appsIDMap: {
        [key: string]: {
          "faQusAvg": number,
          "taQusAvg": number,
        }
      } = {};
      response.forEach((obj: any) => {
        if(!appsIDMap[obj.app]){
          appsIDMap[obj.app] = {
            faQusAvg: 0,
            taQusAvg: 0,
          }
        }
        appsIDMap[obj.app].faQusAvg = obj.faQusAvg;
        appsIDMap[obj.app].taQusAvg = obj.taQusAvg;
      });
      this.businessObjectives.apps.forEach((appObj: any) => {
        appObj.faQusAvg = appsIDMap[appObj._id]?.faQusAvg;
        appObj.taQusAvg = appsIDMap[appObj._id]?.taQusAvg;
      });
    });
  }
  getFATAColor(val: any) {
    let colorObj: any;
    if (val > 4) {
      colorObj = Appconfig.CriticalColorIndicators['green'];
    }
    else if (val > 2) {
      colorObj = Appconfig.CriticalColorIndicators['amber'];
    }
    else if (val > 0) {
      colorObj = Appconfig.CriticalColorIndicators['red'];
    }    
    else
      colorObj = Appconfig.CriticalColorIndicators['grey'];
    return colorObj;
  }
    
  
  getObjectiveProperties( ){
    let realisationDts:any[]=[];
    this.businessObjectives.suppliers.forEach((obj: any) => {
      supplierMap.forEach((supplierProp: string) => {
        if(obj[supplierProp] === "Severe Concern" || obj[supplierProp] === "Significant Concern"){
          obj.isRisk = true;
          if(!obj.riskTooltip){
            obj.riskTooltip = "";
          }
          obj.riskTooltip += ` ${supplierProp},`
        }
      });
      if(obj.riskTooltip){
        obj.riskTooltip = obj.riskTooltip.slice(0, -1);
        obj.riskTooltip += ` of the supplier ${obj.supplierName} been marked as either Severe Concern or Significant Concern`;
      }
    });
    this.businessObjectives.progs.forEach((prj:any) => {
      //Risk icon logic
      if(prj.itbApproved == 'rejected' || prj.rag === 'red'){
        prj['isRisk']=true;
      }
      //Realisation date logic
      prj.benefits_realisations.forEach((benefit:any) => {
        realisationDts.push(benefit.benefit_delivery_date);
      });
     
    });
    if(realisationDts && realisationDts.length>0) {
      const latestDate: Date | null = this.getLatestDate(realisationDts);
      this.businessObjectives['realisationDate']=latestDate;
    }
    }

    getLatestDate(dates: any): Date | null {
      if (dates.length === 0) {
        return null; // Return null if the array is empty
      }
      let maxDate: Date = dates[0];
    
      for (let i = 1; i < dates.length; i++) {
        if (dates[i] > maxDate) {
          maxDate = dates[i];
        }
      }
    
      return maxDate;
      
    }

      getOwnerData() {
       this.manageDataService
        .getHRDataForProject(this.appService.currentActivatedFeature.strategyId)
        .subscribe(res => {
             this.ownerData = res;
             const ownerObj = this.ownerData.find(x => x._id ===this.businessObjectives.sponsor._id);
            this.dashboardService.getSelectedImage(ownerObj?.pic)
            .subscribe(signedUrl => this.businessObjectives['pics'] = signedUrl);
        });
    }
    // getSponsor(id: any) {
    // const sponsor = this.ownerData.find(x => x._id === id);
    // return sponsor ? { empName: sponsor.empName, pic: sponsor.pic } : { empName: '', pic: '' };
    // }

    Trimhtmltags(txt: any) {
      return Utils.Trimhtmltags(txt);
    }

    openProject(project: string) {
      this.ref.close({ project });
    }

    openApplication(application: string) {
      this.ref.close({ application });
    }
}
