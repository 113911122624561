import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Appconfig } from 'src/app/configurations/appconfig';
import { AppService } from 'src/app/providers/appservice/app.service';
import { CanvasService } from 'src/app/providers/canvas/canvas.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/modules/home/data.service';
import { LoaderService } from 'src/app/providers/loader/loader.service';
declare var DiagramEditor: any;
@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CanvasComponent implements OnInit, OnDestroy {
  isDataLoaded: boolean = false;
  canvasSource: any = '';
  @Input()
  canvasType: any;

  config: any = {
    "language": "",
    "configVersion": null,
    "customFonts": [],
    "libraries": "general;uml;er;bpmn;flowchart;basic;arrows2",
    "customLibraries": [
      "L.scratchpad"
    ],
    "plugins": [],
    "recentColors": [],
    "formatWidth": 240,
    "createTarget": false,
    "pageFormat": {
      "pageSize": {
        "pageWidth": "100px",
        "pageHeight": "100px",
      }



    },
    "autoSize": true,
    "search": true,
    "showStartScreen": true,
    "gridColor": "#d0d0d0",
    "darkGridColor": "#424242",
    "autosave": false,
    "resizeImages": null,
    "openCounter": 8,
    "version": 18,
    "unit": 1,
    "isRulerOn": false,
    "ui": "atlas",
    "darkMode": "auto",
    "maxImageSize": 900
  };
  ui: any;

  @ViewChild('canvasEditor') canvasEditor: any;
  @HostListener('window:canvasdatasave', ['$event'])
  onDataSave(event: any): void {
    // console.log("DEBUG: exit ", event.detail)
    this.loaderService.show();
    setTimeout(() => {
      this.saveCanvas(this.canvasEditor.nativeElement.src);
      this.canvasService.isCanvasActive = false;
    }, 3000);
    // if(!event.detail.exit){
    // }
  }
  // @HostListener('window:canvasExit', ['$event'])
  // onExitCanvas(evt: any): void {
  // }

  constructor(private dashboardService: DashboardService, private canvasService: CanvasService, private appService: AppService,
    private sanitizer: DomSanitizer, private sharedService: SharedService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.canvasService.getCanvasData(this.canvasType, this.appService.currentActivatedFeature.strategyId).subscribe({
      next: (v) => {
        this.canvasSource = (v.canvas) ? v.canvas : Appconfig.defaultCanvas;
        this.isDataLoaded = true;
        document.getElementById('canvasEditor')?.setAttribute('src', this.canvasSource);
        // this.canvasService.isCanvasActive = true;
        // this.editDiagram();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => console.info('complete')
    })

  }

  editDiagram() {
    DiagramEditor.editElement(document.getElementById('canvasEditor'), this.config, this.ui, (data: any, draft: any, elt: any) => {
      this.canvasService.isCanvasActive = true;
      // console.log(elt.currentSrc);
      // this.saveCanvas(elt.currentSrc);
    });
  }

  saveCanvas(data: any) {
    this.canvasService.saveCanvasData(data, this.canvasType, this.appService.currentActivatedFeature.strategyId).subscribe({
      next: (v) => {
        console.log(v);
      },
      error: (e) => {
        this.sharedService.alertNotification('error', 'Unable to Save', e);
      },
      complete: () => {
        this.loaderService.hide();
        this.sharedService.alertNotification('success', 'Saved Successfully', '');
      }
    })
  }

  ngOnDestroy() {
    console.log("Inside destroy")
    DiagramEditor.stopEditingFrame();
  }

}
