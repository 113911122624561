import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ICheckBox } from 'src/app/models/checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() public _value: string; 

  @Input() public dataRef: ICheckBox[];

  constructor() { }

  ngOnInit(): void {
  }

  public get value() : string {
    return this._value
  }
  
  public set value(v : string) {
    this._value = v;
    console.log(this._value);
  }
  
  registerOnChange() { }

  registerOnTouched(){ }

  writeValue(value: any) {
    this.value = value;
  } 

}
