import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorpickerComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class ColorpickerComponent implements OnInit, ControlValueAccessor {
  @Input()
  _color: string;
  constructor() { }

  ngOnInit(): void {
  }

  public get color() : string {
    return this._color
  }
  
  public set color(v : string) {
    this._color = v;
    console.log(this._color);
  }
  
  registerOnChange() { }

  registerOnTouched(){ }

  writeValue(value: any) {
    this.color = value;
  } 

}
