    <div class="modal-body p-0">
      <div class="container-fluid">
        <div class="back d-flex" (click)="backToObjective()" *ngIf="screen === 'businessobjectives'">
          Back to <span [innerHTML]="selectedObjective.title"></span>
        </div>
        <div class="">
          <!-- accordion 1 -->
          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button bg-info1 text-white fs-18 fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  APPLICATION DATA
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="row mb-34">
                    <div class="col-lg-6">
                      <div
                        class="uniquie-id height-align d-flex justify-content-between"
                      >
                        <div>
                          <p class="fs-13 text-info2 fw-500 mb-1">UNIQUE ID</p>
                          <p>{{ appData.appID }}</p>
                        </div>
                        <div class="d-flex image">
                          <img
                            [src]="appPic"
                            class="image"
                            appImageFallback 
                            [fallbackSrc]="'../../../assets/images/public/applicationdatadefault.jpeg'"
                          />
                          <div class="add-uni-fata">
                            <h6>FA</h6>
                            <h6
                              [style.color]="
                                getFATAColor(appData.faQusAvg)
                              "
                            >
                              {{ appData.faQusAvg }}
                            </h6>
                          </div>
                          <div class="add-uni-fata">
                            <h6>TA</h6>
                            <h6
                              [style.color]="
                                getFATAColor(appData.taQusAvg)
                              "
                            >
                              {{ appData.taQusAvg }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-7">
                        <div class="form-floating text-dark mb-34">
                          <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION NAME</p>
                          <p>{{ appData.appName }}</p>
                        </div>
                      </div>
                      <div class="mb-3">
                        <p class="fs-13 text-info2 fw-500 mb-1">DESCRIPTION</p>
                        <p>{{ appData.desc }}</p>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">HOSTING LOCATION</p>
                            <p>{{ appData.location }}</p>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <span class="p-float-label">
                              <p class="fs-13 text-info2 fw-500 mb-1">BUSINESS CRITICALITY</p>
                              <p>{{ appData.criticality }}</p>
                            </span>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION OWNER</p>
                            <p>{{ appData.owner }}</p>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION TYPE</p>
                            <p>{{ appData.type }}</p>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <span class="p-float-label">
                              <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION TCO (<span
                                [innerHTML]="company ? company.curr : ''"
                              ></span
                              >)</p>
                              <p>{{ appData.tco }}</p>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">GEOGRAPHIC COVERAGE</p>
                            <p>{{ appData.coverage }}</p>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION CATEGORY</p>
                            <p>{{ appData.category }}</p>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION MANUFACTURER</p>
                            <p>{{ appData.provider }}</p>
                          </div>
                          <div class="form-floating text-dark mb-34">
                            <p class="fs-13 text-info2 fw-500 mb-1">APPLICATION STATUS</p>
                            <p>{{ appData.status }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <p class="fs-20 text-info2 fw-500 border-bottom">
                      CUSTOM FIELDS
                    </p>
                    <div
                      class="col-lg-6 col-xl-3"
                      *ngFor="
                        let customFields of appData.categories
                      "
                    >
                      <div class="text-dark mb-34 text-caps">
                        <span class="p-float-label">
                          <!-- <p-dropdown
                            inputId="type"
                            [autoDisplayFirst]="false"
                            [options]="getOptions(customFields?._id)"
                            [(ngModel)]="customFields.value"
                            placeholder="Select any one"
                            [editable]="true"
                          >
                          </p-dropdown> -->
                          <!-- <p for="type">{{ customFields?.label }}</p> -->
                          <p class="fs-13 text-info2 fw-500 mb-1">{{customFields?.label}}</p>
                          <p>{{ customFields.value || '-' }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-3" id="accordionPanelsStayOpenSix">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                <button
                  class="accordion-button bg-info1 text-white fs-18 fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseSix"
                >
                  SUPPLIER CONTRACT MAPPING
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseSix"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionPanelsStayOpenSix"
                aria-labelledby="panelsStayOpen-headingSix"
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col p-3">
                      <div
                        class="d-flex justify-content-between align-items-start mb-4"
                      >
                        <p class="fs-15 fw-500 text-info2 w-75">
                          IS THIS APPLICATION LINKED TO ONE OR MORE SUPPLIER
                          CONTRACTS?
                        </p>
                      </div>
                      <div class="tags-container">
                        <ng-container *ngFor="
                        let supplier of appData.suppliers;
                        let i = index
                      ">
                        <span
                          class="tags"
                        >
                          {{ supplier.supplierName }}
                        </span>
                      </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="accordionPanelsStayOpenSeven">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                <button
                  class="accordion-button bg-info1 text-white fs-18 fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSeven"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseSeven"
                >
                  PROJECT MAPPING
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseSeven"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionPanelsStayOpenSeven"
                aria-labelledby="panelsStayOpen-headingSeven"
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col p-3">
                      <div
                        class="d-flex justify-content-between align-items-start mb-4"
                      >
                        <p class="fs-15 fw-500 text-info2 w-75">
                          IS THIS APPLICATION LINKED TO ONE OR MORE PROJECTS?
                        </p>
                      </div>
                      <div class="tags-container confix">
                        <span
                          class="tags"
                          *ngFor="
                            let project of appData.projects;
                            let i = index
                          "
                        >
                          {{ project.prgName }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


