<div class="right-container-filter d-grid">
    <!-- Filter Side bar -->
    <!-- <div class="sidebar-2 bg-white overflow-hidden">
        <li class="sidebar-header d-flex  align-items-center bg-info1 justify-content-between"
            style="height: 56px; min-height: 56px; max-height: 56px;">
            <div class="dropdown fs-15">
                <button class="btn text-white dropdown-toggle btn-info1 border-0" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false"> {{rowValue}} </button>
                <ul class="dropdown-menu fs-15 p-0 rounded-3" aria-labelledby="dropdownMenuLink"
                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                    data-popper-placement="bottom-start">
                    <li class="border-bottom" (click)="LoadProjects('ALL')">
                        <a class="dropdown-item" href="javascript:void(0)"> ALL </a>
                    </li>
                    <li class="border-bottom" (click)="LoadProjects('IT')">
                        <a class="dropdown-item uppercase" href="javascript:void(0)"> IT Project </a>
                    </li>
                    <li (click)="LoadProjects('Business')">
                        <a class="dropdown-item uppercase" href="javascript:void(0)"> Business Project </a>
                    </li>
                </ul>
            </div>
        </li>
        <ul class="m-0  bg-success-light ">
            <li class="border-bottom " style="height: 42px;">
                <a href="javascript:void(0)" class="list-group-item d-flex align-items-center">
                    <div class="d-flex align-items-center me-auto">
                        <span class="mt-1" *ngIf="selectedProjects">{{selectedProjects.length}} Projects</span>

                        <button class="btn btn-info2 ms-5  text-light  fs-15 " data-bs-toggle="dropdown">
                            <img src="../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter </button>

                            <ul class=" dropdown-menu fs-15 p-0 w-20 overflow-auto dropdown-menu.show1" aria-labelledby="dropdownMenuLink"
                            style="z-index: 100; height: 550px; overflow-y: auto; position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);
                            left:100px !important;top:100px !important"
                            data-popper-placement="bottom-start">
                            <li class="bg-secondary">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    PROJECTS ({{clonedSelectedProjects?.length}})</a>
                                    
                            </li>
                            <li *ngFor="let bproj of clonedSelectedProjects; index as k">
                                <a class="dropdown-item" href="javascript:void(0)" style="text-overflow: ellipsis;">
                                    <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                        (change)="FilterProjects()" id="[{{k}}]" />
                                    <span style="width: 50px; overflow: auto;">
                                        {{bproj.prgName}}
                                    </span>
                                </a>
                                
                            </li>


                        </ul>
                    </div> -->
                    <!-- <button class="btn btn-info1 text-white border-0" data-bs-toggle="dropdown"><img
                            src=" ../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter</button> -->
                    <!-- <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                        style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                        data-popper-placement="bottom-start">
                        <li class="bg-secondary">
                            <a class="dropdown-item" href="javascript:void(0)">
                                PROJECTS</a>
                        </li>
                        <li *ngFor="let bproj of osprograms; index as k">
                            <a class="dropdown-item" href="javascript:void(0)">
                                <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                    (change)="FilterColumn()" id="[{{k}}]" />
                                {{bproj.prgName}}
                            </a>
                        </li>


                    </ul> -->
                <!-- </a>
            </li>
        </ul> -->
        <!-- scrollable section -->
        <!-- <div class="scroll-container">
            <ul>
                <li *ngFor="let proj of selectedProjects;index as i" class="border-bottom overflow-hidden">
                    <a href="javascript:void(0)" class="list-group-item d-flex justify-content-between align-items-center"
                        style="height:52px !important;overflow: hidden; width:100%">
                        <span class="mt-1">{{proj.prgName}}</span>
                    </a>
                        <div class="d-flex align-items-center justify-content-between">
                            <a href="javascript:void(0)" (click)="filterRow(proj)">
                                <img [src]="proj.filtered?'../../../../../../assets/images/uploads/filter.svg':'../../../../../../assets/images/uploads/filter-dark.svg'"
                                    alt="">
                            </a>
                            <a href="javascript:void(0)" (click)="projectInfo(proj)">
                                <img src="../../../../../../assets/images/uploads/i.svg" class="ms-1 i-icon p-0">
                            </a>
                        </div>
                   
                </li>
            </ul>
        </div>
    </div> -->
    <!-- Filter Side bar End-->
    <!-- accordion Container Start -->
    <div class="after-filter-container">
        <div class="detail-container  ">
            <div class="sidebar-header bg-info1 d-flex align-items-center ">
                <div class="dropdown fs-15 d-flex justify-content-between" style="width:782px">
                    <button class="btn text-white dropdown-toggle btn-info1 border-0 h-fs" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false"> {{rowValue}} </button>
                    <ul class="dropdown-menu fs-15 p-0 rounded-3" aria-labelledby="dropdownMenuLink"
                        style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px); min-height: 136px !important;"
                        data-popper-placement="bottom-start">
                        <li class="border-bottom" (click)="LoadProjects('ALL')">
                            <a class="dropdown-item" href="javascript:void(0)"> ALL </a>
                        </li>
                        <li class="border-bottom" (click)="LoadProjects('IT')">
                            <a class="dropdown-item uppercase" href="javascript:void(0)"> IT Project </a>
                        </li>
                        <li (click)="LoadProjects('Business')">
                            <a class="dropdown-item uppercase" href="javascript:void(0)"> Business Project </a>
                        </li>
                    </ul>
                    <button class="btn text-white dropdown-toggle btn-info1 border-0 uppercase h-fs" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false"> {{selectedColumn}} </button>
                    <ul class="dropdown-menu fs-15 p-0 rounded-3" aria-labelledby="dropdownMenuLink"
                        style="position: absolute; inset: 0px auto auto 0px; margin: 0px; 
                        transform: translate3d(0px, 42px, 0px);min-height: 136px !important;"
                        data-popper-placement="bottom-start">
                        <li *ngFor="let col of columnSelector">
                            <a class="dropdown-item uppercase" href="javascript:void(0)"
                                (click)="onColumnSelector(col)"> {{col}}
                            </a>
                        </li>

                    </ul>
                </div>
                <!-- dropdown -->
                <!-- <div class="dropdown fs-15" style="margin-left: 245px;">
                    <button class="btn text-white dropdown-toggle btn-info1 border-0 uppercase" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false"> {{selectedColumn}} </button>
                    <ul class="dropdown-menu fs-15 p-0 rounded-3" aria-labelledby="dropdownMenuLink"
                        style="position: absolute; inset: 0px auto auto 0px; margin: 0px; 
                        transform: translate3d(0px, 42px, 0px);"
                        data-popper-placement="bottom-start">
                        <li *ngFor="let col of columnSelector">
                            <a class="dropdown-item uppercase" href="javascript:void(0)"
                                (click)="onColumnSelector(col)"> {{col}}
                            </a>
                        </li>

                    </ul>
                </div> -->

                <!-- <button class="btn btn-info2 ms-2 text-light m-0 fs-18 px-3" data-bs-toggle="dropdown">
                    <img src="../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter </button> -->
                    
                <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                    data-popper-placement="bottom-start">
                    <li class="bg-secondary">
                        <a class="dropdown-item" href="javascript:void(0)">
                            PROJECTS</a>
                    </li>
                    <li *ngFor="let bproj of oprograms; index as k">
                        <a class="dropdown-item" href="javascript:void(0)">
                            <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                (change)="FilterRows()" id="[{{k}}]" />
                            {{bproj.prgName}}
                        </a>
                    </li>
                </ul>
                <!-- dropdown end-->
                <div class="w-100 float-end">
                    <div class="float-end d-flex">
                        <div style="margin-top: 2px;">
                            <i class="bi-check-circle-fill fs-24 me-2 mt-2 color-meh"></i>
                            
                        </div>
                        <span class="text-white btn-info1 h-fs" style="margin-top: 8px;">
                            Dependency Exists
                        </span>
                        <div style="margin-top: 2px;">
                            <i class="bi-dash-circle-fill fs-24 ms-2 me-2 no-dependency-color"></i>
                        </div>
                        <span class="text-white btn-info1 me-2 h-fs" style="margin-top: 8px;">
                            No Dependency
                        </span>
                        <button class="btn btn-info2 me-2 text-light m-0 fs-18 px-3" data-bs-toggle="dropdown">
                            <img src="../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter </button>
                        <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                            data-popper-placement="bottom-start">
                            <li class="bg-secondary">
                                <a class="dropdown-item" href="javascript:void(0)">
                                {{filterText}} ({{oprograms.length}})</a>
                                    
                            </li>
                            <div style="height:456px;overflow-y: auto; overflow-x: hidden;">
                            <li *ngFor="let bproj of oprograms; index as k">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                    (change)="FilterRows()"  id="[{{k}}]" />
                                    {{TrimHtmlTags(bproj.prgName)}}
                                </a>
                                
                            </li>
                            </div>
                        </ul>
                    </div>

                </div>
                <!-- <button class="btn btn-info2 me-2 text-light m-0 fs-18 px-3" data-bs-toggle="dropdown">
                    <img src="../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter </button> -->

            </div>
            <div class="overflow-auto  business-grogrammes ">
                <table class="table table-responsive table-bordered fs-15">
                    <thead class="bg-success-light">
                        <tr>
                            <td>
                                <ul class="m-0  bg-success-light ">
                                    <li class="" style="height: 42px;">
                                        <a href="javascript:void(0)" class="list-group-item d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <span class="mt-1 h-fs" style="width:100px" *ngIf="selectedProjects">{{selectedProjects.length}} Projects</span>
                        
                                                <button class="btn btn-info2 text-light  fs-15 " style="margin-left: 147px; width: 100px;" data-bs-toggle="dropdown">
                                                    <img src="../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter </button>
                        
                                                    <ul class=" dropdown-menu fs-15 p-0 w-20  dropdown-menu.show1" aria-labelledby="dropdownMenuLink"
                                                    style="z-index: 100; height: 550px;  position: fixed; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);
                                                    left:100px !important;top:100px !important"
                                                    data-popper-placement="bottom-start">
                                                    <li class="bg-secondary">
                                                        <a class="dropdown-item" href="javascript:void(0)">
                                                            PROJECTS ({{clonedSelectedProjects?.length}})</a>
                                                            
                                                    </li>
                                                    <div style="height:456px;overflow-y: auto; overflow-x: hidden;">
                                                    <li *ngFor="let bproj of clonedSelectedProjects; index as k">
                                                        <a class="dropdown-item" href="javascript:void(0)" style="text-overflow: ellipsis;">
                                                            <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                                                (change)="FilterProjects()" id="[{{k}}]" />
                                                            <span style="width: 50px; overflow: auto;">
                                                                {{bproj.prgName}}
                                                            </span>
                                                        </a>
                                                        
                                                    </li>
                                                </div>
                        
                                                </ul>
                                            </div>
                                            <!-- <button class="btn btn-info1 text-white border-0" data-bs-toggle="dropdown"><img
                                                    src=" ../../../../../../assets/images/uploads/filter-white.svg" alt=""> Filter</button> -->
                                            <!-- <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                                                style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                                                data-popper-placement="bottom-start">
                                                <li class="bg-secondary">
                                                    <a class="dropdown-item" href="javascript:void(0)">
                                                        PROJECTS</a>
                                                </li>
                                                <li *ngFor="let bproj of osprograms; index as k">
                                                    <a class="dropdown-item" href="javascript:void(0)">
                                                        <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                                            (change)="FilterColumn()" id="[{{k}}]" />
                                                        {{bproj.prgName}}
                                                    </a>
                                                </li>
                        
                        
                                            </ul> -->
                                        </a>
                                    </li>
                                </ul>
                            </td>
                            <td *ngFor="let program of criticalPrograms;index as i" style="width:200px;max-width: 200px;">
                                <div class="d-flex"
                                    style="height:25px !important;overflow: hidden;" [ngStyle]="">
                                    <a href="" class="list-group-item d-flex"
                                        data-bs-toggle="tooltip" data-bs-placement="bottom"
                                        [title]="TrimHtmlTags(program?.prgName)" style="align-items: start;">
                                        <span class="mt-1 table-header-text-wrap"
                                            [innerHTML]="TrimHtmlTags(program.prgName) | safeHtml"></span>
                                    </a>
                                    <div class="d-flex">
                                        <a href="" class="list-group-item d-flex justify-content-between"></a>
                                        <a href="javascript:void(0)">
                                            <img [src]="program.filtered?'../../../../../../assets/images/uploads/filter-filled.svg':'../../../../../../assets/images/uploads/filter.svg'"
                                                alt="" (click)="filterColumn(program)">
                                        </a>
                                        <a href="javascript:void(0)" *ngIf="selectedColumn == 'Business Objectives'">
                                            <img src="../../../../../../assets/images/uploads/i.svg"
                                                class="ms-1 i-icon p-0"
                                                 (click)="openObjPopup(program)">
                                        </a>
                                        <a href="javascript:void(0)" *ngIf="selectedColumn == 'Business Projects'">
                                            <img src="../../../../../../assets/images/uploads/i.svg"
                                                class="ms-1 i-icon p-0"  (click)="projectInfo(program)">
                                        </a>
                                        <a href="javascript:void(0)" *ngIf="selectedColumn == 'Technology Themes'">
                                        </a>
                                    </div>
                                </div>
                            </td>
                         
                        </tr>
                    </thead>
                    <tbody>
                        <!--  tr stART style="width: 330px; overflow: hidden; white-space: nowrap;" style="width:330px !important; margin:0px!important"-->
                        <tr *ngFor="let tech of selectedProjects;index as k">
                            <td class="filterwidth">
                                <div class="d-flex align-items-center justify-content-between h-fs" [ngStyle]="{'width': criticalPrograms.length<=0 ? '350px' : '100%'}" >
                                    {{tech.prgName}}
                                <div style="display: flex;">
                                    <a href="javascript:void(0)">
                                        <img [src]="tech.filtered?'../../../../../../assets/images/uploads/filter-filled.svg':'../../../../../../assets/images/uploads/filter.svg'"
                                            alt="" (click)="filterRow(tech)" >
                                    </a>
                                    <a href="javascript:void(0)">
                                        <img src="../../../../../../assets/images/uploads/i.svg" class="ms-1 i-icon p-0"
                                            (click)="projectInfo(tech)">
                                    </a>
                                </div>
                            </div>
                            </td>

                            <ng-container>
                            <td *ngIf="criticalPrograms.length<=0">
                                <div class="dropdown fs-24 d-flex justify-content-center" style="align-items: self-start;"
                                style="align-content: center;width:100px;max-width: 100px;">
                                &nbsp;
                            </div>
                            </td>
                            <td *ngFor="let program of criticalPrograms;index as k"  style="width:100px;max-width: 100px;">
                                <div class="dropdown fs-15 d-flex justify-content-center" style="align-items: self-start;"
                                    style="align-content: center;width:100px;max-width: 100px;">
                                    <i class="bi fs-24 me-2"
                                        [ngClass]="getStatusValue(tech,
                                    program) == 'Dependency Exists' ? 'color-meh bi-check-circle-fill' : 'bi-dash-circle-fill no-dependency-color'"></i>
                                    <!-- <button
                                        class="btn text-dark dropdown-toggle bg-secondary form-control border-0 d-flex align-items-center justify-content-between"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                       
                                        {{getStatusValue(tech,
                                        program)}}
                                    </button> -->
                                </div>
                            </td>
                            </ng-container> 
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- accordion Container Start -->
</div>




