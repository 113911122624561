import { Injectable } from '@angular/core';
import { DashboardService } from '../home/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {


  private _businessUnitId: any;
  public get businessUnitId(): any {
    return this._businessUnitId;
  }
  public set businessUnitId(value: any) {
    this._businessUnitId = value;
  }

  private _isCustomTitle: boolean = false;
  public get isCustomTitle(): boolean {
    return this._isCustomTitle;
  }
  public set isCustomTitle(value: boolean) {
    this._isCustomTitle = value;
  }

  public _currentActivatedFeature: any = {
    'parentTitle': '',
    'parentRoute': '',
    'childTitle': '',
    'childRoute': '',
    'strategyId': ''
  };

  public get currentActivatedFeature(): any {
    return this._currentActivatedFeature;
  }

  public set currentActivatedFeature(value: any) {
    this.dashboardService.getAccountDetails();
    this.strategyId = value?.strategyId;
    this._currentActivatedFeature = value;
  }

  constructor(private dashboardService: DashboardService) {
    this.dashboardService.getAccountDetails();
  }

  checkCustomTitle() {
    this.isCustomTitle = this.currentActivatedFeature.parentRoute == 'managedata' && this.currentActivatedFeature.childRoute != 'uploaddata';
    console.log(this.isCustomTitle);
  }

  public strategyId: string = '';
}
