<div class="modal fade" id="modelObservations" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header py-2 bg-info1 text-light ">
        <h1 class="modal-title fs-18" id="staticBackdropLabel">OBSERVATION</h1>
        <div>
          <div>
            <button *ngIf="!isAdd" class="btn btn-info2 text-white" (click)="addObservation()">+ ADD
              OBSERVATION</button>
            <button *ngIf="isAdd" class="btn btn-info2 ms-2 text-light" (click)="onDataSave()"><img
                src="../../../../../../assets/images/uploads/save.svg" alt="" height="20px" width="20px" class="mb-1">
              Save</button>
            <button *ngIf="isAdd" class="btn btn-info2 ms-2 me-2 text-light" (click)="onCancel()">Cancel</button>
            <button type="button" class="btn btn-info2 text-light rounded-circle" (click)="onCancel()" #closebutton
              data-bs-dismiss="modal">
              <img src="../../../../../../assets/images/uploads/close-white.svg" height="11px">
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body p-0">
        <table class="table table-responsive table-bordered">
          <thead>
            <tr>
              <th scope="col" class="p-3 bg-success-light text-dark">Observation #</th>
              <th scope="col" class="p-1 bg-success-light text-dark" style="width:25%">Addressed in this strategy?</th>
              <!-- <td>
                <span>Actions</span>
              </td> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isAdd">
              <td class="ps-3"><textarea type="text" placeholder="Observation" [(ngModel)]="observaton"
                  style="width:'100%'" rows="3" cols="30"></textarea></td>
              <td>
                <div class="dropdown">
                  <button *ngIf="addressStatuses && addressStatuses.length > 0"
                    class="btn dropdown-toggle bg-secondary border-0" type="button" data-bs-toggle="dropdown"
                    aria-expanded="true">
                    <img [src]="getImg()" height="22px" width="22px" class="me-2">
                    {{getSta()}} </button>
                  <ul class="dropdown-menu border-0 rounded-10 shadow bg-secondary"
                    data-popper-placement="bottom-start">
                    <li *ngFor="let item of addressStatuses" (click)="selectStatus(item.name)" class="cursor">
                      <a class="dropdown-item">
                        <img [src]="item.image" height="22px" width="22px" class="me-2">
                        {{item.name}} </a>
                    </li>
                  </ul>
                </div>
              </td>

            </tr>
            <tr *ngFor="let ob of observations;  index as j">
              <td class="ps-3">
                <textarea type="text" placeholder="Observation" [(ngModel)]="ob.desc" style="width:'100%'" rows="3"
                  cols="25"></textarea>
              </td>
              <td class="edit-chan">
                <div class="dropdown chan-3">
                  <button *ngIf="addressStatuses && addressStatuses.length > 0"
                    class="btn dropdown-toggle bg-secondary border-0" type="button" data-bs-toggle="dropdown"
                    aria-expanded="true">
                    <img [src]="getImage(ob)" height="22px" width="22px" class="me-2">
                    {{getStatus(ob)}} </button>
                  <ul class="dropdown-menu border-0 rounded-10 shadow bg-secondary"
                    data-popper-placement="bottom-start">
                    <li *ngFor="let item of addressStatuses" (click)="ob.status = item.name">
                      <a class="dropdown-item">
                        <img [src]="item.image" height="22px" width="22px" class="me-2">
                        {{item.name}} </a>
                    </li>
                  </ul>
                </div>
                <a href="javascript:void(0);">
                  <div data-toggle="tooltip" data-placement="top" title="Delete Observation"
                    (click)="deleteObservation(j)" class="chan-1">
                    <img src="'../../../../../../assets/images/uploads/delete-red.svg" class="img-fluid"
                      alt="delete icon" width="15px" height="15px">
                  </div>
                </a>
              </td>

            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<p-toast position="top-center"></p-toast>