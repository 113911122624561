import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/helpers/common.service';
import { AppService } from 'src/app/providers/appservice/app.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { BusinessStrategyService } from 'src/app/providers/input/business-strategy.service';
import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
import { LoadingService } from 'src/app/providers/loading/loading.service';
import Utils from 'src/app/utils/utils';

@Component({
  selector: 'app-visionobjective',
  templateUrl: './visionobjective.component.html',
  styleUrls: ['./visionobjective.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisonobjectiveComponent implements OnInit {
  objective: any = {
    color: '',
    title: '',
    sponsor: {},
    pic: '',
    desc: '',
    time: '',
    timeUnit: '',
    value: '',
    progs: [],
    apps: [],
    goals: [],
    suppliers: [],
  };
  sponsorPic: any;
  objectivePic: any;
  public currentActivatedPopupItem: any = {
    title: '',
    blockname: '',
    type: '',
    list: [],
  };

  ownerData: any[] = [];
  objectiveCount: any;
  categoryId: any;
  objcetiveId: any;
  isshowChecked = false;
  isKPI = true;
  isMonth = true;
  isEdit = false;
  selectedobjective: any = {
    bprogs: [],
    itprogs: [],
    apps: [],
    goals: [],
    suppliers: [],
  };
  goals: any[] = [];
  headerText: string = 'New Objective';

  constructor(
    public manageDataService: ManagedataService,
    public appService: AppService,
    private busStartegyService: BusinessStrategyService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private loadingService: LoadingService,
    private msgService: MessageService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.categoryId = this.config.data.categoryId;
    this.isEdit = this.config.data.isEdit;
    if (this.isEdit) {
      this.headerText = 'Edit Objective';
      this.objective = this.config.data.objective;
      this.objectiveCount = this.config.data.objectiveCount;
      this.selectedobjective.apps = this.objective.apps;
      this.selectedobjective.suppliers = this.objective.suppliers;
      this.objcetiveId = this.objective._id;
      this.goals = this.config?.data?.goals;
      this.isshowChecked = this.objective.qualify;
      this.isKPI = this.config?.data?.objective?.kpi?.length > 0 ? true : false;
      this.isMonth =
        this.config?.data?.objective?.timeUnit == 'month' ? true : false;
      if (this.objective.sponsor) {
        this.dashboardService
          .getSelectedImage(this.objective.sponsor.pic)
          .subscribe((signedUrl) => (this.sponsorPic = signedUrl));
      } else {
        this.objective.sponsor = {};
      }
      this.dashboardService
        .getSelectedImage(this.objective.pic)
        .subscribe((signedUrl) => (this.objectivePic = signedUrl));

      this.goals.map((goalObj: any) => {
        const goal = this.objective.goals.find(
          (x: any) => x._id == goalObj._id
        );
        if (goal) {
          goalObj.title = Utils.Trimhtmltags(goalObj.title);
          goalObj.checked = true;
        } else {
          goalObj.checked = false;
        }
      });
    } else {
      this.goals = this.config?.data?.goals;
      this.goals.map((g: any) => {
        g.checked = false;
      });
      if (this.config?.data?.objective) {
        this.isshowChecked = this.objective.qualify;
        this.isKPI =
          this.config?.data?.objective?.kpi?.length > 0 ? true : false;
        this.isMonth =
          this.config?.data?.objective?.timeUnit == 'month' ? true : false;
        this.objective = this.config?.data?.objective;
        this.dashboardService
          .getSelectedImage(this.config?.data?.selectedObjectiveIcon)
          .subscribe((signedUrl) => (this.objectivePic = signedUrl));
        // this.objective.pic = this.config?.data?.selectedObjectiveIcon;
        this.goals.map((goalObj: any) => {
          const goal = this.objective.goals.find(
            (x: any) => x._id == goalObj._id
          );
          if (goal) {
            goalObj.title = Utils.Trimhtmltags(goalObj.title);
            goalObj.checked = true;
          } else {
            goalObj.checked = false;
          }
        });
      }
      //  this.objective = this.config?.data?.objective;
    }
    this.getOwnerData();
    this.getProjectData();
    this.getApplicationData();
    this.getSupplierData();
  }

  Addobjective() {
    let request = {
      title: 'Objective ' + (this.objectiveCount + 1),
    };
    this.loadingService.apiLoader = true;
    this.busStartegyService.AddObjective(request, this.categoryId).subscribe({
      next: (x: any) => {
        this.objcetiveId = x._id;
        this.objective.title = x.title;
        this.loadingService.apiLoader = false;
        this.msgService.add({
          severity: 'success',
          summary: 'Objectives',
          detail: 'Added Successfully',
        });
      },
      error: (e) => {
        this.loadingService.apiLoader = false;
      },
    });
  }

  getOwnerData() {
    this.loadingService.apiLoader = true;
    this.manageDataService
      .getHRDataForProject(this.appService.currentActivatedFeature.strategyId)
      .subscribe({
        next: (res) => {
          this.ownerData = res;
          this.ownerData.forEach((obj) => {
            this.dashboardService
              .getSelectedImage(obj?.pic)
              .subscribe((signedUrl) => (obj.pic = signedUrl));
          });
          this.loadingService.apiLoader = false;
        },
        error: (e) => {
          this.loadingService.apiLoader = false;
        },
      });
  }

  onOpenPopup(type: any) {
    this.currentActivatedPopupItem = this.popupConfig[type];
    if (this.currentActivatedPopupItem && this.currentActivatedPopupItem.list)
      if (type == 'goals') {
        this.currentActivatedPopupItem.list.map((x: any) => {
          if (
            this.selectedobjective &&
            this.selectedobjective.goals?.find((p: any) => p._id == x._id)
          )
            x.checked = true;
          else x.checked = false;
        });
      } else if (type == 'businessProjects') {
        this.currentActivatedPopupItem.list.map((x: any) => {
          if (
            this.selectedobjective &&
            this.selectedobjective.bprogs?.find((p: any) => p._id == x._id)
          )
            x.checked = true;
          else x.checked = false;
        });
      } else if (type == 'itProjects') {
        this.currentActivatedPopupItem.list.map((x: any) => {
          if (
            this.selectedobjective &&
            this.selectedobjective.itprogs?.find((p: any) => p._id == x._id)
          )
            x.checked = true;
          else x.checked = false;
        });
      } else if (type == 'applications') {
        this.currentActivatedPopupItem.list.map((x: any) => {
          if (
            this.selectedobjective &&
            this.selectedobjective.apps?.find((p: any) => p._id == x._id)
          )
            x.checked = true;
          else x.checked = false;
        });
      } else if (type == 'suppliers') {
        this.currentActivatedPopupItem.list.map((x: any) => {
          if (
            this.selectedobjective &&
            this.selectedobjective.suppliers?.find((p: any) => p._id == x._id)
          )
            x.checked = true;
          else x.checked = false;
        });
      }
  }

  addMappings() {
    if (this.currentActivatedPopupItem.mapping == 'businessProjects') {
      this.selectedobjective.bprogs =
        this.currentActivatedPopupItem.list.filter((o: any) => {
          return o.checked == true;
        });
    } else if (this.currentActivatedPopupItem.mapping == 'itProjects') {
      this.selectedobjective.itprogs =
        this.currentActivatedPopupItem.list.filter((o: any) => {
          return o.checked == true;
        });
    } else if (this.currentActivatedPopupItem.mapping == 'apps') {
      this.selectedobjective.apps = this.currentActivatedPopupItem.list.filter(
        (o: any) => {
          return o.checked == true;
        }
      );
    } else if (this.currentActivatedPopupItem.mapping == 'Suppliers') {
      this.selectedobjective.suppliers =
        this.currentActivatedPopupItem.list.filter((o: any) => {
          return o.checked == true;
        });
    } else if (this.currentActivatedPopupItem.mapping == 'goals') {
      this.selectedobjective.goals = this.currentActivatedPopupItem.list.filter(
        (o: any) => {
          return o.checked == true;
        }
      );
    }
  }

  public popupConfig: any = {
    goals: {
      title: 'GOAL MAPPING',
      blockname: 'Goals',
      type: 'prgName',
      mapping: 'goals',
      list: [],
    },
    businessProjects: {
      title: 'Business Projects',
      blockname: 'Projects',
      type: 'prgName',
      mapping: 'businessProjects',
      list: [],
    },
    itProjects: {
      title: 'IT Projects',
      blockname: 'Projects',
      type: 'prgName',
      mapping: 'itProjects',
      list: [],
    },
    applications: {
      title: 'Applications',
      blockname: 'Applications',
      type: 'appName',
      mapping: 'apps',
      list: [],
    },
    suppliers: {
      title: 'Suppliers',
      blockname: 'Suppliers',
      type: 'supplierName',
      mapping: 'Suppliers',
      list: [],
    },
  };

  getLabel(item: any, type: any) {
    return item[type];
  }

  getProjectData() {
    this.loadingService.apiLoader = true;
    this.manageDataService
      .getProjectData(this.appService.currentActivatedFeature.strategyId, true)
      .subscribe({
        next: (v) => {
          this.loadingService.apiLoader = false;
          this.popupConfig.businessProjects.list = v.filter(
            (x: any) => x.prgType == 'business'
          );
          this.popupConfig.itProjects.list = v.filter(
            (x: any) => x.prgType == 'it'
          );
          if (true) {
            this.objective.progs.map((p: any) => {
              let pr = this.popupConfig.businessProjects.list.filter(
                (x: any) => x._id == p._id
              );
              if (pr.length > 0) {
                pr = pr.map((p: any) => (p.checked = true));
                this.selectedobjective.bprogs.push(pr[0]);
              } else {
                let it = this.popupConfig.itProjects.list.filter(
                  (x: any) => x._id == p._id
                );
                if (it.length > 0) {
                  it = it.map((p: any) => (p.checked = true));
                  this.selectedobjective.itprogs.push(it[0]);
                }
              }
            });
          }
        },
        error: (e) => {
          this.loadingService.apiLoader = false;
        },
        complete: () => console.info('complete'),
      });
  }

  getApplicationData() {
    this.loadingService.apiLoader = true;
    this.manageDataService
      .getApplicationData(this.appService.currentActivatedFeature.strategyId)
      .subscribe({
        next: (v) => {
          this.loadingService.apiLoader = false;
          this.popupConfig.applications.list = v;
          this.popupConfig.applications.list.map((app: any) => {
            const busObjApp = this.objective.apps.find(
              (a: any) => a._id == app._id
            );
            if (busObjApp) {
              app.checked = true;
            }
          });
        },
        error: (e) => {
          this.loadingService.apiLoader = false;
        },
        complete: () => console.info('complete'),
      });
  }

  getSupplierData() {
    this.loadingService.apiLoader = true;
    this.manageDataService
      .getSupplierDataForProject(
        this.appService.currentActivatedFeature.strategyId
      )
      .subscribe({
        next: (v) => {
          this.loadingService.apiLoader = false;
          this.popupConfig.suppliers.list = v;
          this.popupConfig.suppliers.list.map((sup: any) => {
            const busObjSup = this.objective.suppliers.find(
              (a: any) => a._id == sup._id
            );
            if (busObjSup) {
              sup.checked = true;
            }
          });
        },
        error: (e) => {
          this.loadingService.apiLoader = false;
        },
        complete: () => console.info('complete'),
      });
  }

  SaveClick() {
    if (this.isKPI) {
      //this.objective.kpi=''
      this.objective.kpi_enabled = 'yes';
    } else {
      this.objective.kpi = '';
      this.objective.kpi_enabled = 'no';
    }

    this.objective.timeUnit = this.isMonth ? 'month' : 'year';
    if (this.objective.title && this.objective.sponsor) {
      this.objective._id = this.objcetiveId;
      this.objective.progs = this.popupConfig.businessProjects.list
        .filter((x: any) => x._id && x.checked == true)
        .concat(
          ...this.popupConfig.itProjects.list.filter(
            (x: any) => x._id && x.checked == true
          )
        );
      //[this.selectedobjective.bprogs.map((x: any) => x._id), ...this.selectedobjective.itprogs.map((x: any) => x._id)];
      this.objective.goals = this.goals?.filter(
        (x: any) => x._id && x.checked == true
      );
      this.objective.apps = this.popupConfig.applications.list.filter(
        (x: any) => x._id && x.checked == true
      );
      this.objective.suppliers = this.popupConfig.suppliers.list.filter(
        (x: any) => x._id && x.checked == true
      );
      this.objective.kpi = this.objective.kpi;
      this.objective.qualify = this.isshowChecked;
      this.objective.sponsor = this.objective.sponsor._id;
      if (this.objcetiveId) {
        this.loadingService.apiLoader = true;
        this.busStartegyService
          .EditObjective(this.objective, this.objcetiveId)
          .subscribe({
            next: (res) => {
              this.loadingService.apiLoader = false;
              this.msgService.add({
                severity: 'success',
                summary: 'Objectives',
                detail: 'Saved Successfully',
              });
              this.objective.goals = [];
              this.ref.close({ objective: this.objective, isSaved: true });
            },
            error: (e) => {
              this.loadingService.apiLoader = false;
            },
          });
      } else {
        this.loadingService.apiLoader = true;
        this.busStartegyService
          .AddObjective(this.objective, this.categoryId)
          .subscribe({
            next: (res) => {
              this.loadingService.apiLoader = false;
              this.msgService.add({
                severity: 'success',
                summary: 'Objectives',
                detail: 'Saved Successfully',
              });
              this.ref.close({ objective: this.objective, isSaved: true });
            },
            error: (e) => {
              this.loadingService.apiLoader = false;
            },
          });
      }
    } else {
      !this.objective.title
        ? this.msgService.add({
            severity: 'error',
            summary: 'Objective',
            detail: 'Please enter Objective Heading',
          })
        : !this.objective.sponsor
        ? this.msgService.add({
            severity: 'error',
            summary: 'Objective',
            detail: 'Please select Objective Sponsor',
          })
        : '';
    }
  }
  removeSelectedList(app: any) {
    app.checked = false;
  }
  onClose() {
    this.ref.close();
  }

  TrimHtmlContent(content: any) {
    return Utils.Trimhtmltags(content);
  }

  openObjectiveImagePopup() {
    this.objective.progs = this.popupConfig.businessProjects.list
      .filter((x: any) => x._id && x.checked == true)
      .concat(
        ...this.popupConfig.itProjects.list.filter(
          (x: any) => x._id && x.checked == true
        )
      );
    this.objective.goals = this.goals.filter(
      (x: any) => x._id && x.checked == true
    );
    this.objective.apps = this.popupConfig.applications.list.filter(
      (x: any) => x._id && x.checked == true
    );
    this.objective.suppliers = this.popupConfig.suppliers.list.filter(
      (x: any) => x._id && x.checked == true
    );
    this.ref.close({ objective: this.objective, isSaved: false });
  }

  // setImageCallback(eventData: any){
  //     if(eventData.closeModal){
  //       return;
  //     }
  //     // if(eventData.imageType === 'upload'){
  //     //   this.goalObj.pic = Utils.getAWSImageUrl(eventData.url, this.dashboardService.accountDetails.account._id)
  //     // } else{
  //     //   this.goalObj.pic = eventData.url
  //     // }
  //     // if(!this.isObjectiveIcon){
  //     // if(!this.goalObj._id)
  //     //   this.newGoalBtn.nativeElement.click();
  //     // else
  //     //  this.EditGoal(this.goalObj);
  //     // }
  //     // else {

  //     // }
  //   }

  // getSelectedImage(url: string) {
  //     return this.dashboardService.getSelectedImage(url)
  // }

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;

    if (inputValue.length > 4) {
      input.value = inputValue.slice(0, 4);
    }
  }

  onSponsorChange() {
    const ownerObj = this.ownerData.find(
      (x) => x._id == this.objective.sponsor?._id
    );
    this.objective.sponsor = { ...ownerObj };
    this.sponsorPic = this.objective.sponsor?.pic;
    // this.sponsorPic = '';
    // this.getpic();
  }
}
