<!-- <div class="m-3 text-center">
    <h3>Click on the cards to edit the organisation chart</h3>
</div>
<div class="m-3" style="max-height: 700px; overflow-y: auto;">
    <ngx-org-chart [nodes]="nodes" direction="vertical" (itemClick)="onItemClick($event)"></ngx-org-chart>
</div>

<div class="modal fade" #empPopup id="chartPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header py-1 bg-info1 text-light">
                <h1 class="modal-title fs-20" id="staticBackdropLabel">Add Successor</h1>
            </div>
            <div class="modal-body ">
                <div *ngIf="!selectedNode.isRootNode">
                <div class="col-12">
                    <div class="form-floating text-dark mb-3">
                        <p-dropdown inputId="type" [autoDisplayFirst]="false"
                        [options]="hrData"
                        [(ngModel)]="selectedEmp" optionLabel="empName"
                        optionValue="_id" placeholder="Select Employee" [disabled]="!selectedNode.isRootNode"></p-dropdown>
                    </div>
                    <div class="form-floating text-dark mb-3" [ngStyle]="{'display': isEmpValid ? 'none' : 'block'}">
                       <span style="color:red">Select Employee</span>
                    </div>
                </div>

                <div class="col p-3">
                    <label for="chevronText_Color" class="fs-15">Select block color</label>
                    <div class="form-floating text-dark mb-3">
                        <span class="p-float-label">
                            <input [value]="chevronText_Color" 
                                [cpSaveClickOutside]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                [(colorPicker)]="chevronText_Color" [(ngModel)]="chevronText_Color" />
                                <span [style.background]="chevronText_Color" style="border: 1px solid black;margin-left: 10px;">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                        </span>
                        
                    </div>
                </div>
                <div class="btn-row">
                    <button class="btn btn-info1 text-white border-0 m-button me-3 fs-20 fw-500 mb-2"
                        (click)="addSuccessor()">Add Successor</button>

                </div>
            </div>
                <ng-container *ngIf="isAddSuccessor || selectedNode.isRootNode">
                 
                    <div class="col-12">
                        <div class="form-floating text-dark mb-3">
                            <p-dropdown inputId="type" [autoDisplayFirst]="false"
                            [options]="hrData"
                            [(ngModel)]="selectedSuccesorEmp" optionLabel="empName"
                            optionValue="_id" placeholder="Select Employee"
                            (onChange)="onSuccessorEmpChange($event)"></p-dropdown>
                            <div class="form-floating text-dark mb-3" [ngStyle]="{'display': isSEmpValid ? 'none' : 'block'}">
                                <span style="color:red"  *ngIf="selectedNode.isRootNode">Add Successor</span>
                                <span style="color:red"  *ngIf="!selectedNode.isRootNode">Add Successor</span>
                             </div>
                        </div>
                    </div>
                    <div class="col p-3">
                        <label for="schevronText_Color" class="fs-15">Select block color</label>
                        <div class="form-floating text-dark mb-3">
                            <span class="p-float-label">
                                <input [value]="schevronText_Color" 
                                    [cpSaveClickOutside]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                    [(colorPicker)]="schevronText_Color" [(ngModel)]="schevronText_Color" />
                                    <span [style.background]="schevronText_Color" style="border: 1px solid black;margin-left: 10px;">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                            </span>
                            
                        </div>
                    </div>
                </ng-container>
                <div class="btn-row">
                    <button class="btn btn-info1 text-white border-0 m-button me-3 fs-20 fw-500 mb-2" (click)="updateNode(true)">Save</button>
                    <button class="btn btn-danger text-white border-0 m-button me-3 fs-20 fw-500 mb-2"
                        data-bs-dismiss="modal" (click)="Cancel()">CANCEL</button>
                        <button class="btn btn-danger text-white border-0 m-button  fs-20 fw-500 mb-2"
                        data-bs-dismiss="modal" (click)="DeleteNode(selectedNode)" *ngIf="!selectedNode.isRootNode">DELETE</button>
                </div>
            </div>
        </div>
    </div>
</div>


<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#chartPopup" [style.opacity]="0"
    #chartPopupBtn> </button> -->

<!-- npx-org-chart replaced with canvas -->
<app-canvas [canvasType]="'current_state_org_chart'"></app-canvas>
    